import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep, uniqBy } from 'lodash';
import { SelectFilterChart, ECHART_EVENT } from '@meteor/frontend-core';
import { AppContext } from '../../contexts/AppContext';
import { getSizeByMap } from '../../utils/commonUtil';

type ChartProps = {
  data: any;
  sizeMode?: string;
  height?: number;
  loading?: boolean;
  uuid: string;
};

const StrategyMatrixDistributionChart: React.FC<ChartProps> = (props) => {
  const { sizeMode = 'small', data: responseData, height, loading, uuid } = props;
  const { color, chartSelectors, setChartSelectors, setGlobalEvent, echartTheme } = useContext(AppContext);
  const { t } = useTranslation();

  const sizeMap = {
    small: {
      title: 18,
      legend: 10,
      yAxis: 12,
      xAxis: 10,
      gridBottom: 40,
    },
    big: {
      title: 28,
      legend: 18,
      yAxis: 18,
      xAxis: 18,
      gridBottom: 30,
    },
  };

  const getHeight = () => (sizeMode === 'big' ? 180 + (height - 322) - 10 : 180);

  const colors = {
    red: color.errorColor,
    orange: color.warningColor,
    green: color.successColor,
  };

  const levelList = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
  ];

  const [options, setOptions] = useState<any>({
    title: {
      value: 'Strategy Matrix Distribution',
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    selectList: [
      {
        list: [],
        styles: {
          // float: 'right',
          // marginRight: 24,
          display: 'flex',
          alignItems: 'center',
          width: 183,
        },
        blocked: false,
        name: '親',
        maxTagCount: 1,
        maxTagTextLength: 3,
        defaultValue: 'All',
      },
      {
        list: levelList,
        styles: {
          // float: 'right',
          marginRight: 20,
          display: 'flex',
          alignItems: 'center',
          width: 100,
        },
        blocked: false,
        name: 'Level',
        maxTagCount: 1,
        maxTagTextLength: 3,
        defaultValue: levelList[4].value,
      },
    ],
    tooltips: ['zoomIn', 'reset'],
    showSeriesLabel: false,
    chartOptions: {
      grid: [
        {
          left: 60,
          right: 50,
          bottom: 50,
          top: 25,
        },
      ],
      tooltip: {
        show: true,
        formatter: (params) => `<div>WP ID: ${params.data[2]}</br>${params.data[6] ? `WP 名: ${params.data[6]}` : ''} </div>`,
      },
      xAxis: {
        type: 'value',
        name: 'リスク',
        axisLabel: {
          show: true,
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        splitNumber: 3,
        name: '面積原価(万)',
        nameGap: 5,
        nameLocation: 'end',
        axisLabel: {
          show: true,
          formatter: (value) => (Number(value) / 10000).toFixed(0),
        },
        splitLine: {
          show: false,
        },
      },

      series: [
        {
          symbol: 'circle',
          symbolSize: 10,
          type: 'scatter',
          label: {
            position: 'top',
            formatter: (params) => `${params.data[2]}`,
          },
          data: [],
          zlevel: 1,
        },
      ],
    },
  });

  const initData = (selectors) => {
    const chartData = [];
    const selectItems = [{ label: 'All', value: 'All' }];
    const { data, xAxisMinValue, yAxisMinValue, xAxisMaxValue, yAxisMaxValue } = responseData;
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element.level === selectors[1]) {
          // 只有parent变了 或者 parent和level同时变了
          if (
            (options.selectList[1].defaultValue === selectors[1] &&
              options.selectList[0].defaultValue !== selectors[0] &&
              selectors[0] !== 'All') ||
            (options.selectList[1].defaultValue !== selectors[1] &&
              options.selectList[0].defaultValue !== selectors[0] &&
              selectors[0] !== 'All')
          ) {
            if (element.parentId === selectors[0]) {
              chartData.push([
                element.risk,
                element.tic,
                element.wp,
                element.id,
                element.optimizationId,
                uuid,
                element.wpName,
                element.level,
              ]);
            }
          } else {
            chartData.push([
              element.risk,
              element.tic,
              element.wp,
              element.id,
              element.optimizationId,
              uuid,
              element.wpName,
              element.level,
            ]);
          }
          if (element.parentId && element.parentId.length > 0) {
            selectItems.push({
              label: element.parentName,
              value: element.parentId,
            });
          }
        }
      }
    }
    const exist = selectItems.find((item) => item.value === selectors[0]);
    options.selectList[0].list = uniqBy(selectItems, (item) => item.value);
    options.selectList[0].defaultValue = exist ? selectors[0] : 'All';
    options.selectList[1].defaultValue = selectors[1];

    options.chartOptions.series[0].data = chartData;

    // compute background
    if (data && data.length > 0) {
      const riskMin: any = xAxisMinValue;
      const ticMin: any = yAxisMinValue;
      const riskMax: any = xAxisMaxValue;
      const ticMax: any = yAxisMaxValue;

      options.chartOptions.xAxis.min = riskMin;
      options.chartOptions.yAxis.min = ticMin;
      options.chartOptions.xAxis.max = riskMax * 1.01;
      options.chartOptions.yAxis.max = ticMax * 1.01;
      const series = [options.chartOptions.series[0], ...createLineBlock(riskMin, ticMin, riskMax, ticMax)];
      options.chartOptions.series = series;
    }

    setOptions(cloneDeep(options));
  };

  const createLineBlock = (xMin, yMin, xMax, yMax) => {
    const pX0 = xMin;
    const pY0 = yMin;
    const pX1 = (xMax - xMin) / 3 + xMin;
    const pY1 = (yMax - yMin) / 3 + yMin;
    const pX2 = ((xMax - xMin) / 3) * 2 + xMin;
    const pY2 = ((yMax - yMin) / 3) * 2 + yMin;

    // Risk轴：[Riskの最小値, 12.33)，[12.33, 13.67)，[13.67, Riskの最大値]
    // TIC轴：[TICの最小値, 1544017)，[1544017, 3087806)，[3087806, TICの最大値]

    // const pX1 = 12.33;
    // const pY1 = 1544017;
    // const pX2 = 13.67;
    // const pY2 = 3087806;

    const pX3 = xMax;
    const pY3 = yMax;
    const block1 = {
      type: 'line',
      data: [
        [pX0, pY0],
        [pX1, pY0],
        [pX1, pY1],
        [pX0, pY1],
        [pX0, pY0],
      ],
      symbol: 'none',
      lineStyle: {
        color: colors.green,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: colors.green,
        opacity: 1,
      },
      animation: false,
    };
    const block2 = {
      type: 'line',
      data: [
        [pX1, pY0],
        [pX2, pY0],
        [pX2, pY1],
        [pX1, pY1],
        [pX1, pY0],
      ],
      symbol: 'none',
      lineStyle: {
        color: colors.green,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: colors.green,
        opacity: 1,
      },
      animation: false,
    };
    const block3 = {
      type: 'line',
      data: [
        [pX2, pY0],
        [pX3, pY0],
        [pX3, pY1],
        [pX2, pY1],
        [pX2, pY0],
      ],
      symbol: 'none',
      lineStyle: {
        color: colors.orange,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: colors.orange,
        opacity: 1,
      },
      animation: false,
    };
    const block4 = {
      type: 'line',
      data: [
        [pX0, pY1],
        [pX1, pY1],
        [pX1, pY2],
        [pX0, pY2],
        [pX0, pY1],
      ],
      symbol: 'none',
      lineStyle: {
        color: colors.green,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: colors.green,
        opacity: 1,
      },
      animation: false,
    };
    const block5 = {
      type: 'line',
      data: [
        [pX1, pY1],
        [pX2, pY1],
        [pX2, pY2],
        [pX1, pY2],
        [pX1, pY1],
      ],
      symbol: 'none',
      lineStyle: {
        color: colors.orange,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: colors.orange,
        opacity: 1,
      },
      animation: false,
    };
    const block6 = {
      type: 'line',
      data: [
        [pX2, pY1],
        [pX3, pY1],
        [pX3, pY2],
        [pX2, pY2],
        [pX2, pY1],
      ],
      symbol: 'none',
      lineStyle: {
        color: colors.red,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: colors.red,
        opacity: 1,
      },
      animation: false,
    };
    const block7 = {
      type: 'line',
      data: [
        [pX0, pY2],
        [pX1, pY2],
        [pX1, pY3],
        [pX0, pY3],
        [pX0, pY2],
      ],
      symbol: 'none',
      lineStyle: {
        color: colors.orange,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: colors.orange,
        opacity: 1,
      },
      animation: false,
    };
    const block8 = {
      type: 'line',
      data: [
        [pX1, pY2],
        [pX2, pY2],
        [pX2, pY3],
        [pX1, pY3],
        [pX1, pY2],
      ],
      symbol: 'none',
      lineStyle: {
        color: colors.red,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: colors.red,
        opacity: 1,
      },
      animation: false,
    };
    const block9 = {
      type: 'line',
      data: [
        [pX2, pY2],
        [pX3, pY2],
        [pX3, pY3],
        [pX2, pY3],
        [pX2, pY2],
      ],
      symbol: 'none',
      lineStyle: {
        color: colors.red,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: colors.red,
        opacity: 1,
      },
      animation: false,
    };
    return [block1, block2, block3, block4, block5, block6, block7, block8, block9];
  };

  useEffect(() => {
    if (responseData) {
      let selectors = ['All', levelList[4].value];
      if (chartSelectors && chartSelectors.length > 0) {
        selectors = chartSelectors;
      }
      initData(selectors);
    }
  }, [responseData, chartSelectors]);

  const onSelect = (selectors) => {
    setChartSelectors(selectors);
    // initData(selectors);
  };

  const chartEventHandler = (globalEvent) => {
    if (
      globalEvent &&
      globalEvent.eventType === ECHART_EVENT.CHART_ITEM_SELECTED &&
      globalEvent.chartTitle.value === 'Strategy Matrix Distribution' &&
      globalEvent.value &&
      uuid === globalEvent.value[5]
    ) {
      if ((globalEvent.value[7] as number) >= 5) {
        setGlobalEvent(globalEvent);
      } else {
        globalEvent.eventType = null;
        setChartSelectors([globalEvent.value[2], (options.selectList[1].defaultValue as number) + 1]);
      }
    } else if (
      globalEvent &&
      globalEvent.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED &&
      globalEvent.chartTitle.value === 'Strategy Matrix Distribution'
    ) {
      setChartSelectors(['All', levelList[4].value]);
    }
  };

  return (
    <>
      <SelectFilterChart
        size="small"
        selectList={options.selectList}
        chartOptions={{ ...options.chartOptions, echartTheme }}
        showSeriesLabel={options.showSeriesLabel}
        title={options.title}
        height={getHeight()}
        tooltips={options.tooltips}
        loading={loading}
        onSelect={onSelect}
        isBank={!responseData || responseData.data.length === 0}
        onChartEvent={chartEventHandler}
      />
    </>
  );
};

export default StrategyMatrixDistributionChart;
