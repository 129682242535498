import './public-path';
import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './pages/app';
import { BrowserRouter } from 'react-router-dom';

function getSubRootContainer(container: any) {
  return container ? container.querySelector('#meteor-frontend-effimate-root') : document.querySelector('#meteor-frontend-effimate-root');
}

function render(props: any) {
  const { container } = props;
  const root = getSubRootContainer(container);
  const rootContainer = createRoot(root);
  rootContainer.render(
    <BrowserRouter basename={(window as any).__POWERED_BY_QIANKUN__ ? '/effimate' : '/'}>
      <App />
    </BrowserRouter>
  );
}

function storeTest(props: any) {
  props.onGlobalStateChange((value: any, prev: any) => console.log(`[onGlobalStateChange - ${props.name}]:`, value, prev), true);
  props.setGlobalState({
    ignore: props.name,
    user: {
      name: props.name,
    },
  });
}

if (!(window as any).__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap() {
  console.log('react app bootstraped');
}

export async function mount(props: any) {
  console.log('props from main framework', props);
  storeTest(props);
  render(props);
}

export async function unmount(props: any) {
  const { container } = props;
  const root = createRoot(getSubRootContainer(container) as HTMLElement);
  root.unmount();
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
