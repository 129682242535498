// chart title: Buffer Suitability Evaluation
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { AppContext } from '../../contexts/AppContext';
import { ContentChart } from '@meteor/frontend-core';
import { getSizeByMap } from '../../utils/commonUtil';
import { getDayString, getAllDay } from '../../utils/DateUtil';
import dayjs from 'dayjs';

type ChartProps = {
  data: any;
  sizeMode?: string;
  height?: number;
  loading?: boolean;
  deadlines?: {
    feedStart?: string;
    epcStart?: string;
    projectStart?: string;
    projectEnd?: string;
  };
};

const TimelineComparisonChart: React.FC<ChartProps> = (props) => {
  const { sizeMode = 'small', data, height, loading, deadlines = {} } = props;
  const { color, echartTheme } = useContext(AppContext);
  const { t } = useTranslation();

  const sizeMap = {
    small: {
      title: 18,
      legend: 10,
      yAxis: 12,
      xAxis: 10,
      gridBottom: 40,
    },
    big: {
      title: 28,
      legend: 18,
      yAxis: 18,
      xAxis: 18,
      gridBottom: 30,
    },
  };

  const getHeight = () => (sizeMode === 'big' ? 180 + (height - 322) - 10 : 180);

  const [options, setOptions] = useState({
    title: {
      value: 'Timeline Comparison',
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    contentList: [],
    tooltips: ['zoomIn'],
    showSeriesLabel: false,
    chartOptions: {},
  });

  const initData = () => {
    if (data && data.length > 0) {
      const chartData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        chartData.push({
          ...element,
          startDate: getDayString(element.startDate),
          endDate: getDayString(element.endDate),
        });
      }

      const temp = [...chartData.map((item) => item.startDate), ...chartData.map((item) => item.endDate)];

      const markLines = {
        type: 'line',
        symbol: 'none',
        lineStyle: {
          width: 10,
        },
        data: [],
        markLine: {
          animation: false,
          symbol: ['none', 'none'],
          label: {
            show: true,
            formatter: (param) => param.name,
            color: color.errorColor,
            distance: 0,
          },
          lineStyle: {
            color: color.errorColor,
            width: 2,
          },
          data: [],
        },
      };

      if (deadlines.feedStart) {
        temp.push(deadlines.feedStart);
        markLines.markLine.data.push({
          xAxis: deadlines.feedStart,
          name: 'FEED Start',
        });
      }

      if (deadlines.epcStart) {
        temp.push(deadlines.epcStart);
        markLines.markLine.data.push({
          xAxis: deadlines.epcStart,
          name: 'EPC Start',
        });
      }

      if (deadlines.projectStart) {
        temp.push(deadlines.projectStart);
        //  markLines.markLine.data.push({ xAxis: deadlines.feedStart, name: 'FEED Start' });
      }

      if (deadlines.projectEnd) {
        temp.push(deadlines.projectEnd);
        markLines.markLine.data.push({
          xAxis: deadlines.projectEnd,
          name: 'Completion',
        });
      }

      const timeline = temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

      if (timeline[0] === deadlines.feedStart) {
        const startTime = new Date(deadlines.feedStart);
        startTime.setDate(startTime.getDate() - 1);
        timeline.unshift(getDayString(startTime));
      } else if (timeline[0] === deadlines.epcStart) {
        const startTime = new Date(deadlines.epcStart);
        startTime.setDate(startTime.getDate() - 1);
        timeline.unshift(getDayString(startTime));
      }

      options.chartOptions = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        grid: {
          left: '3%',
          right: '10%',
          bottom: '3%',
          top: 15,
          containLabel: true,
        },
        yAxis: {
          type: 'category',
          axisLabel: { interval: 0 },
          data: chartData
            .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
            .map((item) => item.optimizationName),
        },
        xAxis: {
          type: 'category',
          data: (() => {
            const newTimeline = getAllDay(timeline[0], timeline[timeline.length - 1]);
            const startDate = dayjs(newTimeline[0])
              .subtract(newTimeline.length / 10, 'days')
              .format('YYYY-MM-DD');
            return getAllDay(startDate, timeline[timeline.length - 1]);
          })(),
        },
        series: [
          markLines,
          ...chartData.map((item) => ({
            type: 'line',
            symbol: 'none',
            lineStyle: {
              width: 10,
            },
            endLabel: {
              show: true,
              formatter: (params) => `${params.value[2]}ヶ月`,
              offset: [-60, 8],
            },
            data: [
              [item.startDate, item.optimizationName, item.duration],
              [item.endDate, item.optimizationName, item.duration],
            ],
          })),
        ],
      };
      setOptions(cloneDeep(options));
    } else {
      (options.chartOptions as any).series = [];
      setOptions(cloneDeep(options));
    }
  };

  useEffect(() => {
    initData();
  }, [data, deadlines]);

  return (
    <>
      <ContentChart
        contentList={options.contentList}
        chartOptions={{ ...options.chartOptions, echartTheme }}
        showSeriesLabel={options.showSeriesLabel}
        title={options.title}
        height={getHeight()}
        tooltips={options.tooltips}
        loading={loading}
        isBank={!data || data.length === 0}
      />
    </>
  );
};

export default TimelineComparisonChart;
