import { isNil } from 'lodash';

// 丸め込み
export const round = (value: number) => Math.round(value * 100) / 100;

// msgIdをもらう
export const getRandomString = () => {
  const x = 2147483648;
  return Math.floor(Math.random() * x).toString(36) + Math.abs(Math.floor(Math.random() * x) ^ Date.now()).toString(36);
};

// ダッシュボードのために、データによって、カーラーを取得
export const getLineStyleColors = (
  blocks: {
    point: number;
    color: string;
  }[],
  max: number,
  pointer?: {
    point: number;
    color: string;
  }
): (string | number)[][] => {
  const lineWidth = 0.01;
  const colors: any[] = [];
  if (pointer) {
    const pointerRate = Math.floor((pointer.point / max) * 100) / 100;
    let beforePoint = 0;
    blocks.forEach((block) => {
      const blockRate = Math.floor((block.point / max) * 100) / 100;
      if (block.point > pointer.point && beforePoint < pointer.point) {
        colors.push([pointerRate, block.color]);
        colors.push([pointerRate + lineWidth, pointer.color]);
        colors.push([blockRate, block.color]);
      } else {
        colors.push([blockRate, block.color]);
      }
      beforePoint = block.point;
    });
  } else {
    blocks.forEach((block) => {
      const blockRate = Math.floor((block.point / max) * 100) / 100;
      colors.push([blockRate, block.color]);
    });
  }

  return colors;
};

export const getSizeByMode = (size: number, mode: string) => {
  if (mode === 'big') {
    return round(size * 1.5);
  }
  return size;
};

export const getSizeByMap = (mode: string, map: any, property: string, rate = 1) => {
  if (isNil(map[mode][property])) {
    return '';
  }
  return map[mode][property] * rate;
};

export const matchRouter = (path: string, router: string) => {
  if (!path) {
    return false;
  }
  let matched = false;
  const routes = router.split('?');
  for (let index = 0; index < routes.length; index++) {
    const route = routes[index];
    if (route === path) {
      matched = true;
      break;
    } else {
      const module = path.replace('/', '');
      const subRoutes = route.split('/');
      for (let i = 0; i < subRoutes.length; i++) {
        const subRoute = subRoutes[i];
        if (subRoute === module) {
          matched = true;
          break;
        }
      }
      if (matched) {
        break;
      }
    }
  }

  return matched;
};
