import { CloseOutlined } from '@ant-design/icons';
import { Tooltip, Tree } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import React, { useContext, useState } from 'react';
import AlertTaskContext from '../contexts/AlertTaskContext';
import { Loading } from '@meteor/frontend-core';

type SiderStyles = {
  top: number;
  height: number | string;
  width: number;
};

type AlertCardData = {
  title: string;
  items: any[];
  styles: SiderStyles;
  loading?: boolean;
};

const TaskList: React.FC<AlertCardData> = (props) => {
  const { items, styles, loading = false, title } = props;
  const { taskListCollapsed, setTaskListCollapsed } = useContext(AlertTaskContext);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const onSearchChange = (e) => {
    const { value } = e.target;
  };

  const onSelect = (e) => {
    if (e && e.length > 0) {
      const nodes: any = [];
      items.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children.forEach((child) => {
            nodes.push(child);
          });
        } else {
          nodes.push(item);
        }
      });
      const selectItem = nodes.find((item) => {
        return item.key === e[0];
      });
      if (selectItem) {
        selectItem.link(selectItem);
      }
      setSelectedKeys(e);
    }
  };

  const frontExpandIcon: any = ({ expanded }) => {
    if (expanded) {
      return (
        <button type="button" style={{ margin: 4 }} className="effimate-table-row-expand-icon effimate-table-row-expand-icon-expanded" />
      );
    }
    return (
      <button type="button" style={{ margin: 4 }} className="effimate-table-row-expand-icon effimate-table-row-expand-icon-collapsed" />
    );
  };

  return (
    <>
      <div className="alert-card-container">
        <Sider
          style={{ height: styles.height, marginTop: styles.top }}
          className="alert-sider"
          collapsible
          collapsedWidth={0}
          collapsed={taskListCollapsed}
          trigger={null}
          width={styles.width}
        >
          <div className="menu-header">
            <div className="menu-title" style={{ fontSize: 16 }}>
              <Tooltip title={title}>{title}</Tooltip>
            </div>

            <div className="close-icon">
              <CloseOutlined
                onClick={() => {
                  setTaskListCollapsed(true);
                }}
              />
            </div>
          </div>
          <div className="custom-scrollbar alert-menu">
            {/* <Input
              allowClear
              style={{ margin: '15px 8px 5px', width: 'calc(100% - 15px)' }}
              placeholder="Search"
              onChange={onSearchChange}
            /> */}
            {!loading ? (
              items && items.length > 0 ? (
                <Tree
                  switcherIcon={frontExpandIcon}
                  treeData={items}
                  defaultExpandedKeys={items.map((item) => item.key)}
                  onSelect={onSelect}
                  style={{ marginTop: 10 }}
                  blockNode
                  selectedKeys={selectedKeys}
                />
              ) : (
                // <Menu onClick={onSelect}>
                //   {items.map((item) => (
                //     <Menu.Item key={item.key}>{item.key}</Menu.Item>
                //   ))}
                // </Menu>
                <div style={{ padding: 20, textAlign: 'center' }}>Empty</div>
              )
            ) : (
              <Loading fixed={false} />
            )}
          </div>
        </Sider>
      </div>
    </>
  );
};

export default TaskList;
