import React, { useContext, useEffect, useState } from 'react';

import { Button, Modal, Table, Space, Tag, Tooltip, Popconfirm, Input, Select, App } from 'antd';
import {
  StopOutlined,
  RightCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  PauseCircleOutlined,
  SearchOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { cloneDeep } from 'lodash';

import { FilterConfirmProps } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { ACTION_EVENT } from '../consts/action-event';
import { AppContext } from '../contexts/AppContext';
import useInterval from '../hooks/useInterval';
import APIList from '../http/ApiList';

const { Column, ColumnGroup } = Table;

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(tz);

interface OptimizationJobProps {}

interface DataSourceType {
  id: string;
  status: number;
  planName: string;
  stage: string;
  optimizeName: string;
  operationTime: string;
  travelTime: string;
  createBy: string;
  workflowName: string;
  jobType: number;
  visible: boolean;
  setVisible: Function;
  dataSource: any;
  tableLoading: boolean;
  changeTableLoading: Function;
  getOptimizationJob: Function;
  projectId: string;
  setJobTime: Function;
  executeStartTimestamp: string;
  statusName: string;
}

const OptimizationJob: React.FC<OptimizationJobProps> = ({}) => {
  const { t } = useTranslation();
  const { color, setGlobalEvent } = useContext(AppContext);
  const { message } = App.useApp();

  // job
  const [visible, setVisible] = useState<boolean>(false);

  // filter
  const [jobFilter, setJobFilter] = useState('month');

  // const { planAuth } = useContext(AppContext);

  const [dataSource, setDataSource] = useState<DataSourceType[]>([]);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const [jobTime, setJobTime] = useState(null);

  const handleCancel = () => {
    setVisible(false);
  };

  const cancelRunning = (record: any) => {
    setTableLoading(true);
    stopOptimizationJob(record);
  };

  const getOptimizationJob = () => {
    APIList.getOptimizationJob()
      .post({
        jobFilter,
      })
      .then((data: any) => {
        setDataSource(data);
        setTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const stopOptimizationJob = (record: any) => {
    switch (record.jobType) {
      // 1: オリジナル案を確認
      // 2: 最適化実行
      // 3: マスタデータ連携
      // 4: プロジェクトクローズ
      case 1:
        APIList.stopOriginal()
          .post({
            id: record.id,
            workflowName: record.workflowName,
          })
          .then(() => {
            getOptimizationJob();
            message.success(t('aipskd.jobList.stopOriginalMessage'));
            setGlobalEvent({
              eventType: ACTION_EVENT.RELOAD_DATA,
            });
          });
        break;
      case 2:
        APIList.stopOptimization()
          .post({
            id: record.id,
            workflowName: record.workflowName,
          })
          .then(() => {
            getOptimizationJob();
            message.success(t('aipskd.jobList.stopOptimizeMessage'));
            setGlobalEvent({
              eventType: ACTION_EVENT.RELOAD_DATA,
            });
          });
        break;
      case 3:
        APIList.stopDataLinkage()
          .post({
            id: record.id,
            workflowName: record.workflowName,
          })
          .then(() => {
            getOptimizationJob();
            message.success(t('aipskd.jobList.stopMasterMessage'));
            setGlobalEvent({
              eventType: ACTION_EVENT.RELOAD_DATA,
            });
          });
        break;
      case 4:
        APIList.stopCloseProject()
          .post({
            id: record.id,
            workflowName: record.workflowName,
          })
          .then(() => {
            getOptimizationJob();
            message.success(t('aipskd.jobList.stopCloseMessage'));
            setGlobalEvent({
              eventType: ACTION_EVENT.RELOAD_DATA,
            });
          });
        break;
      default:
    }
  };

  const diff = (endDate, startDate, startDateUTC?) => {
    if (!startDate) {
      return null;
    }
    const startTimeUTC = startDateUTC ? dayjs.tz(startDate, startDateUTC).utc() : dayjs(startDate);
    const currentTimeBeijing = dayjs(endDate).utc();
    const diffTime = currentTimeBeijing.diff(startTimeUTC);
    return diffTime;
  };

  const computeTime = (filterData) => {
    filterData.forEach((item) => {
      if (item.executeEndTimestamp) {
        const diffTime = diff(item.executeEndTimestamp, item.executeStartTimestamp);
        if (diffTime) {
          const convertedDuration = dayjs.duration(diffTime, 'milliseconds');
          item.operationTime = `${String(convertedDuration.hours()).padStart(2, '0')}:${String(convertedDuration.minutes()).padStart(
            2,
            '0'
          )}:${String(convertedDuration.seconds()).padStart(2, '0')}`;
        } else {
          item.operationTime = '-';
        }
      } else if (item.status === 1) {
        const diffTime = diff(dayjs(), item.executeStartTimestamp, 'Asia/Tokyo');
        if (diffTime) {
          const convertedDuration = dayjs.duration(diffTime, 'milliseconds');
          item.operationTime = `${String(convertedDuration.hours()).padStart(2, '0')}:${String(convertedDuration.minutes()).padStart(
            2,
            '0'
          )}:${String(convertedDuration.seconds()).padStart(2, '0')}`;
        } else {
          item.operationTime = '-';
        }
      } else {
        item.operationTime = '-';
      }
    });
    setTableData(cloneDeep(filterData));
  };

  useEffect(() => {
    if (visible) {
      getOptimizationJob();
      setJobTime(5000);
      setTableLoading(true);
    } else {
      setJobTime(null);
    }
  }, [visible, jobFilter]);

  useEffect(() => {
    const filterData = dataSource.filter((item) => {
      if (item.status === 1 && !item.executeStartTimestamp) {
        item.statusName = 'Pending';
      }
      if (item.status === 1 && item.executeStartTimestamp) {
        item.statusName = 'Running';
      }
      if (item.status === 2) {
        item.statusName = 'Canceled';
      }
      if (item.status === 3) {
        item.statusName = 'Passed';
      }
      if (item.status === 4) {
        item.statusName = 'Failed';
      }
      return [1, 2, 3, 4].includes(item.jobType);
    });
    computeTime(filterData);
    const intervalId = setInterval(() => {
      computeTime(filterData);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [dataSource]);

  useInterval(() => {
    getOptimizationJob();
  }, jobTime);

  const stopConfirm = (record: any) => {
    cancelRunning(record);
  };

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void) => {
    clearFilters();
    confirm();
  };

  const getColumnSearchProps: any = (title: string, dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          placeholder={`${t('common.search')} ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button onClick={() => clearFilters && handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            {t('common.reset')}
          </Button>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('common.search')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false,
  });

  return (
    <>
      <div
        style={{ float: 'right', paddingLeft: 25, cursor: 'pointer' }}
        onClick={() => {
          setVisible(true);
        }}
      >
        <DatabaseOutlined
          style={{
            fontSize: 24,
            marginRight: 8,
            top: 4,
            position: 'relative',
            color: color.theme === 'dark' ? '#ffffff' : color.primaryColor,
          }}
        />
        <span
          style={{
            fontSize: 14,
            fontWeight: 700,
            top: 1,
            position: 'relative',
            paddingRight: 12,
            color: color.theme === 'dark' ? '#ffffff' : color.primaryColor,
          }}
        >
          {t('aipskd.jobList.jobList')}
        </span>
      </div>
      <Modal
        title={t('aipskd.jobList.jobList')}
        width={window.innerWidth - 100}
        open={visible}
        onCancel={handleCancel}
        footer={null}
        styles={{
          body: { height: window.innerHeight - 300 },
        }}
        destroyOnClose
      >
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: 20,
            }}
          >
            <div>
              <span style={{ marginRight: 20 }}>{t('aipskd.jobList.period')}：</span>
              <Select
                value={jobFilter}
                style={{ width: 145 }}
                onChange={(value) => {
                  setJobFilter(value);
                }}
                options={[
                  {
                    value: 'month',
                    label: t('aipskd.jobList.periodSelection01'),
                  },
                  {
                    value: '3months',
                    label: t('aipskd.jobList.periodSelection02'),
                  },
                  {
                    value: 'year',
                    label: t('aipskd.jobList.periodSelection03'),
                  },
                ]}
              />
            </div>
            <div>
              {t('aipskd.jobList.inputRecords')}： {tableData?.length || 0} {t('aipskd.jobList.recordsUnit')}
            </div>
          </div>
          <Table
            dataSource={tableData}
            rowKey="id"
            loading={tableLoading}
            pagination={false}
            scroll={{ x: 1600, y: window.innerHeight - 450 }}
          >
            <Column
              {...getColumnSearchProps(t('aipskd.jobList.status'), 'statusName')}
              width={120}
              title={t('aipskd.jobList.status')}
              dataIndex="status"
              key="status"
              render={(status: number, record: any) => (
                <Space direction="vertical" size={3}>
                  {status === 1 && !record.executeStartTimestamp && (
                    <div className="optimization-job">
                      <Tag className="rounded-tag" style={{ width: '80px' }} color="#FFA500" icon={<PauseCircleOutlined />}>
                        {record.statusName}
                      </Tag>
                    </div>
                  )}
                  {status === 1 && record.executeStartTimestamp && (
                    <div className="optimization-job">
                      <Tag className="rounded-tag" style={{ width: '80px' }} color="#3299D9" icon={<RightCircleOutlined />}>
                        {record.statusName}
                      </Tag>
                    </div>
                  )}
                  {status === 2 && (
                    <div className="optimization-job">
                      <Tag className="rounded-tag" style={{ width: '80px' }} color="#808080" icon={<StopOutlined />}>
                        {record.statusName}
                      </Tag>
                    </div>
                  )}
                  {status === 3 && (
                    <div className="optimization-job">
                      <Tag className="rounded-tag" style={{ width: '80px' }} color={color.successColor} icon={<CheckCircleOutlined />}>
                        {record.statusName}
                      </Tag>
                    </div>
                  )}
                  {status === 4 && (
                    <div className="optimization-job">
                      <Tag className="rounded-tag" style={{ width: '80px' }} color={color.errorColor} icon={<CloseCircleOutlined />}>
                        {record.statusName}
                      </Tag>
                    </div>
                  )}
                </Space>
              )}
            />
            <ColumnGroup title={t('aipskd.jobList.optimization')}>
              <Column
                title={t('aipskd.jobList.projectCode')}
                width={180}
                dataIndex="projectId"
                key="projectId"
                render={(projectId) => <>{!projectId ? ' - ' : projectId}</>}
                {...getColumnSearchProps(t('aipskd.jobList.projectCode'), 'projectId')}
              />
              <Column
                title={t('aipskd.jobList.planName')}
                width={250}
                dataIndex="planName"
                key="planName"
                render={(planName) => <>{!planName ? ' - ' : planName}</>}
                {...getColumnSearchProps(t('aipskd.jobList.planName'), 'planName')}
              />
              <Column
                width={100}
                title={t('aipskd.jobList.optimize')}
                dataIndex="optimizeName"
                key="optimizeName"
                render={(optimizeName) => <>{!optimizeName ? ' - ' : optimizeName}</>}
                {...getColumnSearchProps(t('aipskd.jobList.optimize'), 'optimizeName')}
              />
              <Column
                width={125}
                title={t('aipskd.jobList.dataSnapshot')}
                dataIndex="dataCrossSection"
                key="dataCrossSection"
                render={(dataCrossSection) => <>{!dataCrossSection ? ' - ' : dataCrossSection}</>}
              />
              <Column
                width={180}
                title={t('aipskd.jobList.jobType')}
                dataIndex="jobType"
                key="jobType"
                render={(text) => {
                  switch (text) {
                    case 1:
                      return t('aipskd.plan.createOriginal');
                    case 2:
                      return t('aipskd.plan.runOptimization');
                    case 3:
                      return t('aipskd.plan.dataLinkage');
                    case 4:
                      return t('aipskd.project.close');
                    default:
                      return '';
                  }
                }}
              />
            </ColumnGroup>
            <Column
              title={t('aipskd.jobList.startTime')}
              width={200}
              dataIndex="executeStartTimestamp"
              key="executeStartTimestamp"
              render={(executeStartTimestamp) => (
                <>
                  <CalendarOutlined /> {!executeStartTimestamp ? ' - ' : executeStartTimestamp}
                </>
              )}
            />
            <Column
              title={t('aipskd.jobList.endTime')}
              width={200}
              dataIndex="executeEndTimestamp"
              key="executeEndTimestamp"
              render={(executeEndTimestamp) => (
                <>
                  <CalendarOutlined /> {!executeEndTimestamp ? ' - ' : executeEndTimestamp}
                </>
              )}
            />
            <Column
              width={100}
              title={t('aipskd.jobList.runTime')}
              dataIndex="operationTime"
              key="operationTime"
              render={(operationTime) => (
                <>
                  <ClockCircleOutlined /> {!operationTime ? ' - ' : operationTime}
                </>
              )}
            />
            <Column
              width={200}
              title={t('aipskd.jobList.createdBy')}
              dataIndex="createdBy"
              key="createdBy"
              render={(createdBy: string) => <>{createdBy}</>}
              {...getColumnSearchProps(t('aipskd.jobList.createdBy'), 'createdBy')}
            />
            <Column
              align="right"
              width={80}
              render={
                (_, record: any) =>
                  record.status === 1 ? (
                    // planAuth(projectId)
                    //   ? (
                    <Tooltip title={t('aipskd.jobList.stopButton')}>
                      <Popconfirm
                        title={t('aipskd.jobList.stopConfirmMessage')}
                        onConfirm={() => stopConfirm(record)}
                        okText={t('common.okText')}
                        cancelText={t('common.cancelText')}
                        placement="left"
                      >
                        <Button
                          style={{
                            backgroundColor: color.errorColor,
                            width: '100%',
                          }}
                        >
                          <StopOutlined style={{ color: 'white', fontSize: '16px' }} />
                        </Button>
                      </Popconfirm>
                    </Tooltip>
                  ) : (
                    <></>
                  )
                // )
                // : <></>
              }
            />
          </Table>
        </>
      </Modal>
    </>
  );
};

export default OptimizationJob;
