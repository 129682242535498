import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep, round } from 'lodash';
import { AppContext } from '../../contexts/AppContext';
import { ContentChart, ECHART_EVENT } from '@meteor/frontend-core';
import { CurrencyMapping } from '../../consts/currencyMapping';
import { getSizeByMap } from '../../utils/commonUtil';

type ChartProps = {
  data: any;
  sizeMode?: string;
  height?: number;
  loading?: boolean;
};

const TICRiskMatrixChart: React.FC<ChartProps> = (props) => {
  const { dataSection, selectOptions, echartTheme } = useContext(AppContext);
  const [projectUnit, setProjectUnit] = useState<string>('');

  const onFilterData = () => {
    const { projectId } = dataSection;
    const { projects } = selectOptions;
    const projectInfo = projects.filter((item) => projectId === item.projectId);
    setProjectUnit(projectInfo.length !== 0 ? projectInfo[0].currency : null);
  };

  useEffect(() => {
    if (dataSection && selectOptions) {
      onFilterData();
    }
  }, [dataSection]);

  const { sizeMode = 'small', data, height, loading } = props;
  const { color, globalEvent } = useContext(AppContext);
  const { t } = useTranslation();

  const sizeMap = {
    small: {
      title: 18,
      legend: 10,
      yAxis: 12,
      xAxis: 10,
      gridBottom: 40,
    },
    big: {
      title: 28,
      legend: 18,
      yAxis: 18,
      xAxis: 18,
      gridBottom: 30,
    },
  };

  const getHeight = () => (sizeMode === 'big' ? 180 + (height - 322) - 10 : 180);

  const [options, setOptions] = useState<any>({
    title: {
      value: 'TIC-Risk Matrix',
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    contentList: [],
    tooltips: ['zoomIn', 'fitView', 'reset'],
    showSeriesLabel: false,
    mouseWheelZoomLock: false,
    chartOptions: {
      animation: false,
      legend: {
        show: true,
        // top: 5,
        // textStyle: {
        //   fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        // },
        itemGap: 5,
        itemWidth: 14,
      },
      tooltip: {
        show: true,
        formatter: (params) => `<div>${params.data[2]}</br>
        Risk: ${params.data[0].toLocaleString()}</br>
        TIC: ${round(params.data[1])}
        </div>`,
        // `${params.data[2]}: [${params.data[0]}, ${round(
        //   params.data[1]
        // )}]`,
      },
      grid: {
        left: 50,
        right: 50,
        bottom: '12%',
        top: 40,
      },
      yAxis: {
        type: 'value',
        name: '',
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
        },
        nameLocation: 'end',
        nameGap: 10,
      },
      xAxis: {
        type: 'value',
        name: 'Risk',
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
        // nameLocation: 'center',
        // nameGap: 8,
      },
      dataZoom: [
        {
          show: false,
          start: 0,
          end: 100,
          xAxisIndex: [0, 1],
          height: '6px',
          showDataShadow: false,
          filterMode: 'none',
        },
        {
          type: 'inside',
          start: 0,
          end: 100,
          xAxisIndex: [0, 1],
          height: '6px',
          filterMode: 'none',
        },
        {
          show: false,
          start: 0,
          end: 100,
          yAxisIndex: [0, 1],
          height: '6px',
          showDataShadow: false,
          filterMode: 'none',
        },
        {
          type: 'inside',
          start: 0,
          end: 100,
          yAxisIndex: [0, 1],
          height: '6px',
          filterMode: 'none',
        },
      ],
      series: [],
    },
  });

  useEffect(() => {
    if (options) {
      const { chartOptions } = options;
      chartOptions.yAxis.name = `TIC(億${CurrencyMapping[projectUnit]})`;
      setOptions(options);
    }
  }, [projectUnit]);

  const initData = () => {
    if (data && data.length > 0) {
      options.chartOptions.series = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        options.chartOptions.series.push({
          name: element.optimizationName,
          symbolSize: 15,
          label: {
            show: false,
          },
          data: [[element.risk, round(element.tic / 100000000), element.optimizationName, element.id, element.optimizationId]],
          type: 'scatter',
        });
      }

      // options.chartOptions.series[0].data = cloneDeep(chartData);
      setOptions(cloneDeep(options));
    } else {
      options.chartOptions.series = [];
      setOptions(cloneDeep(options));
    }
  };

  const fitView = () => {
    if (data && data.length > 0) {
      const riskList = data.map((item) => item.risk);
      const ticList = data.map((item) => round(item.tic / 100000000));
      options.chartOptions.xAxis.max = Math.round(Math.max(...riskList)) + 10;
      options.chartOptions.xAxis.min = Math.round(Math.min(...riskList)) - 10;
      options.chartOptions.xAxis.interval = (options.chartOptions.xAxis.max - options.chartOptions.xAxis.min) / 4;
      options.chartOptions.yAxis.max = Math.round(Math.max(...ticList) * 1.1);
      options.chartOptions.yAxis.min = Math.round(Math.min(...ticList) * 0.9);
      options.chartOptions.yAxis.interval = options.chartOptions.yAxis.interval =
        (options.chartOptions.yAxis.max - options.chartOptions.yAxis.min) / 4;
      setOptions(cloneDeep(options));
    }
  };

  useEffect(() => {
    initData();
    // setTimeout(() => {
    fitView();
    // }, 1000);
  }, [data]);

  const chartEventHandler = (globalEvent) => {
    // Chart reset 事件
    if (globalEvent && globalEvent.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED && globalEvent.chartTitle.value === 'TIC-Risk Matrix') {
      options.chartOptions.xAxis.max = undefined;
      options.chartOptions.xAxis.min = undefined;
      options.chartOptions.xAxis.interval = undefined;
      options.chartOptions.yAxis.max = undefined;
      options.chartOptions.yAxis.min = undefined;
      options.chartOptions.yAxis.interval = undefined;
      initData();
    }

    if (globalEvent && globalEvent.eventType === ECHART_EVENT.CHART_FIT_VIEW && globalEvent.chartTitle.value === 'TIC-Risk Matrix') {
      fitView();
    }
  };

  return (
    <>
      <ContentChart
        contentList={options.contentList}
        chartOptions={{ ...options.chartOptions, echartTheme }}
        showSeriesLabel={options.showSeriesLabel}
        mouseWheelZoomLock={options.mouseWheelZoomLock}
        title={options.title}
        height={getHeight()}
        tooltips={options.tooltips}
        loading={loading}
        isBank={!data || data.length === 0}
        onChartEvent={(event) => {
          chartEventHandler(event);
        }}
      />
    </>
  );
};

export default TICRiskMatrixChart;
