import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';
import dayjs from 'dayjs';
import { AppContext } from '../../contexts/AppContext';
import { ContentChart, ECHART_EVENT } from '@meteor/frontend-core';
import { CurrencyMapping } from '../../consts/currencyMapping';
import { getAllDay } from '../../utils/DateUtil';
import { getSizeByMap, round } from '../../utils/commonUtil';

type EvmData = {
  data: {
    costItems: {
      pvData: any[];
      evData: any[];
      acData: any[];
      areaData: number;
    };
    today;
    dueDate;
    deliveryDate;
    timeline;
  };
  height?: number;
  sizeMode?: string;
  loading?: boolean;
  axis?: any;
};

const EvmChart: React.FC<EvmData> = (props) => {
  const { dataSection, selectOptions, color, echartTheme } = useContext(AppContext);
  // const [projectUnit, setProjectUnit] = useState<string>();
  const projectUnit = useRef<string>();

  const onFilterData = () => {
    const { projectId } = dataSection;
    const { projects } = selectOptions;
    const projectInfo = projects.filter((item) => projectId === item.projectId);
    // setProjectUnit(projectInfo.length !== 0 ? projectInfo[0].currency : null);
    projectUnit.current = projectInfo.length !== 0 ? projectInfo[0].currency : null;
  };

  useEffect(() => {
    if (dataSection && selectOptions) {
      onFilterData();
    }
  }, [dataSection]);

  const {
    sizeMode = 'small',
    data,
    loading,
    height,
    axis, // 我是所有方案日期和dueDate中最大的
  } = props;

  const sizeMap = {
    small: {
      title: 18,
      legend: 10,
      yAxis: 12,
      xAxis: 10,
      gridBottom: 40,
      segmentPaddingLeft: 12,
    },
    big: {
      title: 28,
      legend: 18,
      yAxis: 18,
      xAxis: 18,
      gridBottom: 30,
    },
  };

  const modalTitle = () => (
    <>
      <span className="list-card-title">
        <span>
          {' '}
          面積原価（TIC）:
          {projectUnit.current === 'USD' ? '$' : '￥'}
        </span>
        <span>{round(data ? data.costItems.areaData : 0).toLocaleString()}</span>
      </span>
    </>
  );

  const [options, setOptions] = useState<any>({
    tooltips: ['zoomIn', 'reset'],
    mouseWheelZoomLock: false,
    contentList: [],
    chartOptions: {},
  });
  const { t } = useTranslation();
  const colors = {
    blue: color.primaryColor,
    green: color.successColor,
    orange: color.warningColor,
    red: color.errorColor,
  };

  const initData = () => {
    const legendData: any[] = [];
    const seriesData: any[] = [];
    let timelineData: any[] = [];
    let dataZoomData: any[] = [];
    if (data) {
      const { costItems, today, dueDate, deliveryDate }: any = data;
      let todayIndex = 0;
      if (costItems.pvData && costItems.pvData.length > 0) {
        timelineData = getAllDay(
          axis.xAxisMinValue || costItems.pvData[0][0],
          axis.xAxisMaxValue
            ? new Date(axis.xAxisMaxValue).getTime() > new Date(dueDate).getTime()
              ? axis.xAxisMaxValue
              : dueDate
            : new Date(costItems.pvData[costItems.pvData.length - 1][0]).getTime() > new Date(dueDate).getTime()
            ? costItems.pvData[costItems.pvData.length - 1][0]
            : dueDate,
          10
        );
        todayIndex = costItems.pvData.findIndex((item) => item[0] === today);
        seriesData.push({
          name: 'now',
          data: [
            [today, 0],
            [today, costItems.pvData[costItems.pvData.length - 1][1] * 1.1],
          ],
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            color: '#808080',
            type: 'dashed',
          },
          itemStyle: {
            color: '#808080',
          },
        });
        seriesData.push({
          name: 'due',
          data: [
            [dueDate, 0],
            [dueDate, costItems.pvData[costItems.pvData.length - 1][1] * 1.1],
          ],
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            color: color.errorColor,
          },
          itemStyle: {
            color: color.errorColor,
          },
        });
        seriesData.push({
          name: 'end',
          data: [
            [deliveryDate, 0],
            [deliveryDate, costItems.pvData[costItems.pvData.length - 1][1] * 1.1],
          ],
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            color: color.layoutBackground,
            type: 'dashed',
          },
          itemStyle: {
            color: color.layoutBackground,
          },
        });
        legendData.push({
          name: 'PV',
          itemStyle: {
            color: colors.green,
          },
        });
        seriesData.push({
          name: 'PV',
          data: costItems.pvData,
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            color: colors.green,
          },
          itemStyle: {
            color: colors.green,
          },
        });
      }
      if (costItems.evData && costItems.evData.length > 0) {
        legendData.push({
          name: 'EV',
          itemStyle: {
            color: colors.blue,
          },
        });
        seriesData.push(
          {
            name: 'EV',
            data: [...costItems.evData].filter((item, index) => index <= todayIndex),
            type: 'line',
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              color: colors.blue,
            },
            itemStyle: {
              color: colors.blue,
            },
          },
          {
            name: 'EV',
            data: [...costItems.evData].map((item, index) => (index < todayIndex ? null : item)),

            type: 'line',
            lineStyle: {
              type: 'dashed',
              color: colors.blue,
            },
            itemStyle: {
              color: colors.blue,
            },
            symbolSize: 0,
            smooth: true,
          }
        );
      }
      if (costItems.acData && costItems.acData.length > 0) {
        legendData.push({
          name: 'AC',
          itemStyle: {
            color: colors.orange,
          },
        });
        seriesData.push(
          {
            name: 'AC',
            data: [...costItems.acData].filter((item, index) => index <= todayIndex),
            type: 'line',
            symbolSize: 0,
            lineStyle: {
              color: colors.orange,
            },
            itemStyle: {
              color: colors.orange,
            },
            smooth: true,
          },
          {
            name: 'AC',
            data: [...costItems.acData].map((item, index) => (index < todayIndex ? null : item)),
            type: 'line',
            lineStyle: {
              type: 'dashed',
              color: colors.orange,
            },

            itemStyle: {
              color: colors.orange,
            },
            symbolSize: 0,
            smooth: true,
          }
        );
      }

      dataZoomData = [
        {
          show: false,
          start: 0,
          end: 100,
          xAxisIndex: [0, 1],
          height: '6px',
          showDataShadow: false,
        },
        {
          type: 'inside',
          start: 0,
          end: 100,
          xAxisIndex: [0, 1],
          height: '6px',
        },
        {
          show: false,
          start: 0,
          end: 100,
          yAxisIndex: [0, 1],
          height: '6px',
          showDataShadow: false,
        },
        {
          type: 'inside',
          start: 0,
          end: 100,
          yAxisIndex: [0, 1],
          height: '6px',
        },
      ];
    }

    const optionInfo: any = {
      grid: [
        {
          left: '3%',
          right: '8%',
          bottom: '3%',
          top: 35,
          containLabel: true,
        },
      ],
      xAxis: {
        type: 'time',
        boundaryGap: false,
        show: true,
        data: timelineData,
        axisLabel: {
          hideOverlap: true,
          formatter: (value) => dayjs(value).format('YYYY-MM-DD'),
        },
        minInterval: (new Date(axis.xAxisMaxValue).getTime() - new Date(axis.xAxisMinValue).getTime()) / 3,
        maxInterval: (new Date(axis.xAxisMaxValue).getTime() - new Date(axis.xAxisMinValue).getTime()) / 2,
      },
      yAxis: {
        type: 'value',
        name: `TIC(万${CurrencyMapping[projectUnit.current]})`,
        nameLocation: 'end',
        nameGap: 10,
        nameTextStyle: {
          align: 'left',
          padding: [0, -25],
        },
        splitNumber: 3,
        axisLine: {
          show: true,
        },
        axisLabel: {
          show: true,
          formatter: (value) => Number(value / 10000).toFixed(0),
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          animation: false,
        },
        appendToBody: true,
        className: 'overflow-all',
        hideDelay: 0,
        formatter: (params) => {
          let str = `<div style="text-align: left">${params[0].data[0]}<br/>`;
          const tooltips = [];
          const findDue = params.find((item) => item.seriesName === 'due');
          const findNow = params.find((item) => item.seriesName === 'now');
          const findEnd = params.find((item) => item.seriesName === 'end');
          if (findDue) {
            str += `${findDue.marker}Due: ${findDue.data[0]}<br/>`;
          }
          if (findNow) {
            str += `${findNow.marker}Now: ${findNow.data[0]}<br/>`;
          }
          if (findEnd) {
            str += `${findEnd.marker}End: ${findEnd.data[0]}<br/>`;
          }
          for (let index = 0; index < params.length; index++) {
            const item = params[index];
            if (item.data && item.seriesName !== 'due' && item.seriesName !== 'now' && item.seriesName !== 'end') {
              tooltips.push({
                value: round(item.data[1]),
                name: `${item.marker}${item.seriesName}: ${item.data[1] ? parseInt(item.data[1]).toLocaleString() : 0}<br/>`,
              });
            }
          }
          const sorted = uniqBy(tooltips, 'name')
            .sort((a, b) => b.value - a.value)
            .map((item) => item.name);
          str += `${sorted.join('')}</div>`;
          return str;
        },
      },
      legend: {
        data: legendData,
      },
      dataZoom: dataZoomData,
      series: seriesData,
    };
    // if (chartInstance.current) {
    //   const inst = chartInstance.current.getEchartsInstance();
    //   inst.setOption(optionInfo);
    // }
    // if (modelChartInstance.current) {
    //   const inst = modelChartInstance.current.getEchartsInstance();
    //   inst.setOption(optionInfo);
    // }

    options.chartOptions = optionInfo;
    setOptions({
      ...options,
    });
  };

  useEffect(() => {
    if (axis) {
      initData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, axis]);

  const getHeight = () => (sizeMode === 'big' ? 180 + (height - 322) - 10 : 180);

  const chartEventHandler = (globalEvent) => {
    if (globalEvent && globalEvent.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED) {
      initData();
    }
  };

  return (
    <>
      <ContentChart
        contentList={options.contentList}
        chartOptions={{ ...options.chartOptions, echartTheme }}
        title={{
          value: modalTitle(),
          styles: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
            paddingLeft: sizeMode === 'big' ? 15 : 0,
          },
        }}
        mouseWheelZoomLock={options.mouseWheelZoomLock}
        height={getHeight()}
        loading={loading}
        tooltips={options.tooltips}
        isBank={
          !data ||
          ((!data.costItems.evData || data.costItems.evData.length === 0) &&
            (!data.costItems.pvData || data.costItems.pvData.length === 0) &&
            (!data.costItems.acData || data.costItems.acData.length === 0))
        }
        onChartEvent={(event) => {
          chartEventHandler(event);
        }}
      />
    </>
  );
};

export default EvmChart;
