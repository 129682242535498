// chart title: Buffer Suitability Evaluation
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { AppContext } from '../../contexts/AppContext';
import { ContentChart } from '@meteor/frontend-core';
import { getSizeByMap } from '../../utils/commonUtil';
import { getDayString, getAllDay } from '../../utils/DateUtil';
import dayjs from 'dayjs';

type ChartProps = {
  data: any;
  sizeMode?: string;
  height?: number;
  loading?: boolean;
  deadline?: string;
  axis?: any;
};

const TimelineDisciplineChart: React.FC<ChartProps> = (props) => {
  const { sizeMode = 'small', data, height, loading, deadline, axis } = props;
  const { echartTheme } = useContext(AppContext);
  const { t } = useTranslation();

  const sizeMap = {
    small: {
      title: 18,
      legend: 10,
      yAxis: 12,
      xAxis: 10,
      gridBottom: 40,
    },
    big: {
      title: 28,
      legend: 18,
      yAxis: 18,
      xAxis: 18,
      gridBottom: 30,
    },
  };

  const getHeight = () => (sizeMode === 'big' ? 180 + (height - 322) - 10 : 180);

  const [options, setOptions] = useState({
    title: {
      value: 'Timeline（Discipline）',
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    contentList: [],
    tooltips: ['zoomIn'],
    showSeriesLabel: false,
    chartOptions: {},
  });

  const initData = () => {
    if (data && data.length > 0) {
      const chartData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        chartData.push({
          ...element,
          startDate: getDayString(element.startDate),
          endDate: getDayString(element.endDate),
        });
      }

      options.chartOptions = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
          formatter: (params) => {
            return `${params[0].marker} ${params[0].name}: ${params[0]?.data[0] || ''} -- ${params[1]?.data[0] || ''}`;
          },
        },
        grid: {
          left: '3%',
          right: '8%',
          bottom: '3%',
          top: 15,
          containLabel: true,
        },
        yAxis: {
          type: 'category',
          axisLabel: { interval: 0 },
          data: chartData.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()).map((item) => item.discipline),
        },
        xAxis: {
          type: 'time',
          data: getAllDay(axis.xAxisMinValue, axis.xAxisMaxValue),
          max: axis.xAxisMaxValue,
          min: axis.xAxisMinValue,
          axisLabel: {
            formatter: (value) => dayjs(value).format('YYYY-MM-DD'),
          },
          minInterval: (new Date(axis.xAxisMaxValue).getTime() - new Date(axis.xAxisMinValue).getTime()) / 3,
          maxInterval: (new Date(axis.xAxisMaxValue).getTime() - new Date(axis.xAxisMinValue).getTime()) / 2,
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            type: 'line',
            symbol: 'none',
            lineStyle: {
              width: 10,
            },
            data: [],
          },
          ...chartData.map((item) => ({
            type: 'line',
            symbol: 'none',
            lineStyle: {
              width: 10,
            },
            data: [
              [item.startDate, item.discipline],
              [item.endDate, item.discipline],
            ],
          })),
        ],
      };
      setOptions(cloneDeep(options));
    } else {
      (options.chartOptions as any).series = [];
      setOptions(cloneDeep(options));
    }
  };

  useEffect(() => {
    if (axis) {
      initData();
    }
  }, [data, deadline, axis]);

  return (
    <>
      <ContentChart
        contentList={options.contentList}
        chartOptions={{ ...options.chartOptions, echartTheme }}
        showSeriesLabel={options.showSeriesLabel}
        title={options.title}
        height={getHeight()}
        tooltips={options.tooltips}
        loading={loading}
        isBank={!data || data.length === 0}
      />
    </>
  );
};

export default TimelineDisciplineChart;
