import { FormOutlined, AlignLeftOutlined, DownSquareOutlined, UpSquareOutlined, CloseOutlined, FilterOutlined, CarryOutOutlined, CheckOutlined, PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { Tooltip, Radio, Input, Tabs, Tree, Popover, Button, Switch, Modal } from "antd";
import Sider from "antd/es/layout/Sider";
import { DataNode } from "antd/es/tree";
import { useContext, useState, useRef, useEffect, useMemo } from "react";
import AlertTaskContext from "../contexts/AlertTaskContext";
import { AppContext } from "../contexts/AppContext";
import { Loading } from "@meteor/frontend-core";
import MemoContainer from "./memo-container";
import { useTranslation } from 'react-i18next';

type SiderStyles = {
  top: number;
  height: number | string;
  width: number;
};

type AlertCardData = {
  acceptableItems: any[];
  notAcceptableItems: any[];
  styles: SiderStyles;
  optimizationId: string;
  loading: boolean;
};

const TaskAlert: React.FC<AlertCardData> = (props) => {
  const {
    acceptableItems, notAcceptableItems, styles, optimizationId, loading
  } = props;

  const {
    color, memo, setMemo, memoSwitch, setMemoSwitch, memoSearchValue, setMemoSearchValue, importanceCategoryMap
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [acceptableExpandedKeys, setAcceptableExpandedKeys] = useState([]);
  const [notAcceptableExpandedKeys, setNotAcceptableExpandedKeys] = useState(
    []
  );
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [allExpand, setAllExpand] = useState(false);
  const [searchValue, setSearchValue] = useState<string>();
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const { alertTaskCollapsed, setAlertTaskCollapsed } = useContext(AlertTaskContext);
  const [penaltyLevel, setPenaltyLevel] = useState('3');

  const [memoVisible, setMemoVisible] = useState(false);
  const [memoInfo, setMemoInfo] = useState<{
    taskId: string,
    penaltyLevel: string,
    alertName: string,
    datePeriod: string
  }>();

  const dataList = useRef([]);

  useEffect(() => {
    if (allExpand) {
      setAcceptableExpandedKeys([]);
      setNotAcceptableExpandedKeys([]);
    } else {
      const expandAll = (list, all) => {
        list.forEach((element) => {
          all.push(element.key);
          if (element.children) {
            expandAll(element.children, all);
          }
        });
      };
      const acceptableAll = [];
      const notAcceptableAll = [];
      expandAll(acceptableItems, acceptableAll);
      expandAll(notAcceptableItems, notAcceptableAll);
      setAcceptableExpandedKeys(acceptableAll);
      setNotAcceptableExpandedKeys(notAcceptableAll);
    }
    setSearchValue('');
    generateList(acceptableItems);
    generateList(notAcceptableItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allExpand]);

  useEffect(() => {
    initAcceptableExpandedKeys();
  }, [acceptableItems]);

  useEffect(() => {
    initNotAcceptableExpandedKeys();
  }, [notAcceptableItems]);

  const initAcceptableExpandedKeys = () => {
    const all = [];
    const expandAll = (list) => {
      list.forEach((element) => {
        all.push(element.key);
      });
    };
    if (acceptableItems) {
      expandAll(acceptableItems);
    }
    setAcceptableExpandedKeys(all);
    setSearchValue('');
    generateList(acceptableItems);
  };

  const initNotAcceptableExpandedKeys = () => {
    const all = [];
    const expandAll = (list) => {
      list.forEach((element) => {
        all.push(element.key);
      });
    };
    if (notAcceptableItems) {
      expandAll(notAcceptableItems);
    }
    setNotAcceptableExpandedKeys(all);
    setSearchValue('');
    generateList(notAcceptableItems);
  };

  const frontExpandIcon = ({ expanded }) => {
    if (expanded) {
      return (
        <MinusSquareOutlined style={{ margin: 4, fontSize: 14 }} />
      );
    }
    return (
      <PlusSquareOutlined style={{ margin: 4, fontSize: 14 }} />
    );
  };

  const onAcceptableExpand = (expandedKeysValue: React.Key[]) => {
    setAcceptableExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onNotAcceptableExpand = (expandedKeysValue: React.Key[]) => {
    setNotAcceptableExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const generateList = (data: DataNode[]) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { key } = node;
      dataList.current.push({ key, title: key as string });
      if (node.children) {
        generateList(node.children);
      }
    }
  };

  const titleRender = (nodeData) => (
    <>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            maxWidth: (!nodeData.children || nodeData.children.length === 0) ? 150 : 'initial',
            justifyContent: 'space-between',
          }}
        >
          <span
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {nodeData.title}
          </span>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              // height: 16,
              // border: '1px solid #000',
              // borderRadius: '50%',
            }}
          >
            {nodeData.importanceCategory ? nodeData.importanceCategory : <></>}
          </span>
        </div>
        <div
          className="title-action"
          style={{ marginRight: 0, whiteSpace: 'nowrap' }}
        >
          {nodeData.scheduleLink
            && (!nodeData.children || nodeData.children.length === 0) ? (
            <>
              <Tooltip title={t('aipskd.optionDetail.memo')}>
                <FormOutlined onClick={() => {
                  if (!nodeData.children) {
                    setMemoInfo({
                      taskId: nodeData.taskId,
                      penaltyLevel: nodeData.penaltyLevel,
                      alertName: nodeData.alertName,
                      datePeriod: nodeData.datePeriod
                    });
                    setMemoVisible(true);
                  }
                }}
                />
              </Tooltip>
              <Tooltip title={t('aipskd.optionDetail.WPLocator')} placement="topLeft">
                <AlignLeftOutlined
                  onClick={() => {
                    nodeData.scheduleLink(nodeData);
                    setSelectedKeys([nodeData.key]);
                  }}
                />
              </Tooltip>
            </>
          ) : (
            <span />
          )}
        </div>
      </div>
    </>
  );

  const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
    let parentKey: React.Key;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey!;
  };

  const onSearchChange = (e) => {
    const { value } = e.target;
    if (penaltyLevel === '3') {
      if (!value) {
        initNotAcceptableExpandedKeys();
        return;
      }
      const newExpandedKeys = dataList.current
        .map((item) => {
          if (item.title.indexOf(value) > -1) {
            return getParentKey(item.key, notAcceptableItems);
          }
          return null;
        })
        .filter(
          (item, i, self): item is React.Key => !!(item && self.indexOf(item) === i)
        );
      setNotAcceptableExpandedKeys(newExpandedKeys);
    }
    if (penaltyLevel === '2') {
      if (!value) {
        initAcceptableExpandedKeys();
        return;
      }
      const newExpandedKeys = dataList.current
        .map((item) => {
          if (!value) {
            return null;
          }
          if (item.title.indexOf(value) > -1) {
            return getParentKey(item.key, acceptableItems);
          }
          return null;
        })
        .filter(
          (item, i, self): item is React.Key => !!(item && self.indexOf(item) === i)
        );
      setAcceptableExpandedKeys(newExpandedKeys);
    }

    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const loop = (data: DataNode[]): DataNode[] => data
    ? data.map((item) => {
      let title;
      if (searchValue) {
        const strTitle = item.title as string;
        const index = strTitle.indexOf(searchValue);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        title = index > -1 ? (
          <span>
            {beforeStr}
            <span className="site-tree-search-value">{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{strTitle}</span>
        );
        if (item.children) {
          return {
            ...item,
            children: loop(item.children),
          };
        }
      } else {
        title = item.title;
        if (item.children) {
          return {
            ...item,
            children: loop(item.children),
          };
        }
      }
      return {
        ...item,
        title,
      };
    })
    : [];

  const notAcceptableTreeData = useMemo(
    () => loop(notAcceptableItems),
    [searchValue, notAcceptableItems]
  );

  const acceptableTreeData = useMemo(
    () => loop(acceptableItems),
    [searchValue, acceptableItems]
  );

  const renderTabBar = (tabBarProps, DefaultTabBar) => {
    const penaltyLevelOptions = tabBarProps.panes.map((prop) => ({
      value: prop.key,
      label: prop.props.tab,
    }));
    return (
      <div className="flex-column-align-center">
        <Radio.Group
          optionType="button"
          options={penaltyLevelOptions}
          onChange={({ target: { value } }) => {
            setPenaltyLevel(value);
          }}
          value={penaltyLevel}
        />
      </div>
    );
  };

  return (
    <>
      <div className="alert-card-container">
        <Sider
          style={{ height: styles.height, marginTop: styles.top }}
          className="alert-sider"
          collapsible
          collapsedWidth={0}
          collapsed={alertTaskCollapsed}
          trigger={null}
          width={styles.width}
        >
          <div className="menu-header">
            <div className="menu-title">Alert Tasks</div>

            <div className="close-icon">
              {allExpand ? (
                <Tooltip title={t('aipskd.optionDetail.openList')}>
                  <DownSquareOutlined
                    onClick={() => {
                      setAllExpand(false);
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={t('aipskd.optionDetail.closeList')}>
                  <UpSquareOutlined
                    onClick={() => {
                      setAllExpand(true);
                    }}
                  />
                </Tooltip>
              )}
              <CloseOutlined
                onClick={() => {
                  setAlertTaskCollapsed(true);
                }}
              />
            </div>
          </div>
          <div className="custom-scrollbar alert-menu">
            <Input
              allowClear
              style={{ margin: '15px 8px 5px', width: 'calc(100% - 15px)' }}
              placeholder="Search"
              onChange={onSearchChange}
            />
            <div style={{ position: 'relative' }}>
              <Tabs
                activeKey={penaltyLevel}
                onChange={setPenaltyLevel}
                renderTabBar={renderTabBar}
              >
                <Tabs.TabPane tab="Not Acceptable" key="3">
                  {!loading ? (
                    <Tree
                      switcherIcon={frontExpandIcon}
                      treeData={notAcceptableTreeData}
                      expandedKeys={notAcceptableExpandedKeys}
                      autoExpandParent={autoExpandParent}
                      onExpand={onNotAcceptableExpand}
                      titleRender={titleRender}
                      blockNode
                      selectedKeys={selectedKeys}
                    />
                  ) : <Loading fixed={false} style={{ top: 100 }} />}

                </Tabs.TabPane>
                <Tabs.TabPane tab="Acceptable" key="2">
                  {!loading ? (
                    <Tree
                      switcherIcon={frontExpandIcon}
                      treeData={acceptableTreeData}
                      expandedKeys={acceptableExpandedKeys}
                      autoExpandParent={autoExpandParent}
                      onExpand={onAcceptableExpand}
                      titleRender={titleRender}
                      blockNode
                      selectedKeys={selectedKeys}
                    />
                  ) : <Loading fixed={false} style={{ top: 100 }} />}

                </Tabs.TabPane>
              </Tabs>
              <Popover
                placement="left"
                content={(
                  <>
                    <div style={{ display: 'flex' }}>
                      <Input
                        placeholder={t('aipskd.common.inputPlaceholder').replace('{placeholder}', t('aipskd.optionDetail.memo'))} size="small" value={memo} onChange={(e) => {
                          setMemo(e.target.value);
                        }}
                      />
                      <Button
                        style={{ marginLeft: 5 }}
                        type="primary" size="small" onClick={() => {
                          setMemoSearchValue(memo);
                        }}
                      >
                        {t('aipskd.optionDetail.search')}
                      </Button>
                    </div>
                  </>
                )}
                title={(
                  <>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span>{t('aipskd.optionDetail.memo')} {t('aipskd.optionDetail.search')}</span>
                      <div>
                        <Switch
                          checked={memoSwitch} onChange={(value) => {
                            setMemoSwitch(value);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
                trigger="click"
              >
                <Tooltip title={t('aipskd.optionDetail.filter')}>
                  <FilterOutlined style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    cursor: 'pointer',
                    // color: memoSwitch ? color.primaryColor : ''
                    color: memoSearchValue || memoSwitch ? color.primaryColor : ''
                  }}
                  />
                </Tooltip>
              </Popover>
            </div>
          </div>
        </Sider>
      </div>
      <Modal
        title={memoInfo ? (
          <>
            <div style={{ display: 'flex' }}>
              <span>{memoInfo?.taskId}</span>
              <div style={{
                marginLeft: 5,
                display: 'flex',
                alignItems: 'center',
                marginTop: 1
              }}
              >
                {importanceCategoryMap[memoInfo?.datePeriod.split('#')[1]]}
              </div>
            </div>
            <div style={{
              display: 'flex',
              fontWeight: 'normal',
              fontSize: 16,
              marginTop: 5
            }}
            >
              {`${memoInfo?.alertName}  ${memoInfo?.datePeriod.split('#')[0]}`}
            </div>
          </>
        ) : ''}
        style={{ height: '70%' }}
        open={memoVisible}
        destroyOnClose
        onCancel={() => {
          setMemoVisible(false);
        }}
        maskClosable={false}
        footer={null}
        className="memo-container"
      >
        <MemoContainer memoInfo={memoInfo} optimizationId={optimizationId} />
      </Modal>
    </>
  );
};

export default TaskAlert;
