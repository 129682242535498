import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

type BasicInfoData = {
  optimizationData: any;
  radio?: { optimization: any; setOptimization: Function };
};

const BasicInfoCard: React.FC<BasicInfoData> = (props) => {
  const { optimizationData, radio } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (radio && optimizationData && optimizationData.isMaster) {
      radio.setOptimization(optimizationData);
    }
  }, [optimizationData]);

  return (
    <>
      {optimizationData ? (
        <div className="list-card-header border-bottom">
          {radio ? (
            <Radio
              checked={optimizationData.optimizationId === radio.optimization?.optimizationId}
              onChange={() => {
                radio.setOptimization(optimizationData);
              }}
            />
          ) : (
            <></>
          )}
          <span
            className="list-card-title"
            style={{
              textDecoration: radio ? 'underline' : 'default',
              cursor: radio ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (radio) {
                navigate(`/aipskd/plan-option-detail?optimizationId=${optimizationData.optimizationId}`);
              }
            }}
          >
            {optimizationData.isMaster
              ? `${optimizationData.optimizationName} (` + t('aipskd.planOption.master') + `)`
              : optimizationData.optimizationName}
          </span>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default BasicInfoCard;
