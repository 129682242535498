import { AlertOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { ChartGroup, ChartTableLayout, Loading, ECHART_EVENT } from '@meteor/frontend-core';
import { message, Select, Badge, Tooltip, Tabs } from 'antd';
import { useState, useContext, useEffect } from 'react';
import GanttChart from '../components/bryntum-gantt';
import DataSwitcher from '../components/data-switcher';
import DateFilteredBarChart from '../components/date-filtered-bar-chart/date-filtered-bar-chart';
import BufferSuitabilityEvaluationChart from '../components/kpi-chart/buffer-suitability-evaluation';
import EvmChart from '../components/kpi-chart/evm';
import PathSuitabilityEvaluationChart from '../components/kpi-chart/path-suitability-evaluation';
import StrategyMatrixCategoryChart from '../components/kpi-chart/strategy-matrix-category';
import StrategyMatrixDistributionChart from '../components/kpi-chart/strategy-matrix-distribution';
import TimelineDisciplineChart from '../components/kpi-chart/timeline-discipline';
import BasicInfoCard from '../components/basic-info-card';
import TaskAlert from '../components/task-alert';
import TaskList from '../components/task-list';
import { CHAT_EVENT } from '../consts/chat-event';
import AlertTaskContext from '../contexts/AlertTaskContext';
import { AppContext } from '../contexts/AppContext';
import APIList from '../http/ApiList';
import { useTranslation } from 'react-i18next';

const PlanOptionDetailPage: React.FC = () => {
  // locales
  const { t } = useTranslation();
  const { color, setMenuCollapsed, globalEvent } = useContext(AppContext);
  const {
    dataSection,
    cwaIds,
    disciplineIds,
    setMemoLoading,
    memoSearchValue,
    memoSwitch,
    memoLoading,
    selectOptions,
    importanceCategoryMap,
    sizeMode,
    menuCollapsed,
    scrollCount,
    setScrollCount,
    selectorTop,
  } = useContext(AppContext);
  const [peakOriginData, setPeakOriginData] = useState<any>([]);
  const [peakFilteredData, setPeakFilteredData] = useState<any>([]);
  const [peakData, setPeakData] = useState([]);
  const [currentTab, setCurrentTab] = useState('schedule');
  const [acceptableItems, setAcceptableItems] = useState([]);
  const [notAcceptableItems, setNotAcceptableItems] = useState([]);

  const [chartOption] = useState<any>({
    legend: {
      top: 10,
    },
    grid: {
      left: 80,
      right: 40,
      top: '15%',
    },
    series: [],
  });
  const [alertTaskCollapsed, setAlertTaskCollapsed] = useState(true);
  const [headerCollapsed, setHeaderCollapsed] = useState(false);
  const [alertView, setAlertView] = useState<any>();
  const [ganttData, setGanttData] = useState<any>();
  const [optimizationData, setOptimizationData] = useState<any>();
  const [kpiRoticData, setKpiRoticData] = useState<any>([]);
  const [project, setProject] = useState<any>();
  const [ganttLoading, setGanttLoading] = useState(true);
  const [peakLoading, setPeakLoading] = useState(true);

  const [alertCount, setAlertCount] = useState(0);
  const [chartLoading, setChartLoading] = useState(true);

  const [selectedTasks, setSelectedTasks] = useState([]);
  const [taskListCollapsed, setTaskListCollapsed] = useState(true);
  const [taskListLoading, setTaskListLoading] = useState(false);
  const [bufferSuitabilityEvaluationData, setBufferSuitabilityEvaluationData] = useState([]);
  const [pathSuitabilityEvaluationData, setPathSuitabilityEvaluationData] = useState([]);
  const [strategyMatrixCategoryData, setStrategyMatrixCategoryData] = useState<any>([]);
  const [timelineDisciplineData, setTimelineDisciplineData] = useState<any>([]);
  const [strategyMatrixDistributionData, setStrategyMatrixDistributionData] = useState<any>([]);

  const [kpiRoticLoading, setKpiRoticLoading] = useState<any>(true);
  const [bufferSuitabilityEvaluationLoading, setBufferSuitabilityEvaluationLoading] = useState(true);
  const [pathSuitabilityEvaluationLoading, setPathSuitabilityEvaluationLoading] = useState(true);
  const [strategyMatrixCategoryLoading, setStrategyMatrixCategoryLoading] = useState<any>(true);
  const [timelineDisciplineLoading, setTimelineDisciplineLoading] = useState<any>(true);
  const [strategyMatrixDistributionLoading, setStrategyMatrixDistributionLoading] = useState<any>(true);

  const [kpiRangeData, setKpiRangeData] = useState<any>([]);
  const [evmAxis, setEvmAxis] = useState<any>([]);
  const [timelineDisciplineAxis, setTimelineDisciplineAxis] = useState<any>([]);
  const [strategyMatrixDistributionAxis, setStrategyMatrixDistributionAxis] = useState<any>([]);
  const [bufferSuitabilityEvaluationAxis, setBufferSuitabilityEvaluationAxis] = useState<any>([]);
  const [pathSuitabilityEvaluationAxis, setPathSuitabilityEvaluationAxis] = useState<any>([]);

  const [taskListTitle, setTaskListTitle] = useState('');

  const [deadline, setDeadline] = useState<any>();

  const chartHeight = 240;

  let maxHeight = 500;
  if (typeof window !== 'undefined') {
    maxHeight = window.innerHeight - 525;
  }

  /**
   * Table高度Map
   */
  const tableHeightMap = {
    'chart-table': maxHeight,
    'table-only': maxHeight + 300,
  };

  const tableTop = 0;
  const tableWidth = 300;
  const alertAction = (item) => {
    setCurrentTab('schedule');
    setAlertView({
      key: item.title,
      tab: 'schedule',
    });
  };
  const scheduleLink = (item) => {
    const period = item.title;
    const { start, end } = getStartAndEndDates(period);
    setCurrentTab('schedule');
    setAlertView({
      key: item.key,
      tab: 'schedule',
      zoom: true,
      startDate: start,
      endDate: end,
    });
  };
  const mpLink = () => {
    setCurrentTab('peak');
    setTimeout(() => {
      setAlertView({
        seriesName: 'Civil Structure ＆ Architecture',
        index: 1,
        tab: 'peak',
      });
    }, 500);
  };

  const onFilterData = () => {
    const { projectId, optimizeId, optimizationId } = dataSection;
    const projectInfo = selectOptions.projects.find((item) => projectId === item.projectId);
    const optimization = selectOptions.optimizations.find((item) => optimizationId === item.optimizationId);
    setChartLoading(false);
    if (!optimization) {
      setKpiRoticData([]);
      setKpiRoticLoading(false);
      setBufferSuitabilityEvaluationData([]);
      setBufferSuitabilityEvaluationLoading(false);
      setPathSuitabilityEvaluationData([]);
      setPathSuitabilityEvaluationLoading(false);
      setStrategyMatrixCategoryData([]);
      setStrategyMatrixCategoryLoading(false);
      setStrategyMatrixDistributionData([]);
      setStrategyMatrixDistributionLoading(false);
      setTimelineDisciplineData([]);
      setTimelineDisciplineLoading(false);
      setPeakData([]);
      setPeakLoading(false);
      setGanttData({ tasksData: [] });
      setGanttLoading(false);
      return;
    }
    setOptimizationData(optimization);
    setProject(projectInfo);
    searchSchedule(optimizationId);
    APIList.getKpiValueRange()
      .get({ optimizeId })
      .then((res: any) => {
        setKpiRangeData(res);
        getKpis([optimizationId]);
      })
      .catch(() => {
        message.error('KPI Range Data Error');
      });
  };

  useEffect(() => {
    if (dataSection && selectOptions) {
      onFilterData();
    }
  }, [dataSection]);

  const searchSchedule = (optimizationId: string) => {
    setGanttLoading(true);
    APIList.getTaskSchedule()
      .post({ optimizationId, disciplines: disciplineIds, cwas: cwaIds })
      .then((data: any) => {
        if (data) {
          setGanttData(data);
          setTimeout(() => {
            setGanttLoading(false);
          }, 1000);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    setPeakLoading(true);

    const pageSize = 10000;

    APIList.getMpPeak()
      .post({
        optimizationId,
        disciplines: disciplineIds,
        cwas: cwaIds,
        // pageNum: 1,
        // pageSize: pageSize,
      })
      .then((res: any) => {
        if (res.list) {
          const data = res.list.map((item) => ({
            seriesName: item[accumulation],
            area: item.area,
            areaName: item.areaName,
            disciplineName: item.disciplineName,
            subDisciplineName: item.subDisciplineName,
            importance: item.importance,
            category: item.category,
            date: new Date(item.date),
            value: item.value,
          }));
          setTimeout(() => {
            setPeakLoading(false);
          }, 1000);
          setPeakOriginData([...data]);
          setPeakFilteredData([...data]);
          //  else {
          //   const count = (res.total - pageSize) / pageSize;
          //   let dataList = [];
          //   const apis = [];
          //   for (let index = 0; index < count; index++) {
          //     apis.push(
          //       APIList.getMpPeak().post({
          //         optimizationId,
          //         disciplines: disciplineIds,
          //         cwas: cwaIds,
          //         pageNum: index + 2,
          //         pageSize: pageSize,
          //       })
          //     );
          //   }
          //   Promise.all(apis).then((respList) => {
          //     dataList = res.list.concat(...respList.map((item) => item.list));
          //     const data = dataList.map((item) => ({
          //       seriesName: item[accumulation],
          //       area: item.area,
          //       areaName: item.areaName,
          //       discipline: item.disciplineName,
          //       disciplineName: item.disciplineName,
          //       subDiscipline: item.subDiscipline,
          //       subDisciplineName: item.subDiscipline,
          //       importance: item.importance,
          //       category: item.category,
          //       date: new Date(item.date),
          //       value: item.value,
          //     }));
          //     setTimeout(() => {
          //       setPeakLoading(false);
          //     }, 1000);
          //     setPeakOriginData([...data]);
          //     setPeakFilteredData([...data]);
          //   });
          // }
        }
      })
      .catch((err) => {
        console.error(err);
      });
    getAlerts(optimizationId);
  };

  const getAlerts = (optimizationId) => {
    setMemoLoading(true);
    APIList.getAlerts()
      .post({
        optimizationId,
        disciplines: disciplineIds,
        cwas: cwaIds,
        hasMemo: memoSwitch,
        memo: memoSearchValue,
      })
      .then((res: any) => {
        const acceptable = makeAlertItems(res.acceptable || []);
        const notAcceptable = makeAlertItems(res.notAcceptable || []);
        setAcceptableItems(acceptable.data);
        setNotAcceptableItems(notAcceptable.data);
        setAlertCount(acceptable.count + notAcceptable.count);
        setMemoLoading(false);
      });
  };

  useEffect(() => {
    if (optimizationData) {
      getAlerts(optimizationData.optimizationId);
    }
  }, [optimizationData, memoSwitch]);

  const makeAlertItems = (alerts) => {
    let count = 0;
    const data = alerts.map((alertItem) => ({
      ...alertItem,
      children: alertItem.children.map((period) => ({
        ...period,
        importanceCategory: importanceCategoryMap[period.importanceCategory],
        scheduleLink,
        mpLink,
        children: period.children.map((task) => {
          count++;
          return {
            ...task,
            scheduleLink: alertAction,
          };
        }),
      })),
    }));
    return {
      data,
      count,
    };
  };

  const getKpis = (optimizationIds) => {
    setKpiRoticLoading(true);
    APIList.getKpiRotic()
      .post({
        optimizationIds,
        disciplines: disciplineIds,
        cwas: cwaIds,
      })
      .then((res: any) => {
        setKpiRoticData(res);
        setKpiRoticLoading(false);
      });
    setBufferSuitabilityEvaluationLoading(true);
    setPathSuitabilityEvaluationLoading(true);
    setStrategyMatrixCategoryLoading(true);
    setTimelineDisciplineLoading(true);
    setStrategyMatrixDistributionLoading(true);
    APIList.getBufferSuitabilityEvaluation()
      .post({
        optimizationIds,
        disciplines: disciplineIds,
        cwas: cwaIds,
      })
      .then((res: any) => {
        setBufferSuitabilityEvaluationData(res);
        setBufferSuitabilityEvaluationLoading(false);
      })
      .catch(() => {
        message.error('Buffer Suitability Evaluation Data Error');
      });

    APIList.getPathSuitabilityEvaluation()
      .post({
        optimizationIds,
        disciplines: disciplineIds,
        cwas: cwaIds,
      })
      .then((res: any) => {
        setPathSuitabilityEvaluationData(res);
        setPathSuitabilityEvaluationLoading(false);
      })
      .catch(() => {
        message.error('Path Suitability Evaluation Data Error');
      });

    APIList.getStrategyMatrixCategory()
      .post({
        optimizationIds,
        disciplines: disciplineIds,
        cwas: cwaIds,
      })
      .then((res: any) => {
        setStrategyMatrixCategoryData(res);
        setStrategyMatrixCategoryLoading(false);
      })
      .catch(() => {
        message.error('Strategy Matrix Category Data Error');
      });

    APIList.getStrategyMatrixDistribution()
      .post({
        optimizationIds,
        disciplines: disciplineIds,
        cwas: cwaIds,
      })
      .then((res: any) => {
        setStrategyMatrixDistributionData(res);
        setStrategyMatrixDistributionLoading(false);
      })
      .catch(() => {
        message.error('Strategy Matrix Distribution Data Error');
      });

    APIList.getTimelineDiscipline()
      .post({
        optimizationIds,
        disciplines: disciplineIds,
      })
      .then((res: any) => {
        setTimelineDisciplineData(res);
        setTimelineDisciplineLoading(false);
      })
      .catch(() => {
        message.error('Timeline Discipline Data Error');
      });
  };

  useEffect(() => {
    if (!globalEvent) {
      return;
    }
    if (globalEvent.eventType === CHAT_EVENT.MOVE_TO_TASK) {
      alertAction({ title: globalEvent.data });
    }
  }, [globalEvent, ganttLoading]);

  useEffect(() => {
    // Chart reset 事件
    if (globalEvent && globalEvent.eventType === ECHART_EVENT.CHART_ITEM_SELECTED) {
      let activityIds = [];
      let parentId = '';
      if (globalEvent.chartTitle.value === 'Buffer Suitability Evaluation') {
        if (!bufferSuitabilityEvaluationData || bufferSuitabilityEvaluationData.length === 0) {
          return;
        }
        const selectedBufferData = bufferSuitabilityEvaluationData[0].find((item) => item.id === globalEvent.value[3]);
        if (!selectedBufferData) {
          return;
        }
        parentId = selectedBufferData.bufferId;
        activityIds = selectedBufferData.activityIds;
        setTaskListTitle('Activities - Buffer Suitability');
      } else if (globalEvent.chartTitle.value === 'Path Suitability Evaluation') {
        if (!pathSuitabilityEvaluationData || pathSuitabilityEvaluationData.length === 0) {
          return;
        }
        const selectedPathData = pathSuitabilityEvaluationData[0].find((item) => item.id === globalEvent.value[3]);
        if (!selectedPathData) {
          return;
        }
        parentId = selectedPathData.pathId;
        activityIds = selectedPathData.activityIds;
        setTaskListTitle('Activities - Path Suitability');
      } else if (globalEvent.chartTitle.value === 'Strategy Matrix Distribution') {
        if (!strategyMatrixDistributionData || strategyMatrixDistributionData.length === 0) {
          return;
        }
        const selectedDistributionData = strategyMatrixDistributionData[0].data.find((item) => item.id === globalEvent.value[3]);
        if (!selectedDistributionData) {
          return;
        }
        activityIds = [selectedDistributionData.wp];
        setTaskListTitle('Activities - Strategy Matrix');
      }
      setTaskListLoading(true);
      if (parentId) {
        setSelectedTasks([
          {
            key: parentId,
            title: parentId,
            selectable: false,
            children: activityIds.map((id) => ({
              key: id,
              title: id,
              link: alertAction,
            })),
          },
        ]);
      } else {
        setSelectedTasks(
          activityIds.map((id) => ({
            key: id,
            title: id,
            link: alertAction,
          }))
        );
      }

      setTaskListCollapsed(false);

      setTimeout(() => {
        setTaskListLoading(false);
      }, 500);

      globalEvent.eventType = null;
    }
  }, [globalEvent, pathSuitabilityEvaluationData, bufferSuitabilityEvaluationData, strategyMatrixDistributionData]);

  const getStartAndEndDates = (period) => {
    const [startDate, endDate] = period.split('~');
    return {
      start: startDate,
      end: endDate,
    };
  };

  useEffect(() => {
    if (optimizationData) {
      getAlerts(optimizationData.optimizationId);
    }
  }, [memoSearchValue]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!alertTaskCollapsed) {
      setTaskListCollapsed(true);
    }
    if (alertTaskCollapsed && taskListCollapsed) {
      setMenuCollapsed(false);
    } else {
      setMenuCollapsed(true);
    }
  }, [alertTaskCollapsed]);

  useEffect(() => {
    if (taskListCollapsed) {
      setSelectedTasks([]);
    } else {
      setAlertTaskCollapsed(true);
    }
    if (alertTaskCollapsed && taskListCollapsed) {
      setMenuCollapsed(false);
    } else {
      setMenuCollapsed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskListCollapsed]);

  useEffect(() => {
    if (!peakFilteredData) {
      return;
    }
    setPeakData(peakFilteredData);
  }, [peakFilteredData]);

  useEffect(() => {
    setEvmAxis(kpiRangeData.find((item) => item.kpiName === 'Evm'));
    setTimelineDisciplineAxis(kpiRangeData.find((item) => item.kpiName === 'TimelineDiscipline'));
    setStrategyMatrixDistributionAxis(kpiRangeData.find((item) => item.kpiName === 'StrategyMatrixDistribution'));
    setBufferSuitabilityEvaluationAxis(kpiRangeData.find((item) => item.kpiName === 'BufferSuitabilityEvaluation'));
    setPathSuitabilityEvaluationAxis(kpiRangeData.find((item) => item.kpiName === 'PathSuitabilityEvaluation'));
  }, [kpiRangeData]);

  useEffect(() => {
    setDeadline(project ? project.endDate : '2023-02-13');
  }, [project]);

  const tabChange = (tab) => {
    setCurrentTab(tab);
  };

  const [accumulation, setAccumulation] = useState('areaName');

  useEffect(() => {
    setPeakFilteredData(filterPeakData());
  }, [accumulation]);

  const filterPeakData = () => {
    const originData = peakOriginData.map((data) => ({
      ...data,
      seriesName: data[accumulation],
    }));
    return originData;
  };

  const getViewMode = () => (headerCollapsed ? 'table-only' : 'chart-table');

  const widget = (
    <>
      <div
        style={{
          display: 'inline-block',
          position: 'absolute',
          left: 40,
          textAlign: 'left',
          zIndex: 100,
        }}
      >
        <div
          style={{
            display: 'inline-block',
            textAlign: 'left',
          }}
        >
          <span style={{ paddingRight: 12 }}>{t('aipskd.optionDetail.peakTarget')}</span>
          <Select
            value={accumulation}
            style={{ width: 220 }}
            onChange={(value) => {
              setAccumulation(value);
            }}
            options={[
              {
                value: 'areaName',
                label: 'CWA',
              },
              {
                value: 'disciplineName',
                label: 'Discipline',
              },
              {
                value: 'subDisciplineName',
                label: 'Sub-Discipline',
              },
              {
                value: 'importance',
                label: 'Importance(1-9)',
              },
              {
                value: 'category',
                label: 'Importance Category(H,M,L)',
              },
            ]}
          />
        </div>
      </div>
    </>
  );

  return (
    <>
      <AlertTaskContext.Provider
        value={{
          alertTaskCollapsed,
          setAlertTaskCollapsed,
          taskListCollapsed,
          setTaskListCollapsed,
          alertView,
          setAlertView,
        }}
      >
        <DataSwitcher
          title={t('aipskd.optionDetail.optionDetail')}
          multiple={false}
          readonly={false}
          optimizationSelected={optimizationData}
          collapsed={alertTaskCollapsed && taskListCollapsed}
        >
          <ChartTableLayout viewMode={getViewMode()}>
            <ChartTableLayout.Chart>
              <div
                className="card border"
                style={{
                  height: chartHeight + 40,
                  padding: 0,
                  width: '100%',
                }}
              >
                <div>
                  <BasicInfoCard optimizationData={optimizationData} />
                  <ChartGroup
                    height={chartHeight}
                    loading={chartLoading}
                    isScrollChart
                    isSmallMode
                    syncScroll
                    sizeMode={sizeMode}
                    menuCollapsed={menuCollapsed}
                    scrollCount={scrollCount}
                    setScrollCount={setScrollCount}
                    selectorTop={selectorTop}
                  >
                    <ChartGroup.Item>
                      <EvmChart data={kpiRoticData[0]} loading={kpiRoticLoading} axis={evmAxis} />
                    </ChartGroup.Item>
                    <ChartGroup.Item>
                      <TimelineDisciplineChart
                        loading={timelineDisciplineLoading}
                        data={timelineDisciplineData[0]}
                        deadline={deadline}
                        axis={timelineDisciplineAxis}
                      />
                    </ChartGroup.Item>

                    <ChartGroup.Item>
                      <StrategyMatrixCategoryChart loading={strategyMatrixCategoryLoading} data={strategyMatrixCategoryData[0]} />
                    </ChartGroup.Item>

                    <ChartGroup.Item>
                      <StrategyMatrixDistributionChart
                        loading={strategyMatrixDistributionLoading}
                        uuid={optimizationData ? optimizationData.optimizationId : 1}
                        data={strategyMatrixDistributionData[0]}
                      />
                    </ChartGroup.Item>
                    <ChartGroup.Item>
                      <BufferSuitabilityEvaluationChart
                        loading={bufferSuitabilityEvaluationLoading}
                        data={bufferSuitabilityEvaluationData[0]}
                        axis={bufferSuitabilityEvaluationAxis}
                      />
                    </ChartGroup.Item>
                    <ChartGroup.Item>
                      <PathSuitabilityEvaluationChart
                        loading={pathSuitabilityEvaluationLoading}
                        data={pathSuitabilityEvaluationData[0]}
                        axis={pathSuitabilityEvaluationAxis}
                      />
                    </ChartGroup.Item>
                  </ChartGroup>
                </div>
              </div>
            </ChartTableLayout.Chart>
            <ChartTableLayout.Table>
              <div
                style={{
                  paddingTop: 4,
                  width: '100%',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: 30,
                    zIndex: 10,
                  }}
                >
                  <Badge
                    showZero
                    offset={[0, 6]}
                    size="small"
                    count={alertCount}
                    style={{
                      backgroundColor: color.errorColor,
                    }}
                  >
                    <Tooltip title={t('aipskd.optionDetail.alert')}>
                      <AlertOutlined
                        onClick={() => {
                          setAlertTaskCollapsed(!alertTaskCollapsed);
                        }}
                        className="view-detail-icon"
                        style={{
                          top: 4,
                          marginLeft: 20,
                          paddingRight: 10,
                          color: color.primaryColor,
                        }}
                      />
                    </Tooltip>
                  </Badge>

                  <DoubleRightOutlined
                    className="view-detail-icon"
                    style={{
                      top: 6,
                      marginLeft: 20,
                      marginBottom: 2,
                      color: color.primaryColor,
                      transform: !headerCollapsed ? 'rotate(-90deg)' : 'rotate(90deg)',
                      transition: 'all 0.2s',
                    }}
                    onClick={() => {
                      setHeaderCollapsed(!headerCollapsed);
                    }}
                  />
                </div>
                <Tabs
                  activeKey={currentTab}
                  type="card"
                  onChange={tabChange}
                  items={[
                    {
                      key: 'schedule',
                      label: 'Schedule',
                      children: (
                        <div
                          style={{
                            minHeight: tableHeightMap[getViewMode()],
                            transform: 'rotate(360deg)',
                          }}
                        >
                          {ganttData ? <GanttChart height={ganttLoading ? 0 : tableHeightMap[getViewMode()]} data={ganttData} /> : <></>}
                          {ganttLoading ? <Loading /> : <></>}
                        </div>
                      ),
                    },
                    {
                      key: 'peak',
                      label: 'MP Peak',
                      children: (
                        <div
                          style={{
                            minHeight: tableHeightMap[getViewMode()],
                            transform: 'rotate(360deg)',
                          }}
                        >
                          <div style={{ display: peakLoading ? 'none' : 'block' }}>
                            {widget}
                            <DateFilteredBarChart
                              accumulation={accumulation}
                              data={peakData}
                              chartOption={chartOption}
                              styles={{
                                height: tableHeightMap[getViewMode()] - 30,
                              }}
                              dateFilterOption={{
                                position: 'right',
                              }}
                            />
                          </div>
                          {peakLoading ? <Loading /> : <></>}
                        </div>
                      ),
                    },
                  ]}
                ></Tabs>
              </div>
            </ChartTableLayout.Table>
          </ChartTableLayout>
        </DataSwitcher>
        <TaskAlert
          styles={{
            top: tableTop,
            height: '100%',
            width: tableWidth,
          }}
          acceptableItems={acceptableItems}
          notAcceptableItems={notAcceptableItems}
          optimizationId={optimizationData?.optimizationId}
          loading={memoLoading}
        />

        <TaskList
          title={taskListTitle}
          styles={{
            top: tableTop,
            height: '100%',
            width: tableWidth,
          }}
          items={selectedTasks}
          loading={taskListLoading}
        />
      </AlertTaskContext.Provider>
    </>
  );
};

export default PlanOptionDetailPage;
