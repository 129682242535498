import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { AppContext } from '../../contexts/AppContext';
import { SingleSegmentChart, ECHART_EVENT } from '@meteor/frontend-core';
import { getSizeByMap } from '../../utils/commonUtil';

type ChartProps = {
  data: any;
  sizeMode?: string;
  height?: number;
  loading?: boolean;
};

const StrategyMatrixCategoryChart: React.FC<ChartProps> = (props) => {
  const { sizeMode = 'small', data, height, loading } = props;
  const { color, globalEvent, echartTheme } = useContext(AppContext);
  const { t } = useTranslation();

  const sizeMap = {
    small: {
      title: 18,
      legend: 10,
      yAxis: 12,
      xAxis: 10,
      gridBottom: 40,
      segmentPaddingLeft: 12,
    },
    big: {
      title: 28,
      legend: 18,
      yAxis: 18,
      xAxis: 18,
      gridBottom: 30,
    },
  };

  const getHeight = () => (sizeMode === 'big' ? 180 + (height - 322) - 10 : 180);

  const commonOptions: any = {
    tooltip: {
      show: true,
    },
    // legend: {
    //   top: '30%',
    //   right: '10%',
    //   width: 20,
    //   data: ['Priority High', 'Priority Middle', 'Priority Low'],
    // },
    series: [
      {
        type: 'pie',
        center: ['50%', '50%'],
        label: {
          show: true,
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        data: [],
      },
    ],
  };

  const [options, setOptions] = useState({
    title: {
      value: 'Strategy Matrix Category',
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    contentList: [],
    segment: {
      default: 'By Importance',
      list: [
        {
          label: 'By Importance',
          value: 'By Importance',
        },
        {
          label: 'By Count',
          value: 'By Count',
        },
      ],
      styles: {
        float: 'right',
        marginTop: -36,
        marginRight: 16,
        display: 'none',
      },
      blocked: false,
    },
    tooltips: ['zoomIn', 'label'],
    showSeriesLabel: true,
    chartOptions: {
      'By Importance': cloneDeep(commonOptions),
      'By Count': cloneDeep(commonOptions),
    },
  });

  options.chartOptions['By Importance'].series[0].roseType = false;
  options.chartOptions['By Count'].series[0].roseType = 'radius';
  options.chartOptions['By Count'].series[0].radius = [10, '75%'];

  const initData = () => {
    const chartData: any[] = [
      {
        itemStyle: {
          color: color.errorColor,
        },
        label: {
          formatter: () => 'Priority High',
        },
        name: 'Priority High',
      },
      {
        itemStyle: {
          color: color.warningColor,
        },
        label: {
          formatter: () => 'Priority Middle',
        },
        name: 'Priority Middle',
      },
      {
        itemStyle: {
          color: color.successColor,
        },
        label: {
          formatter: () => 'Priority Low',
        },
        name: 'Priority Low',
      },
    ];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element.importance === 1 || element.importance === 2 || element.importance === 4) {
        // red
        chartData[0].value = chartData[0].value || 0;
        chartData[0].value += element.count;
      }
      if (element.importance === 3 || element.importance === 5 || element.importance === 7) {
        // yellow
        chartData[1].value = chartData[1].value || 0;
        chartData[1].value += element.count;
      }
      if (element.importance === 6 || element.importance === 8 || element.importance === 9) {
        // green
        chartData[2].value = chartData[2].value || 0;
        chartData[2].value += element.count;
      }
    }
    options.chartOptions['By Importance'].series[0].data = cloneDeep(chartData);
    options.chartOptions['By Count'].series[0].data = cloneDeep(chartData.sort((a, b) => b.value - a.value));

    setOptions(cloneDeep(options));
  };

  useEffect(() => {
    if (data && data.length > 0) {
      initData();
    }
  }, [data]);

  useEffect(() => {
    // Chart reset 事件
    if (
      globalEvent &&
      globalEvent.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED &&
      globalEvent.chartTitle.value === 'Strategy Matrix Category'
    ) {
      initData();
    }
  }, [globalEvent]);

  return (
    <>
      <SingleSegmentChart
        segment={options.segment}
        contentList={options.contentList}
        chartOptions={{ ...options.chartOptions, echartTheme }}
        showSeriesLabel={options.showSeriesLabel}
        title={options.title}
        height={getHeight()}
        tooltips={options.tooltips}
        loading={loading}
        isBank={!data || data.length === 0}
      />
    </>
  );
};

export default StrategyMatrixCategoryChart;
