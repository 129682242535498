import React, { useState, useEffect, useRef, useContext } from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import { EChartsOption } from 'echarts-for-react';
import { FilterData, filterDataByDate, FilterType } from './date-range-mapper';
import AlertTaskContext from '../../contexts/AlertTaskContext';
import { round } from '../../utils/commonUtil';
import { AppContext } from '../../contexts/AppContext';
import { MeteorEchart } from '@meteor/frontend-core';

// @flow
/**
 * @public
 */
export type DateFilterPosition = 'left' | 'center' | 'right';

export type BarChartData = {
  seriesName: string;
  date: Date;
  value: number;
  [key: string]: any;
};

export type FilterOptions = {
  label: string;
  value: FilterType;
};

export type DateFilterOption = {
  defaultFilterType?: FilterType;
  filterOptions?: FilterOptions[];
  position?: DateFilterPosition;
};

export type BarChartStyles = {
  height?: number;
};

export type DateFilteredBarChartData = {
  data: BarChartData[];
  chartOption?: EChartsOption;
  dateFilterOption?: DateFilterOption;
  styles?: BarChartStyles;
  accumulation?: string;
};

const defaultFilterOptions: FilterOptions[] = [
  { label: 'Year', value: 'Year' },
  { label: 'Month', value: 'Month' },
  { label: 'Week', value: 'Week' },
  { label: 'Day', value: 'Day' },
];

const defaultDateFilterOption: any = {
  defaultFilterType: 'Month',
  filterOptions: defaultFilterOptions,
  position: 'center',
};

const DateFilteredBarChart: React.FC<DateFilteredBarChartData> = ({ chartOption, data, styles = {}, dateFilterOption, accumulation }) => {
  const { alertView } = useContext(AlertTaskContext);
  const { color, echartTheme } = useContext(AppContext);

  const defaultOption: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      appendToBody: true,
      className: 'overflow-all',
      hideDelay: 0,
      order: 'seriesDesc',
    },
    xAxis: {
      type: 'category',
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: true,
      },
      z: 10,
    },
    yAxis: {
      name: 'MP',
      position: 'left',
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    grid: {
      left: '5%',
      right: '5%',
      bottom: '20%',
      height: '70%',
      top: '10%',
      containLabel: true,
    },
    dataZoom: [
      {
        type: 'inside',
      },
      {
        type: 'slider',
      },
    ],
    series: [],
  };
  const [filterOption, setFilterOption] = useState<DateFilterOption>({
    ...defaultDateFilterOption,
    ...dateFilterOption,
  });
  const [filterType, setFilterType] = useState<FilterType>(filterOption.defaultFilterType || defaultDateFilterOption.defaultFilterType);
  const [option, setOption] = useState<EChartsOption>({
    ...defaultOption,
    ...chartOption,
  });

  const [filterData, setFilterData] = useState<FilterData | null>(null);

  const filterDataList: any = {};

  const instance = useRef(null);

  const setAlertColor = () => {
    // option.xAxis.data;
    return {
      ...option,
      series: option.series.map((item) => {
        if (item.name === alertView.seriesName) {
          return {
            ...item,
            data: item.data.map((value, index) => {
              if (index === alertView.index) {
                if (value.itemStyle) {
                  return {
                    ...value,
                    itemStyle: { borderColor: 'red', borderWidth: 2 },
                  };
                }
                return {
                  value: value,
                  itemStyle: { borderColor: 'red', borderWidth: 2 },
                };
              }
              return value;
            }),
          };
        }
        return item;
      }),
    };
  };

  useEffect(() => {
    if (!alertView || alertView.tab !== 'peak' || !alertView.seriesName) {
      return;
    }
    if (instance && instance.current) {
      const curr: any = instance.current;
      const inst = curr.getEchartsInstance();
      const op = setAlertColor();
      inst.setOption(op);
      setOption({
        ...op,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertView]);

  useEffect(() => {
    if (!filterType || !option) {
      return;
    }
    const filtered =
      filterDataList[filterType] ||
      filterDataByDate(
        filterType,
        data.filter((item) => item.seriesName).map((item) => ({ ...item, day: item.date.toDateString() }))
      );

    if (!filterDataList[filterType]) {
      // cache data
      filterDataList[filterType] = filtered;
    }

    filtered.series = filtered.series.sort((a: any, b: any) => {
      const order = { H: 2, M: 1, L: 0 };
      const contains = Object.keys(order);
      if (contains.includes(a.name) && contains.includes(b.name)) {
        return order[a.name] - order[b.name];
      }
      if (typeof a.name === 'string' && typeof b.name === 'string') {
        return b.name > a.name ? 1 : -1;
      }
      return b.name - a.name;
    });
    setFilterData(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const filtered = filterDataByDate(
      filterType,
      data.filter((item) => item.seriesName).map((item) => ({ ...item, day: item.date.toDateString() }))
    );

    if (!filterDataList[filterType]) {
      // cache data
      filterDataList[filterType] = filtered;
    }
    filtered.series = filtered.series.sort((a: any, b: any) => {
      const order = { H: 2, M: 1, L: 0 };
      const contains = Object.keys(order);
      if (contains.includes(a.name) && contains.includes(b.name)) {
        return order[a.name] - order[b.name];
      }
      if (typeof a.name === 'string' && typeof b.name === 'string') {
        return b.name > a.name ? 1 : -1;
      }
      return b.name - a.name;
    });
    setFilterData(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!filterData) {
      return;
    }
    option.xAxis.data = filterData.timeline;

    const baseSeries = {
      type: 'bar',
      stack: 'mp',
      barCategoryGap: filterType === 'Day' ? '0%' : '20%',
    };

    // option.series = option.series.map((element) => ({
    //   ...baseSeries,
    //   ...element,
    // }));

    // const totalIndex = option.series.findIndex(
    //   (series) => series.name === 'total'
    // );
    const totalSeries = {
      name: 'total',
      type: 'bar',
      itemStyle: {
        color: '#da7705',
        borderColor: '#da7705',
        opacity: 0,
      },
      barGap: '-100%',
      data: filterData.timeline.map((item, index) => {
        let value = 0;
        filterData.series.forEach((seriesData) => {
          value += seriesData.values[index];
        });
        return round(value);
      }),
    };
    // if (totalIndex > -1) {
    //   option.series[totalIndex] = totalSeries;
    // } else {
    //   option.series.push(totalSeries);
    // }
    option.series = [];
    option.series.push(totalSeries);
    filterData.series.forEach((item) => {
      // const index = option.series.findIndex(
      //   (series) => series.name === item.name
      // );
      // if (index > -1) {
      //   option.series[index] = {
      //     ...baseSeries,
      //     name: item.name,
      //     data: item.values,
      //   };
      // } else {
      //   option.series.push({
      //     ...baseSeries,
      //     name: item.name,
      //     data: item.values,
      //   });
      // }
      const seriesInfo: any = {
        ...baseSeries,
        name: item.name,
        data: item.values.map((value) => round(value)),
      };
      if (accumulation === 'category' && item.name) {
        seriesInfo.itemStyle = {
          color: (() => {
            if (item.name === 'H') {
              return color.errorColor;
            }
            if (item.name === 'M') {
              return color.warningColor;
            }
            if (item.name === 'L') {
              return color.successColor;
            }
            return '';
          })(),
        };
      }
      option.series.push(seriesInfo);
    });

    option.legend.data = option.series
      .filter((item) => item.name !== 'total')
      .map((item) => ({
        ...item,
        // nameはタイプnumberの可能性がある、必ずタイプstringになります
        name: item.name ? item.name.toString() : '',
      }))
      .sort((a, b) => {
        const order = { H: 2, M: 1, L: 0 };
        const contains = Object.keys(order);
        if (contains.includes(a.name) && contains.includes(b.name)) {
          return order[b.name] - order[a.name];
        }
        if (typeof a.name === 'string' && typeof b.name === 'string') {
          return b.name < a.name ? 1 : -1;
        }
        return b.name - a.name;
      });

    let legendTotalWidth = 0;
    const legendItemWidth = 25;
    const letterWidth = 10;
    const offset = 100;

    option.legend.data.forEach((item) => {
      legendTotalWidth += legendItemWidth + item.name.length * letterWidth;
    });

    if (typeof document !== 'undefined') {
      const container = document.getElementsByClassName('ant-layout-content');

      if (container.length > 0) {
        if (legendTotalWidth > container[0].clientWidth - offset) {
          option.grid.top = 80;
        } else {
          option.grid.top = 60;
        }
      }
    }

    if (alertView && alertView.tab === 'peak' && alertView.seriesName) {
      option.series = setAlertColor().series;
    }
    const curr: any = instance.current;
    const inst = curr?.getEchartsInstance();
    if (inst) {
      inst.clear();
      inst.setOption({ ...option });
      setOption({
        ...option,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  const onTimeTypeChange = ({ target: { value } }: RadioChangeEvent) => {
    setFilterType(value);
  };

  return (
    <>
      <div
        style={{
          position: 'relative',
          textAlign: filterOption.position,
          paddingLeft: 40,
          paddingRight: 40,
        }}
      >
        <Radio.Group
          options={filterOption.filterOptions}
          onChange={onTimeTypeChange}
          value={filterType}
          optionType="button"
          buttonStyle="solid"
        />
      </div>
      <MeteorEchart chartRef={instance} option={{ ...option, echartTheme }} style={{ height: styles.height }} />
    </>
  );
};

DateFilteredBarChart.defaultProps = {
  chartOption: {},
  styles: {
    height: 500,
  },
  dateFilterOption: defaultDateFilterOption,
};

export default DateFilteredBarChart;
