import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { maxBy, minBy, uniqBy } from 'lodash';
import dayjs from 'dayjs';
import { AppContext } from '../../contexts/AppContext';
import { SingleSelectChart, ECHART_EVENT } from '@meteor/frontend-core';
import { CurrencyMapping } from '../../consts/currencyMapping';
import { getAllDay } from '../../utils/DateUtil';
import { getSizeByMap, round } from '../../utils/commonUtil';

type EvmComparisonData = {
  data: {
    costItems: {
      pvCompareData: {
        name: string;
        data: any[];
      }[];
      evCompareData: {
        name: string;
        data: any[];
      }[];
      acCompareData: {
        name: string;
        data: any[];
      }[];
      areaData: number;
    };
    today;
    dueDate;
    deliveryDate;
    timeline;
  };
  height?: number;
  sizeMode?: string;
  loading?: boolean;
  axis?: any;
};

const EvmComparisonChart: React.FC<EvmComparisonData> = (props) => {
  const { dataSection, selectOptions: selectOptionsFunc, color, echartTheme } = useContext(AppContext);
  const [projectUnit, setProjectUnit] = useState<string>();

  const onFilterData = () => {
    const { projectId } = dataSection;
    const { projects } = selectOptionsFunc;
    const projectInfo = projects.filter((item) => projectId === item.projectId);
    setProjectUnit(projectInfo.length !== 0 ? projectInfo[0].currency : null);
  };

  useEffect(() => {
    if (dataSection && selectOptionsFunc) {
      onFilterData();
    }
  }, [dataSection]);

  const {
    sizeMode = 'small',
    data,
    loading,
    height,
    axis, // 我是所有方案日期和dueDate中最大的
  } = props;

  const sizeMap = {
    small: {
      title: 18,
      legend: 10,
      yAxis: 12,
      xAxis: 10,
      gridBottom: 40,
      segmentPaddingLeft: 12,
    },
    big: {
      title: 28,
      legend: 18,
      yAxis: 18,
      xAxis: 18,
      gridBottom: 30,
    },
  };

  const [options, setOptions] = useState<any>({
    tooltips: ['zoomIn', 'reset'],
    mouseWheelZoomLock: false,
    title: {
      value: 'TIC Comparison',
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    contentList: [],
    chartOptions: {},
  });
  const [selectOptions, setSelectOptions] = useState({
    default: '',
    list: [],
  });

  const { t } = useTranslation();

  const initData = () => {
    const legendData: any[] = [];
    const seriesData: any[] = [];
    let timelineData: any[] = [];
    const dataZoomData = [
      {
        show: false,
        start: 0,
        end: 100,
        xAxisIndex: [0, 1],
        height: '6px',
        showDataShadow: false,
      },
      {
        type: 'inside',
        start: 0,
        end: 100,
        xAxisIndex: [0, 1],
        height: '6px',
      },
      {
        show: false,
        start: 0,
        end: 100,
        yAxisIndex: [0, 1],
        height: '6px',
        showDataShadow: false,
      },
      {
        type: 'inside',
        start: 0,
        end: 100,
        yAxisIndex: [0, 1],
        height: '6px',
      },
    ];
    const optionInfos: any = {};
    const optionInfo: any = {
      echartTheme,
      grid: [
        {
          left: 60,
          right: 40,
          bottom: 25,
          top: 65,
        },
      ],
      xAxis: {
        type: 'time',
        boundaryGap: false,
        show: true,
        data: timelineData,
        axisLabel: {
          hideOverlap: true,
          formatter: (value) => dayjs(value).format('YYYY-MM-DD'),
        },
        minInterval: (new Date(axis.xAxisMaxValue).getTime() - new Date(axis.xAxisMinValue).getTime()) / 3,
        maxInterval: (new Date(axis.xAxisMaxValue).getTime() - new Date(axis.xAxisMinValue).getTime()) / 2,
      },
      yAxis: {
        type: 'value',
        name: `TIC(万${CurrencyMapping[projectUnit]})`,
        nameLocation: 'end',
        nameGap: 10,
        splitNumber: 3,
        axisLine: {
          show: true,
        },
        axisLabel: {
          show: true,
          formatter: (value) => Number(value / 10000).toFixed(0),
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          animation: false,
        },
        appendToBody: true,
        className: 'overflow-all',
        hideDelay: 0,
        formatter: (params) => {
          let str = `<div style="text-align: left">${params[0].data[0]}<br/>`;
          const tooltips = [];
          const findDue = params.find((item) => item.seriesName === 'due');
          const findNow = params.find((item) => item.seriesName === 'now');
          if (findDue) {
            str += `${findDue.marker}Due: ${findDue.data[0]}<br/>`;
          }
          if (findNow) {
            str += `${findNow.marker}Now: ${findNow.data[0]}<br/>`;
          }
          for (let index = 0; index < params.length; index++) {
            const item = params[index];
            if (item.data && item.seriesName !== 'due' && item.seriesName !== 'now') {
              tooltips.push({
                value: round(item.data[1]),
                name: `${item.marker}${item.seriesName}: ${item.data[1] ? parseInt(item.data[1]).toLocaleString() : 0}<br/>`,
              });
            }
          }
          const sorted = uniqBy(tooltips, 'name')
            .sort((a, b) => b.value - a.value)
            .map((item) => item.name);
          str += `${sorted.join('')}</div>`;
          return str;
        },
      },
      legend: {
        data: legendData,
      },
      dataZoom: dataZoomData,
      series: seriesData,
    };
    const selects = [];
    if (data) {
      const { costItems, today, dueDate }: any = data;
      let todayIndex = 0;

      if (costItems.pvCompareData && costItems.pvCompareData.length > 0) {
        // select
        selects.push({ key: 'PV', value: 'PV', label: 'PV' });

        const minItem = minBy(costItems.pvCompareData, (item: any) => item.data[0][0]);
        const maxItem = maxBy(costItems.pvCompareData, (item: any) => item.data[item.data.length - 1][0]);
        timelineData = getAllDay(
          axis.xAxisMinValue || minItem.data[0][0],
          axis.xAxisMaxValue
            ? new Date(axis.xAxisMaxValue).getTime() > new Date(dueDate).getTime()
              ? axis.xAxisMaxValue
              : dueDate
            : new Date(maxItem.data[maxItem.data.length - 1][0]).getTime() > new Date(dueDate).getTime()
            ? maxItem.data[maxItem.data.length - 1][0]
            : dueDate,
          20
        );

        costItems.pvCompareData.forEach((compareData) => {
          todayIndex = compareData.data.findIndex((item) => item[0] === today);
          legendData.push({
            name: compareData.name,
            // itemStyle: {
            //   color: colors.green,
            // },
          });
          seriesData.push({
            key: `${compareData.name}-PV`,
            name: compareData.name,
            data: compareData.data,
            type: 'line',
            symbolSize: 0,
            smooth: true,
            // markLine: {
            //   symbol: ['none', 'none'],
            //   label: { show: false },
            //   lineStyle: {
            //     color: '#808080',
            //   },
            //   data:
            //     todayIndex > 0
            //       ? [{ yAxis: compareData.data[todayIndex][1] }]
            //       : [],
            // },
          });
        });

        seriesData.push({
          name: 'now',
          data: [
            [today, 0],
            [today, costItems.pvCompareData[0].data[costItems.pvCompareData[0].data.length - 1][1] * 1.1],
          ],
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            color: '#808080',
            type: 'dashed',
          },
          itemStyle: {
            color: '#808080',
          },
        });
        seriesData.push({
          name: 'due',
          data: [
            [dueDate, 0],
            [dueDate, costItems.pvCompareData[0].data[costItems.pvCompareData[0].data.length - 1][1] * 1.1],
          ],
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            color: color.errorColor,
          },
          itemStyle: {
            color: color.errorColor,
          },
        });

        optionInfo.xAxis.data = timelineData;
        optionInfo.legend.data = legendData;
        optionInfo.series = seriesData;
        optionInfos.PV = optionInfo;
      }
      if (costItems.evCompareData && costItems.evCompareData.length > 0) {
        // select
        selects.push({ key: 'EV', value: 'EV', label: 'EV' });

        const minItem = minBy(costItems.evCompareData, (item: any) => item.data[0][0]);
        const maxItem = maxBy(costItems.evCompareData, (item: any) => item.data[item.data.length - 1][0]);
        timelineData = getAllDay(
          axis.xAxisMinValue || minItem.data[0][0],
          axis.xAxisMaxValue
            ? new Date(axis.xAxisMaxValue).getTime() > new Date(dueDate).getTime()
              ? axis.xAxisMaxValue
              : dueDate
            : new Date(maxItem.data[maxItem.data.length - 1][0]).getTime() > new Date(dueDate).getTime()
            ? maxItem.data[maxItem.data.length - 1][0]
            : dueDate,
          20
        );
        costItems.evCompareData.forEach((compareData) => {
          todayIndex = compareData.data.findIndex((item) => item[0] === today);
          legendData.push({
            name: compareData.name,
          });
          seriesData.push(
            {
              key: `${compareData.name}-EV`,
              name: compareData.name,
              data: [...compareData.data].filter((item, index) => index <= todayIndex),
              type: 'line',
              symbolSize: 0,
              smooth: true,
            },
            {
              name: compareData.name,
              data: [...compareData.data].map((item, index) => (index < todayIndex ? null : item)),
              type: 'line',
              symbolSize: 0,
              smooth: true,
            }
          );
        });
        seriesData.push({
          name: 'now',
          data: [
            [today, 0],
            [today, costItems.evCompareData[0].data[costItems.evCompareData[0].data.length - 1][1] * 1.1],
          ],
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            color: '#808080',
            type: 'dashed',
          },
          itemStyle: {
            color: '#808080',
          },
        });
        seriesData.push({
          name: 'due',
          data: [
            [dueDate, 0],
            [dueDate, costItems.evCompareData[0].data[costItems.evCompareData[0].data.length - 1][1] * 1.1],
          ],
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            color: color.errorColor,
          },
          itemStyle: {
            color: color.errorColor,
          },
        });
        optionInfo.xAxis.data = timelineData;
        optionInfo.legend.data = legendData;
        optionInfo.series = seriesData;
        optionInfos.EV = optionInfo;
      }
      if (costItems.acCompareData && costItems.acCompareData.length > 0) {
        // select
        selects.push({ key: 'AC', value: 'AC', label: 'AC' });

        const minItem = minBy(costItems.acCompareData, (item: any) => item.data[0][0]);
        const maxItem = maxBy(costItems.acCompareData, (item: any) => item.data[item.data.length - 1][0]);
        timelineData = getAllDay(
          axis.xAxisMinValue || minItem.data[0][0],
          axis.xAxisMaxValue
            ? new Date(axis.xAxisMaxValue).getTime() > new Date(dueDate).getTime()
              ? axis.xAxisMaxValue
              : dueDate
            : new Date(maxItem.data[maxItem.data.length - 1][0]).getTime() > new Date(dueDate).getTime()
            ? maxItem.data[maxItem.data.length - 1][0]
            : dueDate,
          20
        );
        costItems.acCompareData.forEach((compareData) => {
          todayIndex = compareData.data.findIndex((item) => item[0] === today);
          legendData.push({
            name: compareData.name,
          });
          seriesData.push(
            {
              key: `${compareData.name}-AC`,
              name: compareData.name,
              data: [...compareData.data].filter((item, index) => index <= todayIndex),
              type: 'line',
              symbolSize: 0,
              smooth: true,
            },
            {
              name: compareData.name,
              data: [...compareData.data].map((item, index) => (index < todayIndex ? null : item)),
              type: 'line',
              symbolSize: 0,
              smooth: true,
            }
          );
        });
        seriesData.push({
          name: 'now',
          data: [
            [today, 0],
            [today, costItems.acCompareData[0].data[costItems.acCompareData[0].data.length - 1][1] * 1.1],
          ],
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            color: '#808080',
            type: 'dashed',
          },
          itemStyle: {
            color: '#808080',
          },
        });
        seriesData.push({
          name: 'due',
          data: [
            [dueDate, 0],
            [dueDate, costItems.acCompareData[0].data[costItems.acCompareData[0].data.length - 1][1] * 1.1],
          ],
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            color: color.errorColor,
          },
          itemStyle: {
            color: color.errorColor,
          },
        });
        optionInfo.xAxis.data = timelineData;
        optionInfo.legend.data = legendData;
        optionInfo.series = seriesData;
        optionInfos.AC = optionInfo;
      }
    }

    setSelectOptions({
      default: 'PV',
      list: selects,
    });

    options.chartOptions = optionInfos;
    setOptions({
      ...options,
    });
  };

  useEffect(() => {
    if (axis) {
      initData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, axis, echartTheme]);

  const getHeight = () => (sizeMode === 'big' ? 180 + (height - 322) - 10 : 180);

  const chartEventHandler = (globalEvent) => {
    if (globalEvent && globalEvent.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED) {
      initData();
    }
  };

  return (
    <>
      <SingleSelectChart
        size="small"
        contentList={options.contentList}
        chartOptions={options.chartOptions}
        title={options.title}
        height={getHeight()}
        select={selectOptions}
        loading={loading}
        layout="inline"
        mouseWheelZoomLock={options.mouseWheelZoomLock}
        tooltips={options.tooltips}
        isBank={
          !data ||
          ((!data.costItems.evCompareData || data.costItems.evCompareData.length === 0) &&
            (!data.costItems.pvCompareData || data.costItems.pvCompareData.length === 0) &&
            (!data.costItems.acCompareData || data.costItems.acCompareData.length === 0))
        }
        onChartEvent={(event) => {
          chartEventHandler(event);
        }}
      />
    </>
  );
};

export default EvmComparisonChart;
