import dayjs from 'dayjs';
import {
  Button, Divider, Popconfirm, Tooltip, Spin
} from 'antd';
import {
  BulbOutlined,
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  HddOutlined,
  PlayCircleOutlined,
  StopOutlined,
  DeploymentUnitOutlined,
  CheckCircleOutlined,
  LinkOutlined
} from '@ant-design/icons';
import APIList from '../http/ApiList';
import { cloneDeep } from 'lodash';
import { INPUT_TABLE, OPERATION } from '../pages/plan';

export const PROJECT_PLAN_TABLE = ({
  t,
  tableData,
  tableTop,
  loading,
  showModal,
  showDeleteModal,
  showInputTable,
  setTableData,
  customProperty,
  navigate,
  message
}) => ({
  columns: PROJECT_PLAN_TABLE_COLUMNS({
    t,
    showModal,
    showDeleteModal,
    showInputTable,
    tableData,
    setTableData,
    customProperty,
    navigate,
    message
  }),
  data: tableData,
  size: 'small',
  childrenColumnName: 'optimize',
  height: window.innerHeight - tableTop,
  bordered: true,
  loading,
});

const render = (text, record, index) => (text ? <>{text}</> : <>-</>);

const renderDate = (text, record, index) => (
  <>{dayjs(text).format('YYYY-MM-DD')}</>
);
const renderPlanLink = (text, record, index) => (
  <>

    {record.optimizeId ? (
      <></>
    ) : (
      <>
        <span title={text} className='ellipsis' style={{ display: 'inline-block', maxWidth: 220, marginRight: 15 }}>{text}</span>
        <Tooltip title={record.planDescription}>
          <FileTextOutlined style={{ position: 'relative', bottom: 5 }} />
        </Tooltip>
      </>
    )}
  </>
);

const renderOptimizeLink = (text, record, index, navigate) =>
  text ? (
    <>
      {record.originalJobReady === 0 && record.optimizeJobReady === 0 ? <span style={{ padding: '4px 15px' }}>{text}</span> : (
        <Button
          type="link"
          onClick={() => {
            navigate(`/aipskd/plan-option?optimizeId=${record.optimizeId}`)
          }}
        >
          {text}
        </Button>
      )}

    </>
  ) : (
    <>-</>
  );

const createOriginalJob = (record: any, tableData, setTableData, setTime, setJobApiRuning) => {
  setJobApiRuning(true);
  APIList.startOriginal().post({
    projectId: record.projectId,
    planId: record.planId,
    planName: record.planName,
    stage: record.stage,
    optimizeId: record.optimizeId,
    optimizeName: record.optimizeName,
    dataCrossSection: record.dataCrossSection,
  }).then(({ id, status, workflowName }: any) => {
    setTableData(cloneDeep(tableData.map(item => {
      const optimizes = item.optimize;
      optimizes.forEach((optimize) => {
        if (record.optimizeId === optimize.optimizeId) {
          optimize.originalStatus = status;
          optimize.jobId = id;
          optimize.workflowName = workflowName;
          if (status === 3) {
            optimize.originalJobReady = 1;
          }
        }
      });
      return item;
    })));
  }).catch((error) => {
    setTableData(cloneDeep(tableData.map(item => {
      const optimizes = item.optimize;
      optimizes.forEach((optimize) => {
        if (record.optimizeId === optimize.optimizeId) {
          optimize.originalStatus = undefined;
        }
      });
      return item;
    })));
  }).finally(() => {
    setTime(5000);
    setJobApiRuning(false);
  });
};

const createOptimizationJob = (record: any, tableData, setTableData, setTime, setJobApiRuning) => {
  setJobApiRuning(true);
  APIList.startOptimization().post({
    projectId: record.projectId,
    planId: record.planId,
    planName: record.planName,
    stage: record.stage,
    optimizeId: record.optimizeId,
    optimizeName: record.optimizeName,
    dataCrossSection: record.dataCrossSection,
  }).then(({ id, status, workflowName }: any) => {
    setTableData(cloneDeep(tableData.map(item => {
      const optimizes = item.optimize;
      optimizes.forEach((optimize) => {
        if (record.optimizeId === optimize.optimizeId) {
          optimize.optimizeStatus = status;
          optimize.jobId = id;
          optimize.workflowName = workflowName;
          if (status === 3) {
            optimize.optimizeJobReady = 1;
          }
        }
      });
      return item;
    })));
    // message.success('最適化ジョブを作成されました');
  }).catch((error) => {
    setTableData(cloneDeep(tableData.map(item => {
      const optimizes = item.optimize;
      optimizes.forEach((optimize) => {
        if (record.optimizeId === optimize.optimizeId) {
          optimize.optimizeStatus = undefined;
        }
      });
      return item;
    })));
  }).finally(() => {
    setTime(5000);
    setJobApiRuning(false);
  });
};

const createDataLinkage = (record: any, tableData, setTableData, setTime, setJobApiRuning) => {
  setJobApiRuning(true);
  APIList.startDataLinkage().post({
    projectId: record.projectId,
    planId: record.planId,
    planName: record.planName,
    stage: record.stage,
    optimizeId: record.optimizeId,
    optimizeName: record.optimizeName,
    optimizationId: record.optimizationId,
    dataCrossSection: record.dataCrossSection,
  }).then(({ id, status, workflowName }: any) => {
    setTableData(cloneDeep(tableData.map(item => {
      const optimizes = item.optimize;
      optimizes.forEach((optimize) => {
        if (record.optimizeId === optimize.optimizeId) {
          optimize.dataLinkageStatus = status;
          optimize.dataLinkage = 2;
          optimize.jobId = id;
          optimize.workflowName = workflowName;
        }
      });
      return item;
    })));
    // message.success('マスタデータ連携を作成されました');
  }).catch((error) => {
    setTableData(cloneDeep(tableData.map(item => {
      const optimizes = item.optimize;
      optimizes.forEach((optimize) => {
        if (record.optimizeId === optimize.optimizeId) {
          optimize.dataLinkageStatus = undefined;
        }
      });
      return item;
    })));
  }).finally(() => {
    setJobApiRuning(false);
    setTime(5000);
  });
};

const stopOptimizationJobStatus = (record: any, tableData, setTableData, setTime, setJobApiRuning, message, t) => {
  setJobApiRuning(true);
  setTime(null);
  APIList.stopOptimization().post({
    id: record.jobId,
    workflowName: record.workflowName
  })
    .then((data: any) => {
      setTableData(cloneDeep(tableData.map(item => {
        const optimizes = item.optimize;
        optimizes.forEach((optimize) => {
          if (record.optimizeId === optimize.optimizeId) {
            optimize.optimizeStatus = undefined;
          }
        });
        return item;
      })));
      message.success(t('aipskd.jobList.stopOptimizeMessage'))
    })
    .catch((err) => {
      console.error(err);
    }).finally(() => {
      setJobApiRuning(false);
      setTime(5000);
    });
};

const stopOriginalJobStatus = (record: any, tableData, setTableData, setTime, setJobApiRuning, message, t) => {
  setJobApiRuning(true);
  setTime(null);
  APIList.stopOriginal().post({
    id: record.jobId,
    workflowName: record.workflowName
  })
    .then((data: any) => {
      setTableData(cloneDeep(tableData.map(item => {
        const optimizes = item.optimize;
        optimizes.forEach((optimize) => {
          if (record.optimizeId === optimize.optimizeId) {
            optimize.originalStatus = undefined;
          }
        });
        return item;
      })));
      message.success(t('aipskd.jobList.stopOriginalMessage'))
    })
    .catch((err) => {
      console.error(err);
    }).finally(() => {
      setTime(5000);
      setJobApiRuning(false);
    });
};

const stopDataLinkageStatus = (record: any, tableData, setTableData, setTime, setJobApiRuning, message, t) => {
  setTime(null);
  setJobApiRuning(true);
  APIList.stopDataLinkage().post({
    id: record.jobId,
    workflowName: record.workflowName
  })
    .then((data: any) => {
      setTableData(cloneDeep(tableData.map(item => {
        const optimizes = item.optimize;
        optimizes.forEach((optimize) => {
          if (record.optimizeId === optimize.optimizeId) {
            optimize.dataLinkageStatus = undefined;
          }
        });
        return item;
      })));
      message.success(t('aipskd.jobList.stopMasterMessage'))
    })
    .catch((err) => {
      console.error(err);
    }).finally(() => {
      setTime(5000);
      setJobApiRuning(false);
    });
};

const originalDisabled = (record) => (record.optimizeStatus === 1 || (record.originalStatus === 3 && record.originalJobReady === 1));
const optimizeDisabled = (record) => (record.originalStatus !== 3 || (record.optimizeStatus === 3 && record.optimizeJobReady === 1) || record.originalJobReady === 0);
const dataLinkageDisabled = (record) => (record.dataLinkage === 0) || (record.dataLinkage === 2);
const updateDeleteDisabled = (record) => {
  const optimizes = record.optimize;
  return optimizes.some(item =>
    [0, 1].includes(item.optimizeStatus) || [0, 1].includes(item.originalStatus) || [0, 1].includes(item.dataLinkageStatus));
};

const PROJECT_PLAN_TABLE_COLUMNS = ({
  t,
  showModal,
  showDeleteModal,
  showInputTable,
  tableData,
  setTableData,
  customProperty,
  navigate,
  message
}) => [
    {
      title: t('aipskd.plan.name'),
      dataIndex: 'planName',
      key: 'planName',
      width: 300,
      render: (text, record, index) => renderPlanLink(text, record, index),
      sorter: (a, b) => (a.planName ? a.planName.localeCompare(b.planName) : -1),
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: t('aipskd.plan.stage'),
      dataIndex: 'stage',
      key: 'stage',
      width: 150,
      render,
      sorter: (a, b) => (a.stage ? a.stage.localeCompare(b.stage) : -1),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('aipskd.plan.optimization'),
      dataIndex: 'optimizeName',
      key: 'optimizeName',
      width: 120,
      render: (text, record, index) => renderOptimizeLink(text, record, index, navigate),
      sorter: (a, b) =>
        a.phaseName ? a.phaseName.localeCompare(b.phaseName) : -1,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('aipskd.plan.optimizationMasterOption'),
      dataIndex: 'optimizationName',
      key: 'optimizationName',
      width: 220,
      render: (text, record, index) =>
        record.optimizeId
          ? text || t('aipskd.plan.optimizationUnselected')
          : render(text, record, index),
      //  renderMaster,
      sorter: (a, b) => {
        const aVal = a.optimizationName ?? '';
        const bVal = b.optimizationName ?? '';
        return aVal.localeCompare(bVal);
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('aipskd.plan.dataSnapshot'),
      dataIndex: 'dataCrossSection',
      key: 'dataCrossSection',
      width: 150,
      render: (text, record, index) => record.optimizeId ? text : '-',
      sorter: (a, b) =>
        a.dataCrossSection
          ? a.dataCrossSection.localeCompare(b.dataCrossSection)
          : -1,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('aipskd.plan.optStartDate'),
      dataIndex: 'optimizeStartDate',
      key: 'optimizeStartDate',
      width: 150,
      render: (text, record, index) => record.optimizeId
        ? renderDate(text, record, index) : '-',
      sorter: (a, b) => dayjs(a.optimizeStartDate, 'YYYY-MM-DD').valueOf()
        - dayjs(b.optimizeStartDate, 'YYYY-MM-DD').valueOf(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('aipskd.plan.optEndDate'),
      dataIndex: 'optimizeEndDate',
      key: 'optimizeEndDate',
      width: 150,
      render: (text, record, index) => record.optimizeId
        ? renderDate(text, record, index) : '-',
      sorter: (a, b) => dayjs(a.optimizeEndDate, 'YYYY-MM-DD').valueOf()
        - dayjs(b.optimizeEndDate, 'YYYY-MM-DD').valueOf(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('aipskd.plan.createdDate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (text, record, index) => record.optimizeId ? '-' : renderDate(text, record, index),
      sorter: (a, b) => dayjs(a.createdAt, 'YYYY-MM-DD').valueOf()
        - dayjs(b.createdAt, 'YYYY-MM-DD').valueOf(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('aipskd.plan.updatedDate'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 150,
      render: (text, record, index) => record.optimizeId ? '-' : renderDate(text, record, index),
      sorter: (a, b) => dayjs(a.updatedAt, 'YYYY-MM-DD').valueOf()
        - dayjs(b.updatedAt, 'YYYY-MM-DD').valueOf(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('aipskd.plan.input'),
      dataIndex: 'input',
      key: 'input',
      width: 160,
      className: 'text-center',
      render: (text, record, index) =>
        record.optimizeId ? (
          <>
            <Tooltip title={t('aipskd.plan.scheduleNetwork')}>
              <CalendarOutlined
                className={record.originalJobReady === 0 && record.optimizeJobReady === 0 ? 'icon-disabled' : ''}
                onClick={() => {
                  if (record.originalJobReady === 0 && record.optimizeJobReady === 0) {
                    return;
                  }
                  showInputTable(INPUT_TABLE.SCHEDULE_NETWORK, record);
                }}
              />
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title={t('aipskd.plan.strategyMatrixBuffer')}>
              <BulbOutlined
                className={record.originalJobReady === 0 && record.optimizeJobReady === 0 ? 'icon-disabled' : ''}
                onClick={() => {
                  if (record.originalJobReady === 0 && record.optimizeJobReady === 0) {
                    return;
                  }
                  showInputTable(INPUT_TABLE.STRATEGY_MATRIX, record);
                }}
              />
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title={t('aipskd.plan.resourceConstraint')}>
              <HddOutlined
                className={record.originalJobReady === 0 && record.optimizeJobReady === 0 ? 'icon-disabled' : ''}
                onClick={() => {
                  if (record.originalJobReady === 0 && record.optimizeJobReady === 0) {
                    return;
                  }
                  showInputTable(INPUT_TABLE.RESOURCE, record);
                }}
              />
            </Tooltip>
          </>
        ) : (
          <>-</>
        ),
      fixed: 'right',
    },
    {
      title: t('aipskd.plan.action'),
      dataIndex: 'action',
      key: 'action',
      width: 160,
      className: 'text-center',
      render: (text, record, index) => {
        const { planAuth, setTime, setJobApiRuning, } = customProperty;
        return record.optimizeId ? (
          <>
            {planAuth(record.projectId) ? (
              <>
                {/* オリジナル案の生成 */}
                {(() => {
                  if (record.originalStatus <= 0) {
                    return (
                      <Tooltip title={t('aipskd.plan.processing')}>
                        <Spin size="small" />
                      </Tooltip>
                    );
                  }
                  if (record.originalStatus === 1) {
                    return (
                      <Tooltip title={t('aipskd.plan.stopOriginal')}>
                        <Popconfirm
                          placement="topLeft"
                          title={t('aipskd.jobList.stopConfirmMessage')}
                          okText={t('common.okText')}
                          cancelText={t('common.cancelText')}
                          onConfirm={() => {
                            setTableData(cloneDeep(tableData.map(item => {
                              const optimizes = item.optimize;
                              optimizes.forEach((optimize) => {
                                if (record.optimizeId === optimize.optimizeId) {
                                  optimize.originalStatus = -1;
                                }
                              });
                              return item;
                            })));
                            stopOriginalJobStatus(record, tableData, setTableData, setTime, setJobApiRuning, message, t);
                          }}
                        >
                          <StopOutlined />
                        </Popconfirm>
                      </Tooltip>
                    );
                  }
                  if (record.originalJobReady === 1) {
                    return <Tooltip title={t('aipskd.plan.originalFinished')}><CheckCircleOutlined style={{ color: '#73A373' }} /></Tooltip>;
                  }
                  return (
                    <Tooltip title={t('aipskd.plan.createOriginal')}>
                      <DeploymentUnitOutlined
                        className={originalDisabled(record) ? 'icon-disabled' : ''} onClick={() => {
                          if (originalDisabled(record)) {
                            return;
                          }
                          setTableData(cloneDeep(tableData.map(item => {
                            const optimizes = item.optimize;
                            optimizes.forEach((optimize) => {
                              if (record.optimizeId === optimize.optimizeId) {
                                optimize.originalStatus = 0;
                              }
                            });
                            return item;
                          })));
                          createOriginalJob(record, tableData, setTableData, setTime, setJobApiRuning);
                        }}
                      />
                    </Tooltip>
                  );
                })()}
                <Divider type="vertical" />
                {/* 最適化実行 */}
                {(() => {
                  if (record.optimizeStatus <= 0) {
                    return (
                      <Tooltip title={t('aipskd.plan.processing')}>
                        <Spin size="small" />
                      </Tooltip>
                    );
                  }
                  if (record.optimizeStatus === 1) {
                    return (
                      <Tooltip title={t('aipskd.plan.stopOptimization')}>
                        <Popconfirm
                          placement="topLeft"
                          title={t('aipskd.jobList.stopConfirmMessage')}
                          okText={t('common.okText')}
                          cancelText={t('common.cancelText')}
                          onConfirm={() => {
                            setTableData(cloneDeep(tableData.map(item => {
                              const optimizes = item.optimize;
                              optimizes.forEach((optimize) => {
                                if (record.optimizeId === optimize.optimizeId) {
                                  optimize.optimizeStatus = -1;
                                }
                              });
                              return item;
                            })));
                            stopOptimizationJobStatus(record, tableData, setTableData, setTime, setJobApiRuning, message, t);
                          }}
                        >
                          <StopOutlined />
                        </Popconfirm>
                      </Tooltip>
                    );
                  }
                  if (record.optimizeJobReady === 1) {
                    return <Tooltip title={t('aipskd.plan.optimizationFinished')}><CheckCircleOutlined style={{ color: '#73A373' }} /></Tooltip>;
                  }
                  return (
                    <Tooltip title={t('aipskd.plan.runOptimization')}>
                      <PlayCircleOutlined
                        className={optimizeDisabled(record) ? 'icon-disabled' : ''}
                        onClick={() => {
                          if (optimizeDisabled(record)) {
                            return;
                          }
                          setTableData(cloneDeep(tableData.map(item => {
                            const optimizes = item.optimize;
                            optimizes.forEach((optimize) => {
                              if (record.optimizeId === optimize.optimizeId) {
                                optimize.optimizeStatus = 0;
                              }
                            });
                            return item;
                          })));
                          createOptimizationJob(record, tableData, setTableData, setTime, setJobApiRuning);
                        }}
                      />
                    </Tooltip>
                  );
                })()}
                <Divider type="vertical" />
                {(() => {
                  if (record.dataLinkageStatus <= 0) {
                    return (
                      <Tooltip title={t('aipskd.plan.processing')}>
                        <Spin size="small" />
                      </Tooltip>
                    );
                  }
                  if (record.dataLinkageStatus === 1) {
                    return (
                      <Tooltip title={t('aipskd.plan.stopDataLinkage')}>
                        <Popconfirm
                          placement="topLeft"
                          title={t('aipskd.jobList.stopConfirmMessage')}
                          okText={t('common.okText')}
                          cancelText={t('common.cancelText')}
                          onConfirm={() => {
                            setTableData(cloneDeep(tableData.map(item => {
                              const optimizes = item.optimize;
                              optimizes.forEach((optimize) => {
                                if (record.optimizeId === optimize.optimizeId) {
                                  optimize.dataLinkageStatus = -1;
                                }
                              });
                              return item;
                            })));
                            stopDataLinkageStatus(record, tableData, setTableData, setTime, setJobApiRuning, message, t);
                          }}
                        >
                          <StopOutlined />
                        </Popconfirm>
                      </Tooltip>
                    );
                  }
                  if (record.dataLinkage === 2) {
                    return <Tooltip title={t('aipskd.plan.dataLinkageFinished')}><CheckCircleOutlined style={{ color: '#73A373' }} /></Tooltip>;
                  }
                  return (
                    <Tooltip title={(() => {
                      switch (record.dataLinkage) {
                        case 0:
                          return (
                            <>
                              <span>{t('aipskd.plan.dataLinkage')}</span>
                              <br />
                              <span>（{t('aipskd.plan.masterUnselected')}）</span>
                            </>
                          );
                        default:
                          return t('aipskd.plan.dataLinkage');
                      }
                    })()}
                    >
                      <LinkOutlined
                        className={dataLinkageDisabled(record) ? 'icon-disabled' : ''}
                        onClick={() => {
                          if (dataLinkageDisabled(record)) {
                            return;
                          }
                          setTableData(cloneDeep(tableData.map(item => {
                            const optimizes = item.optimize;
                            optimizes.forEach((optimize) => {
                              if (record.optimizeId === optimize.optimizeId) {
                                optimize.dataLinkageStatus = 0;
                              }
                            });
                            return item;
                          })));
                          createDataLinkage(record, tableData, setTableData, setTime, setJobApiRuning);
                        }}
                      />
                    </Tooltip>
                  );
                })()}
              </>
            )
              : <></>}

          </>
        ) : (
          <>
            {planAuth(record.projectId) ? (
              <>
                <Tooltip title={t('common.update')}>
                  <EditOutlined
                    className={updateDeleteDisabled(record) ? 'icon-disabled' : ''}
                    onClick={() => {
                      if (updateDeleteDisabled(record)) {
                        return;
                      }
                      showModal(OPERATION.MODIFY, record);
                    }}
                  />
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip title={t('common.delete')}>
                  <DeleteOutlined
                    className={updateDeleteDisabled(record) ? 'icon-disabled' : ''}
                    onClick={() => {
                      if (updateDeleteDisabled(record)) {
                        return;
                      }
                      showDeleteModal(record);
                    }}
                  />
                </Tooltip>
              </>
            ) : <></>}
          </>
        );
      },
      fixed: 'right',
    },
  ];
