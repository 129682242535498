// chart title: Buffer Suitability Evaluation
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { ContentChart, ECHART_EVENT } from '@meteor/frontend-core';
import { getSizeByMap } from '../../utils/commonUtil';
import { AppContext } from '../../contexts/AppContext';

type ChartProps = {
  data: any;
  sizeMode?: string;
  height?: number;
  loading?: boolean;
  axis?: any;
};

const BufferSuitabilityEvaluationChart: React.FC<ChartProps> = (props) => {
  const { sizeMode = 'small', data, height, loading, axis } = props;
  const { color, setGlobalEvent, echartTheme } = useContext(AppContext);
  const { t } = useTranslation();

  const sizeMap = {
    small: {
      title: 18,
      legend: 10,
      yAxis: 12,
      xAxis: 12,
      gridBottom: 40,
    },
    big: {
      title: 38,
      legend: 18,
      yAxis: 28,
      xAxis: 28,
      gridBottom: 30,
    },
  };

  const getHeight = () => (sizeMode === 'big' ? 180 + (height - 322) - 10 : 180);

  const [options, setOptions] = useState<any>({
    title: {
      value: 'Buffer Suitability Evaluation',
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    contentList: [],
    tooltips: ['zoomIn', 'reset'],
    showSeriesLabel: false,
    mouseWheelZoomLock: false,
    chartOptions: {
      animation: false,
      tooltip: {
        show: true,
        formatter: (params) => `<div>
          <span style="font-weight: bolder;">WP ID: ${params.data[2]}</span><br/>
          ${params.data[5] ? `<span>WP 名: ${params.data[5]}</span><br/>` : ''}
          <span>Buffer Length: ${params.data[0]}</span><br/>
          <span>MH Delta: ${params.data[1]}</span><br/>
        </div>`,
      },
      grid: {
        left: 60,
        right: 30,
        bottom: 40,
        top: '10%',
      },
      xAxis: {
        type: 'value',
        name: 'Buffer Length',
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
        nameLocation: 'center',
        nameGap: 35,
        min: axis?.xAxisMinValue,
        max: axis?.xAxisMaxValue,
      },
      yAxis: {
        type: 'value',
        name: 'MH Delta',
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
        },
        nameLocation: 'center',
        nameGap: 25,
        min: axis?.yAxisMinValue,
        max: axis?.yAxisMaxValue,
      },
      dataZoom: [
        {
          show: false,
          start: 0,
          end: 100,
          xAxisIndex: [0, 1],
          height: '6px',
          showDataShadow: false,
          filterMode: 'none',
        },
        {
          type: 'inside',
          start: 0,
          end: 100,
          xAxisIndex: [0, 1],
          height: '6px',
          filterMode: 'none',
        },
        {
          show: false,
          start: 0,
          end: 100,
          yAxisIndex: [0, 1],
          height: '6px',
          showDataShadow: false,
          filterMode: 'none',
        },
        {
          type: 'inside',
          start: 0,
          end: 100,
          yAxisIndex: [0, 1],
          height: '6px',
          filterMode: 'none',
        },
      ],
      series: [
        {
          symbolSize: 10,
          label: {
            position: 'top',
            formatter: (params) => `${params.data[2]}`,
          },
          data: [],
          type: 'scatter',
        },
      ],
    },
  });

  const initData = () => {
    const chartData = [];
    options.chartOptions.yAxis = {
      type: 'value',
      name: 'MH Delta',
      axisLabel: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
      },
      nameLocation: 'center',
      nameGap: 40,
      min: axis.yAxisMinValue,
      max: axis.yAxisMaxValue,
      interval: (axis.yAxisMaxValue - axis.yAxisMinValue) / 4,
    };
    options.chartOptions.xAxis = {
      type: 'value',
      name: 'Buffer Length',
      axisLabel: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
      },
      nameLocation: 'center',
      nameGap: 25,
      min: axis.xAxisMinValue,
      max: axis.xAxisMaxValue,
      interval: (axis.xAxisMaxValue - axis.xAxisMinValue) / 4,
    };
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element.bufferLength) {
          chartData.push([element.bufferLength, element.mhDelta, element.bufferId, element.id, element.optimizationId, element.wpName]);
        }
      }

      options.chartOptions.series[0].data = cloneDeep(chartData);
      setOptions(cloneDeep(options));
    } else {
      options.chartOptions.series[0].data = [];
      setOptions(cloneDeep(options));
    }
  };

  useEffect(() => {
    if (axis) {
      initData();
    }
  }, [data, axis]);

  const chartEventHandler = (globalEvent) => {
    // Chart reset 事件
    if (globalEvent && globalEvent.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED) {
      initData();
    } else {
      setGlobalEvent(globalEvent);
    }
  };

  return (
    <>
      <ContentChart
        contentList={options.contentList}
        chartOptions={{ ...options.chartOptions, echartTheme }}
        showSeriesLabel={options.showSeriesLabel}
        mouseWheelZoomLock={options.mouseWheelZoomLock}
        title={options.title}
        height={getHeight()}
        tooltips={options.tooltips}
        loading={loading}
        isBank={!data || data.length === 0}
        onChartEvent={chartEventHandler}
      />
    </>
  );
};

export default BufferSuitabilityEvaluationChart;
