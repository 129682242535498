
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { isNil } from 'lodash';

const sortRole = (dataIndex: string, dataType: string, multiple = 999) => {
  let sortData = null;
  switch (dataType) {
    case 'string':
      sortData = {
        // multiple: multiple,
        compare: (a, b) => {
          const aVal = a[dataIndex] ?? '';
          const bVal = b[dataIndex] ?? '';
          return aVal.localeCompare(bVal);
        },
      };
      break;
    case 'date':
      sortData = {
        // multiple: multiple,
        compare: (a, b) => (a[dataIndex]
          ? dayjs(a[dataIndex], 'YYYY-MM-DD').valueOf()
          - dayjs(b[dataIndex], 'YYYY-MM-DD').valueOf()
          : -1),
      };
      break;
    case 'number':
      sortData = {
        // multiple: multiple,
        compare: (a, b) => {
          if (a[dataIndex] === null && b[dataIndex] !== null) {
            return -1;
          }
          if (b[dataIndex] === null && a[dataIndex] !== null) {
            return 1;
          }
          if (a[dataIndex] === null && b[dataIndex] === null) {
            return 0;
          }
          return a[dataIndex] - b[dataIndex];
        },
      };
      break;
    default:
      break;
  }
  return sortData;
};

const render = (text) => !isNil(text) ? text : '-';

export const WORK_ACTIVITY_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Work Activity ID',
    dataIndex: 'workActivityId',
    key: 'workActivityId',
    width: 160,
    sorter: sortRole('workActivityId', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'FWBS Code',
    dataIndex: 'fwbsCode',
    key: 'fwbsCode',
    width: 140,
    sorter: sortRole('fwbsCode', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Activity Type',
    dataIndex: 'workActivityType',
    key: 'workActivityType',
    width: 180,
    sorter: sortRole('workActivityType', 'string', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Activity Name',
    dataIndex: 'workActivityName',
    key: 'workActivityName',
    width: 200,
    sorter: sortRole('workActivityName', 'string', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Activity Initial Baseline Start Date',
    dataIndex: 'initialBaselineStartDate',
    key: 'initialBaselineStartDate',
    width: 330,
    sorter: sortRole('initialBaselineStartDate', 'date', 5),
    sortDirections: ['descend', 'ascend'],
    render: (text) => (text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-')
  },
  {
    title: 'Work Activity Initial Baseline Finish Date',
    dataIndex: 'initialBaselineFinishDate',
    key: 'initialBaselineFinishDate',
    width: 330,
    sorter: sortRole('initialBaselineFinishDate', 'date', 6),
    sortDirections: ['descend', 'ascend'],
    render: (text) => (text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-')
  },
  {
    title: 'Initial Plan Duration',
    dataIndex: 'initialPlanDuration',
    key: 'initialPlanDuration',
    width: 230,
    className: 'text-right',
    sorter: sortRole('initialPlanDuration', 'number', 7),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Activity Current Baseline Start Date',
    dataIndex: 'currentBaselineStartDate',
    key: 'currentBaselineStartDate',
    width: 330,
    sorter: sortRole('currentBaselineStartDate', 'date', 8),
    sortDirections: ['descend', 'ascend'],
    render: (text) => {
      console.log(dayjs(text).format('YYYY-MM-DD HH:mm:ss'));
      return (text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-');
    }
  },
  {
    title: 'Work Activity Current Baseline Finish Date',
    dataIndex: 'currentBaselineFinishDate',
    key: 'currentBaselineFinishDate',
    width: 350,
    sorter: sortRole('currentBaselineFinishDate', 'date', 9),
    sortDirections: ['descend', 'ascend'],
    render: (text) => (text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-')
  },
  {
    title: 'Plan Duration',
    dataIndex: 'planDuration',
    key: 'planDuration',
    width: 180,
    className: 'text-right',
    sorter: sortRole('planDuration', 'number', 10),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Activity Current Start Date',
    dataIndex: 'currentStartDate',
    key: 'currentStartDate',
    width: 280,
    sorter: sortRole('currentStartDate', 'date', 11),
    sortDirections: ['descend', 'ascend'],
    render: (text) => (text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-')
  },
  {
    title: 'Work Activity Current Finish Date',
    dataIndex: 'currentFinishDate',
    key: 'currentFinishDate',
    width: 280,
    sorter: sortRole('currentFinishDate', 'date', 12),
    sortDirections: ['descend', 'ascend'],
    render: (text) => (text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-')
  },
  {
    title: 'Primary Constraint',
    dataIndex: 'primaryConstraint',
    key: 'primaryConstraint',
    width: 180,
    sorter: sortRole('primaryConstraint', 'string', 13),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Primary Constraint Date',
    dataIndex: 'primaryConstraintDate',
    key: 'primaryConstraintDate',
    width: 230,
    sorter: sortRole('primaryConstraintDate', 'date', 14),
    sortDirections: ['descend', 'ascend'],
    render: (text) => (text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-')
  },
  {
    title: 'Forecast Duration',
    dataIndex: 'forecastDuration',
    key: 'forecastDuration',
    width: 180,
    className: 'text-right',
    sorter: sortRole('forecastDuration', 'number', 15),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Activity Strategy Matrix Level',
    dataIndex: 'strategyMatrixLevel',
    key: 'strategyMatrixLevel',
    width: 300,
    className: 'text-right',
    sorter: sortRole('strategyMatrixLevel', 'number', 16),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Capacity Flag',
    dataIndex: 'capacityFlag',
    key: 'capacityFlag',
    width: 140,
    className: 'text-right',
    sorter: sortRole('capacityFlag', 'number', 17),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Milestone Buffer Flag',
    dataIndex: 'milestoneBufferFlag',
    key: 'milestoneBufferFlag',
    width: 210,
    className: 'text-right',
    sorter: sortRole('milestoneBufferFlag', 'number', 18),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Package Code',
    dataIndex: 'workPackageCode',
    key: 'workPackageCode',
    width: 200,
    sorter: sortRole('workPackageCode', 'string', 19),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Process',
    dataIndex: 'process',
    key: 'process',
    width: 110,
    sorter: sortRole('process', 'string', 20),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Priority Flag',
    dataIndex: 'priorityFlag',
    key: 'priorityFlag',
    width: 160,
    className: 'text-right',
    sorter: sortRole('priorityFlag', 'number', 21),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Area Code',
    dataIndex: 'areaCode',
    key: 'areaCode',
    width: 130,
    sorter: sortRole('areaCode', 'string', 22),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Discipline Code',
    dataIndex: 'disciplineCode',
    key: 'disciplineCode',
    width: 160,
    sorter: sortRole('disciplineCode', 'string', 23),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'FWBS Code Level',
    dataIndex: 'fwbsCodeLevel',
    key: 'fwbsCodeLevel',
    width: 180,
    sorter: sortRole('fwbsCodeLevel', 'string', 24),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Activity Status Code',
    dataIndex: 'statusCode',
    key: 'statusCode',
    width: 230,
    sorter: sortRole('statusCode', 'string', 25),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Activity Physical Percent Complete',
    dataIndex: 'physicalPercentComplete',
    key: 'physicalPercentComplete',
    width: 330,
    className: 'text-right',
    sorter: sortRole('physicalPercentComplete', 'number', 26),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Activity Actual Start Date',
    dataIndex: 'actualStartDate',
    key: 'actualStartDate',
    width: 260,
    sorter: sortRole('actualStartDate', 'date', 27),
    sortDirections: ['descend', 'ascend'],
    render: (text) => (text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-')
  },
  {

    title: 'Work Activity Actual Finish Date',
    dataIndex: 'actualFinishDate',
    key: 'actualFinishDate',
    width: 270,
    sorter: sortRole('actualFinishDate', 'date', 28),
    sortDirections: ['descend', 'ascend'],
    render: (text) => (text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-')
  },
  {
    title: 'Actual Duration',
    dataIndex: 'actualDuration',
    key: 'actualDuration',
    width: 160,
    className: 'text-right',
    sorter: sortRole('actualDuration', 'number', 29),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Resource Type Code',
    dataIndex: 'resourceTypeCode',
    key: 'resourceTypeCode',
    width: 190,
    sorter: sortRole('resourceTypeCode', 'string', 30),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'ROS Activity Flag',
    dataIndex: 'rosActivityFlag',
    key: 'rosActivityFlag',
    width: 180,
    className: 'text-right',
    sorter: sortRole('rosActivityFlag', 'number', 31),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Calendar Code',
    dataIndex: 'calendarCode',
    key: 'calendarCode',
    width: 170,
    sorter: sortRole('calendarCode', 'string', 32),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const WORK_PACKAGE_RELATION_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Schedule Predecessor Work Package Code',
    dataIndex: 'predecessorCode',
    key: 'predecessorCode',
    sorter: sortRole('predecessorCode', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Package Code',
    dataIndex: 'successorCode',
    key: 'successorCode',
    sorter: sortRole('successorCode', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Activity Relation Type',
    dataIndex: 'activityRelationType',
    key: 'activityRelationType',
    sorter: sortRole('activityRelationType', 'string', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Schedule Lag',
    dataIndex: 'scheduleLag',
    key: 'scheduleLag',
    className: 'text-right',
    sorter: sortRole('scheduleLag', 'number', 4),
    sortDirections: ['descend', 'ascend'],
    render
  }
];

export const CALENDAR_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: sortRole('date', 'date', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Day of Week',
    dataIndex: 'dayOfWeek',
    key: 'dayOfWeek',
    className: 'text-right',
    sorter: sortRole('dayOfWeek', 'number', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Working Day',
    dataIndex: 'workingDay',
    key: 'workingDay',
    className: 'text-right',
    sorter: sortRole('workingDay', 'number', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Working Start Time',
    dataIndex: 'workingStartTime',
    key: 'workingStartTime',
    sorter: sortRole('workingStartTime', 'string', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Working End Time',
    dataIndex: 'workingEndTime',
    key: 'workingEndTime',
    sorter: sortRole('workingEndTime', 'string', 5),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Calendar Name',
    dataIndex: 'calendarName',
    key: 'calendarName',
    sorter: sortRole('calendarName', 'string', 6),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Calendar Code',
    dataIndex: 'calendarCode',
    key: 'calendarCode',
    sorter: sortRole('calendarCode', 'string', 7),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const WORK_PACKAGE_BOQ_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Work Activity ID',
    dataIndex: 'workActivityId',
    key: 'workActivityId',
    width: 170,
    sorter: sortRole('workActivityId', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'BoQ Code',
    dataIndex: 'boqCode',
    key: 'boqCode',
    width: 120,
    sorter: sortRole('boqCode', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'BoQ',
    dataIndex: 'boq',
    key: 'boq',
    width: 70,
    className: 'text-right',
    sorter: sortRole('boq', 'number', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Unit of Measure BoQ',
    dataIndex: 'unitOfMeasureBoq',
    key: 'unitOfMeasureBoq',
    width: 200,
    sorter: sortRole('unitOfMeasureBoq', 'string', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Plan KPI 2 DX Applied',
    dataIndex: 'dxApplied',
    key: 'dxApplied',
    width: 230,
    className: 'text-right',
    sorter: sortRole('dxApplied', 'number', 5),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Plan KPI 2 DX Not Applied',
    dataIndex: 'dxNotApplied',
    key: 'dxNotApplied',
    width: 250,
    className: 'text-right',
    sorter: sortRole('dxNotApplied', 'number', 6),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Unit of KPI 2',
    dataIndex: 'unitOfKpi2',
    key: 'unitOfKpi2',
    width: 150,
    sorter: sortRole('unitOfKpi2', 'string', 7),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Required MH',
    dataIndex: 'requiredMh',
    key: 'requiredMh',
    width: 150,
    className: 'text-right',
    sorter: sortRole('requiredMh', 'number', 8),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Resource Type Code',
    dataIndex: 'resourceTypeCode',
    key: 'resourceTypeCode',
    width: 200,
    sorter: sortRole('resourceTypeCode', 'string', 9),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Optimization Target Flag',
    dataIndex: 'optimizationTargetFlag',
    key: 'optimizationTargetFlag',
    width: 250,
    sorter: sortRole('optimizationTargetFlag', 'string', 10),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const WORK_ACTIVITY_RESOURCE_TYPE_USAGE_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Work Activity ID',
    dataIndex: 'workActivityId',
    key: 'workActivityId',
    width: 180,
    sorter: sortRole('workActivityId', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'BoQ Code',
    dataIndex: 'boqCode',
    key: 'boqCode',
    width: 120,
    sorter: sortRole('boqCode', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Resource Type Code',
    dataIndex: 'resourceTypeCode',
    key: 'resourceTypeCode',
    width: 220,
    sorter: sortRole('resourceTypeCode', 'string', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Planned Resource Type Quantity',
    dataIndex: 'plannedQuantity',
    key: 'plannedQuantity',
    width: 300,
    className: 'text-right',
    sorter: sortRole('plannedQuantity', 'number', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Unit of Measure Overall Planned Resource Type Quantity',
    dataIndex: 'plannedQuantityUnit',
    key: 'plannedQuantityUnit',
    width: 450,
    sorter: sortRole('plannedQuantityUnit', 'string', 5),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Actual Resource Type Quantity',
    dataIndex: 'actualQuantity',
    key: 'actualQuantity',
    width: 260,
    className: 'text-right',
    sorter: sortRole('actualQuantity', 'number', 6),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Unit of Measure Overall Actual Resource Type Quantity',
    dataIndex: 'actualQuantityUnit',
    key: 'actualQuantityUnit',
    width: 450,
    sorter: sortRole('actualQuantityUnit', 'string', 7),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Planned Resource Type Cost',
    dataIndex: 'plannedCost',
    key: 'plannedCost',
    width: 250,
    className: 'text-right',
    sorter: sortRole('plannedCost', 'number', 8),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Unit of Measure Overall Planned Resource Type Cost',
    dataIndex: 'plannedCostUnit',
    key: 'plannedCostUnit',
    width: 420,
    sorter: sortRole('plannedCostUnit', 'string', 9),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Actual Resource Type Cost',
    dataIndex: 'actualCost',
    key: 'actualCost',
    width: 250,
    className: 'text-right',
    sorter: sortRole('actualCost', 'number', 10),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Unit of Measure Overall Actual Resource Type Cost',
    dataIndex: 'actualCostUnit',
    key: 'actualCostUnit',
    width: 400,
    sorter: sortRole('actualCostUnit', 'string', 11),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const RESOURCE_LOADING_CONSTRAINT_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Parent Resource Type Code',
    dataIndex: 'parentResourceTypeCode',
    key: 'parentResourceTypeCode',
    width: 250,
    sorter: sortRole('parentResourceTypeCode', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Resource Type Code',
    dataIndex: 'resourceTypeCode',
    key: 'resourceTypeCode',
    width: 200,
    sorter: sortRole('resourceTypeCode', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Constraint Discipline MP Upper',
    dataIndex: 'disciplineMpUpper',
    key: 'disciplineMpUpper',
    width: 280,
    className: 'text-right',
    sorter: sortRole('disciplineMpUpper', 'number', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Discipline Weekly Rate of Fluctuation',
    dataIndex: 'disciplineWeeklyRate',
    key: 'disciplineWeeklyRate',
    width: 370,
    className: 'text-right',
    sorter: sortRole('disciplineWeeklyRate', 'number', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Discipline Monthly Rate of Fluctuation',
    dataIndex: 'disciplineMonthlyRate',
    key: 'disciplineMonthlyRate',
    width: 370,
    className: 'text-right',
    sorter: sortRole('disciplineMonthlyRate', 'number', 5),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Constraint Role MP Upper',
    dataIndex: 'roleMpUpper',
    key: 'roleMpUpper',
    width: 300,
    className: 'text-right',
    sorter: sortRole('roleMpUpper', 'number', 6),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Constraint Max Number of Team',
    dataIndex: 'maxNumberOfTeam',
    key: 'maxNumberOfTeam',
    width: 300,
    className: 'text-right',
    sorter: sortRole('maxNumberOfTeam', 'number', 7),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Workers per Team',
    dataIndex: 'workersPerTeam',
    key: 'workersPerTeam',
    width: 180,
    className: 'text-right',
    sorter: sortRole('workersPerTeam', 'number', 8),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Target Constraint Daily MH Upper',
    dataIndex: 'targetDailyMhUpper',
    key: 'targetDailyMhUpper',
    width: 300,
    className: 'text-right',
    sorter: sortRole('targetDailyMhUpper', 'number', 9),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Acceptable Constraint Daily MH Upper',
    dataIndex: 'acceptableDailyMhUpper',
    key: 'acceptableDailyMhUpper',
    width: 380,
    className: 'text-right',
    sorter: sortRole('acceptableDailyMhUpper', 'number', 10),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Target Constraint Monthly MH Upper',
    dataIndex: 'targetMonthlyMhUpper',
    key: 'targetMonthlyMhUpper',
    width: 350,
    className: 'text-right',
    sorter: sortRole('targetMonthlyMhUpper', 'number', 11),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Acceptable Constraint Monthly MH Upper',
    dataIndex: 'acceptableMonthlyMhUpper',
    key: 'acceptableMonthlyMhUpper',
    width: 380,
    className: 'text-right',
    sorter: sortRole('acceptableMonthlyMhUpper', 'number', 12),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const AREA_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Parent Area',
    dataIndex: 'parentArea',
    key: 'parentArea',
    sorter: sortRole('parentArea', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Area',
    dataIndex: 'area',
    key: 'area',
    sorter: sortRole('area', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Constraint Area MP Upper',
    dataIndex: 'areaMpUpper',
    key: 'areaMpUpper',
    className: 'text-right',
    sorter: sortRole('areaMpUpper', 'string', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Constraint Area MP Lower',
    dataIndex: 'areaMpLower',
    key: 'areaMpLower',
    className: 'text-right',
    sorter: sortRole('areaMpLower', 'string', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const MINIMUM_DURATION_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'FWBS Code',
    dataIndex: 'fwbsCode',
    key: 'fwbsCode',
    sorter: sortRole('fwbsCode', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Minimum Duration Category',
    dataIndex: 'minimumDurationCategory',
    key: 'minimumDurationCategory',
    sorter: sortRole('minimumDurationCategory', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Minimum Duration',
    dataIndex: 'minimumDuration',
    key: 'minimumDuration',
    className: 'text-right',
    sorter: sortRole('minimumDuration', 'number', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Parent FWBS',
    dataIndex: 'parentFwbs',
    key: 'parentFwbs',
    sorter: sortRole('parentFwbs', 'string', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const FWBS_RESOURCE_LOADING_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'FWBS Code',
    dataIndex: 'fwbsCode',
    key: 'fwbsCode',
    sorter: sortRole('fwbsCode', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Resource Type Code',
    dataIndex: 'resourceTypeCode',
    key: 'resourceTypeCode',
    sorter: sortRole('resourceTypeCode', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'LAE MH Ratio',
    dataIndex: 'laeMhRatio',
    key: 'laeMhRatio',
    className: 'text-right',
    sorter: sortRole('laeMhRatio', 'number', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Area Lead MH Ratio',
    dataIndex: 'areaLeadMhRatio',
    key: 'areaLeadMhRatio',
    className: 'text-right',
    sorter: sortRole('areaLeadMhRatio', 'number', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'AE MH Ratio',
    dataIndex: 'aeMhRatio',
    key: 'aeMhRatio',
    className: 'text-right',
    sorter: sortRole('aeMhRatio', 'number', 5),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const RESOURCE_DAILY_LOADING_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: 100,
    sorter: sortRole('date', 'date', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Activity ID',
    dataIndex: 'workActivityId',
    key: 'workActivityId',
    sorter: sortRole('workActivityId', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Discipline Code',
    dataIndex: 'disciplineCode',
    key: 'disciplineCode',
    sorter: sortRole('disciplineCode', 'string', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Role Code',
    dataIndex: 'roleCode',
    key: 'roleCode',
    sorter: sortRole('roleCode', 'string', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Actual Resource Type Quantity',
    dataIndex: 'actualQuantity',
    key: 'actualQuantity',
    width: 260,
    className: 'text-right',
    sorter: sortRole('actualQuantity', 'number', 5),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Unit of Measure Overall Actual Resource Type Quantity',
    dataIndex: 'actualQuantityUnit',
    key: 'actualQuantityUnit',
    width: 430,
    sorter: sortRole('actualQuantityUnit', 'string', 6),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const RESOURCE_TYPE_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Resource Type Code',
    dataIndex: 'resourceTypeCode',
    key: 'resourceTypeCode',
    sorter: sortRole('resourceTypeCode', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Discipline Code',
    dataIndex: 'disciplineCode',
    key: 'disciplineCode',
    sorter: sortRole('disciplineCode', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const MANHOUR_UNIT_COST_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Resource Type Code',
    dataIndex: 'resourceTypeCode',
    key: 'resourceTypeCode',
    sorter: sortRole('resourceTypeCode', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Company Code',
    dataIndex: 'companyCode',
    key: 'companyCode',
    sorter: sortRole('companyCode', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'MH Unit Cost',
    dataIndex: 'mhUnitCost',
    key: 'mhUnitCost',
    className: 'text-right',
    sorter: sortRole('mhUnitCost', 'number', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'MH Unit Cost (uom)',
    dataIndex: 'mhUnitCostUom',
    key: 'mhUnitCostUom',
    sorter: sortRole('mhUnitCostUom', 'string', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Unit Rate',
    dataIndex: 'unitRate',
    key: 'unitRate',
    className: 'text-right',
    sorter: sortRole('unitRate', 'number', 5),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Unit Rate (uom)',
    dataIndex: 'unitRateUom',
    key: 'unitRateUom',
    width: 300,
    sorter: sortRole('unitRateUom', 'string', 6),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const CONSTRUCTION_UNIT_COST_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Work Activity ID',
    dataIndex: 'workActivityId',
    key: 'workActivityId',
    sorter: sortRole('workActivityId', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'BoQ Code',
    dataIndex: 'boqCode',
    key: 'boqCode',
    sorter: sortRole('boqCode', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Company Code',
    dataIndex: 'companyCode',
    key: 'companyCode',
    sorter: sortRole('companyCode', 'string', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Plan Unit Rate',
    dataIndex: 'planUnitRate',
    key: 'planUnitRate',
    className: 'text-right',
    sorter: sortRole('planUnitRate', 'number', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Plan Unit Rate (uom)',
    dataIndex: 'planUnitRateUom',
    key: 'planUnitRateUom',
    sorter: sortRole('planUnitRateUom', 'string', 5),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Actual Unit Rate',
    dataIndex: 'actualUnitRate',
    key: 'actualUnitRate',
    className: 'text-right',
    sorter: sortRole('actualUnitRate', 'number', 6),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Actual Unit Rate (uom)',
    dataIndex: 'actualUnitRateUom',
    key: 'actualUnitRateUom',
    width: 300,
    sorter: sortRole('actualUnitRateUom', 'string', 7),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const STRATEGY_MATRIX_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Strategy Matrix',
    dataIndex: 'strategyMatrix',
    key: 'strategyMatrix',
    className: 'text-right',
    sorter: sortRole('strategyMatrix', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Buffer Rate Upstream',
    dataIndex: 'bufferRateUpstream',
    key: 'bufferRateUpstream',
    className: 'text-right',
    sorter: sortRole('bufferRateUpstream', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Buffer Rate Downstream',
    dataIndex: 'bufferRateDownstream',
    key: 'bufferRateDownstream',
    className: 'text-right',
    sorter: sortRole('bufferRateDownstream', 'string', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const FHD_BUFFER_RATE_SET_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Work Activity ID',
    dataIndex: 'workActivityId',
    key: 'workActivityId',
    sorter: sortRole('workActivityId', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Activity Name',
    dataIndex: 'workActivityName',
    key: 'workActivityName',
    sorter: sortRole('workActivityName', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Risk Level',
    dataIndex: 'riskLevel',
    key: 'riskLevel',
    className: 'text-right',
    sorter: sortRole('riskLevel', 'number', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Buffer Rate',
    dataIndex: 'bufferRate',
    key: 'bufferRate',
    className: 'text-right',
    sorter: sortRole('bufferRate', 'number', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Rick Category',
    dataIndex: 'riskCategory',
    key: 'riskCategory',
    sorter: sortRole('riskCategory', 'string', 5),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Risk Factor Name',
    dataIndex: 'riskFactorName',
    key: 'riskFactorName',
    sorter: sortRole('riskFactorName', 'string', 6),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Risk Factor',
    dataIndex: 'riskFactor',
    key: 'riskFactor',
    className: 'text-right',
    sorter: sortRole('riskFactor', 'number', 7),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const WORK_PACKAGE_KPI_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Cut Off Date',
    dataIndex: 'cutOffDate',
    key: 'cutOffDate',
    sorter: sortRole('cutOffDate', 'date', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Activity ID',
    dataIndex: 'workActivityId',
    key: 'workActivityId',
    sorter: sortRole('workActivityId', 'string', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'PV',
    dataIndex: 'pv',
    key: 'pv',
    className: 'text-right',
    sorter: sortRole('pv', 'number', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'EV',
    dataIndex: 'ev',
    key: 'ev',
    className: 'text-right',
    sorter: sortRole('ev', 'number', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'AC',
    dataIndex: 'ac',
    key: 'ac',
    className: 'text-right',
    sorter: sortRole('ac', 'number', 5),
    sortDirections: ['descend', 'ascend'],
    render
  },
];

export const WORK_PACKAGE_RISK_LEVEL_TABLE_COLUMNS: ColumnsType<any> = [
  {
    title: 'Work Activity ID',
    dataIndex: 'workActivityId',
    key: 'workActivityId',
    width: 200,
    sorter: sortRole('workActivityId', 'string', 1),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Activity Strategy Matrix Level',
    dataIndex: 'workActivityStrategyMatrix',
    key: 'workActivityStrategyMatrix',
    width: 300,
    className: 'text-right',
    sorter: sortRole('workActivityStrategyMatrix', 'number', 2),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Package Risk Level',
    dataIndex: 'workPackageRisk',
    key: 'workPackageRisk',
    width: 230,
    className: 'text-right',
    sorter: sortRole('workPackageRisk', 'number', 3),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Bottleneck Risk Level',
    dataIndex: 'bottleneckRisk',
    key: 'bottleneckRisk',
    width: 200,
    className: 'text-right',
    sorter: sortRole('bottleneckRisk', 'number', 4),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Fhd Risk Level',
    dataIndex: 'fhdRisk',
    key: 'fhdRisk',
    width: 160,
    className: 'text-right',
    sorter: sortRole('fhdRisk', 'number', 5),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Plant System Start Up Risk Level',
    dataIndex: 'plantSystemStartUpRisk',
    key: 'plantSystemStartUpRisk',
    width: 280,
    className: 'text-right',
    sorter: sortRole('plantSystemStartUpRisk', 'number', 6),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Plant System Engineering Risk Level',
    dataIndex: 'plantSystemEngineeringRisk',
    key: 'plantSystemEngineeringRisk',
    width: 300,
    className: 'text-right',
    sorter: sortRole('plantSystemEngineeringRisk', 'number', 7),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Quality Difficulty Risk Level',
    dataIndex: 'qualityDifficultyRisk',
    key: 'qualityDifficultyRisk',
    width: 260,
    className: 'text-right',
    sorter: sortRole('qualityDifficultyRisk', 'number', 8),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Vendor Qualification Risk Level',
    dataIndex: 'vendorQualificationRisk',
    key: 'vendorQualificationRisk',
    width: 280,
    className: 'text-right',
    sorter: sortRole('vendorQualificationRisk', 'number', 9),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Operation Ratio Risk Level',
    dataIndex: 'operationRatioRisk',
    key: 'operationRatioRisk',
    width: 250,
    className: 'text-right',
    sorter: sortRole('operationRatioRisk', 'number', 10),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Work Package Schedule Risk Level',
    dataIndex: 'workPackageScheduleRisk',
    key: 'workPackageScheduleRisk',
    width: 280,
    className: 'text-right',
    sorter: sortRole('workPackageScheduleRisk', 'number', 11),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Boq Functional Risk Level',
    dataIndex: 'boqFunctionalRisk',
    key: 'boqFunctionalRisk',
    width: 250,
    className: 'text-right',
    sorter: sortRole('boqFunctionalRisk', 'number', 12),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Eng Area Risk Level',
    dataIndex: 'engAreaRisk',
    key: 'engAreaRisk',
    width: 180,
    className: 'text-right',
    sorter: sortRole('engAreaRisk', 'number', 14),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'Const Area Risk Level',
    dataIndex: 'constAreaRisk',
    key: 'constAreaRisk',
    width: 200,
    className: 'text-right',
    sorter: sortRole('constAreaRisk', 'number', 15),
    sortDirections: ['descend', 'ascend'],
    render
  },
  {
    title: 'TIC',
    dataIndex: 'tic',
    key: 'tic',
    width: 100,
    className: 'text-right',
    sorter: sortRole('tic', 'number', 13),
    sortDirections: ['descend', 'ascend'],
    render: value => (value ? Number(value).toLocaleString() : value)
  },
];
