import { Button, Divider, Popconfirm, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { DeleteOutlined, StopOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import { OPERATION } from '../pages/project';
import APIList from '../http/ApiList';

export const PROJECT_TABLE = ({
  t,
  tableData,
  tableTop,
  loading,
  showModal,
  showDeleteModal,
  checkRoleFunctionAuth,
  customProperty,
  setTableData,
  navigate,
  message,
}) => ({
  columns: PROJECT_TABLE_COLUMNS({
    t,
    showModal,
    showDeleteModal,
    checkRoleFunctionAuth,
    customProperty,
    tableData,
    setTableData,
    navigate,
    message,
  }),
  data: tableData,
  size: 'small',
  height: window.innerHeight - tableTop,
  bordered: true,
  loading,
});

const sortOrder = [
  'FS',
  'R&D',
  'License',
  'PDP',
  'PDP Support',
  'FEED',
  'Engineering Service',
  'Engineering',
  'Procurement',
  'Procurement Service',
  'Construction',
  'Construction Management',
  'Commissioning',
  'Commissioning Support',
  'PMC/PMT',
  'O&M',
];

const renderDate = (text, record, index) => <>{dayjs(text).format('YYYY-MM-DD')}</>;

const closeProjectDisabled = (record) => record.hasRunningJob;

const stopCloseProjectStatus = (record: any, tableData, setTableData, setTime, message, t) => {
  setTime(null);
  APIList.stopCloseProject()
    .post({
      id: record.jobId,
      workflowName: record.workflowName,
    })
    .then((data: any) => {
      setTableData(
        cloneDeep(
          tableData.map((item) => {
            if (record.projectId === item.projectId) {
              item.closeProjectStatus = undefined;
            }
            return item;
          })
        )
      );
      message.success(t('aipskd.project.stopCloseMessage'));
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      setTime(5000);
    });
};

const PROJECT_TABLE_COLUMNS = ({
  t,
  showModal,
  showDeleteModal,
  checkRoleFunctionAuth,
  customProperty,
  tableData,
  setTableData,
  navigate,
  message,
}) => [
  {
    title: t('aipskd.project.id'),
    dataIndex: 'projectId',
    key: 'projectId',
    width: 120,
    sorter: (a, b) => a.projectId.localeCompare(b.projectId),
    sortDirections: ['descend', 'ascend'],
    fixed: 'left',
    render: (text: string, record) => (
      <Button
        type="link"
        className="link-ellipsis"
        title={text}
        onClick={() => {
          navigate(`/aipskd/plan?projectId=${record.projectId}`);
        }}
      >
        {text}
      </Button>
    ),
  },
  {
    title: 'TGC',
    dataIndex: 'location',
    key: 'location',
    width: 80,
    sorter: (a, b) => a.location.localeCompare(b.location),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: t('aipskd.project.scopeType'),
    dataIndex: 'scope',
    key: 'scope',
    width: 180,
    sorter: (a, b) => a.scope.localeCompare(b.scope),
    sortDirections: ['descend', 'ascend'],
    render: (text: string, record) =>
      text
        .split(',')
        .sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
        .join(', '),
  },
  {
    title: t('aipskd.project.feedStartDate'),
    dataIndex: 'feedStartDate',
    key: 'feedStartDate',
    width: 100,
    sorter: (a, b) => dayjs(a.feedStartDate, 'YYYY-MM-DD').valueOf() - dayjs(b.feedStartDate, 'YYYY-MM-DD').valueOf(),
    render: (text: string, record) => (text ? <>{dayjs(text).format('YYYY-MM-DD')}</> : <>-</>),
  },
  {
    title: t('aipskd.project.epcStartDate'),
    dataIndex: 'epcStartDate',
    key: 'epcStartDate',
    width: 100,
    sorter: (a, b) => dayjs(a.epcStartDate, 'YYYY-MM-DD').valueOf() - dayjs(b.epcStartDate, 'YYYY-MM-DD').valueOf(),
    render: (text: string, record) => (text ? <>{dayjs(text).format('YYYY-MM-DD')}</> : <>-</>),
  },
  {
    title: t('aipskd.project.endDate'),
    dataIndex: 'endDate',
    key: 'endDate',
    width: 100,
    render: renderDate,
    sorter: (a, b) => dayjs(a.endDate, 'YYYY-MM-DD').valueOf() - dayjs(b.endDate, 'YYYY-MM-DD').valueOf(),
  },
  {
    title: t('aipskd.project.duration'),
    dataIndex: 'duration',
    key: 'duration',
    width: 85,
    sorter: (a, b) => a.duration - b.duration,
    render: (text: string, record) => (text ? <span style={{ float: 'right' }}>{text}</span> : <>-</>),
  },
  {
    title: t('aipskd.project.action'),
    dataIndex: 'operation',
    key: 'operation',
    width: 100,
    className: 'text-center',
    render: (text, record, index) => {
      const { projectEditDeleteAuth, setTime } = customProperty;
      return (
        <>
          {projectEditDeleteAuth(record.projectId) ? (
            <>
              {(() => {
                if (record.closeProjectStatus <= 0) {
                  return (
                    <Tooltip title={t('aipskd.project.processing')}>
                      <Spin size="small" />
                    </Tooltip>
                  );
                }
                if (record.closeProjectStatus === 1) {
                  return (
                    <Tooltip title={t('aipskd.project.stopClose')}>
                      <Popconfirm
                        placement="topLeft"
                        title={t('aipskd.jobList.stopConfirmMessage')}
                        okText={t('common.okText')}
                        cancelText={t('common.cancelText')}
                        onConfirm={() => {
                          setTableData(
                            cloneDeep(
                              tableData.map((item) => {
                                if (record.projectId === item.projectId) {
                                  item.closeProjectStatus = -1;
                                }
                                return item;
                              })
                            )
                          );
                          stopCloseProjectStatus(record, tableData, setTableData, setTime, message, t);
                        }}
                      >
                        <StopOutlined />
                      </Popconfirm>
                    </Tooltip>
                  );
                }
                return (
                  <Tooltip title={t('aipskd.project.close')}>
                    <DeleteOutlined
                      className={closeProjectDisabled(record) ? 'icon-disabled' : ''}
                      onClick={() => {
                        if (closeProjectDisabled(record)) {
                          return;
                        }
                        showDeleteModal(record);
                      }}
                    />
                  </Tooltip>
                );
              })()}
            </>
          ) : (
            <></>
          )}
          {checkRoleFunctionAuth() ? (
            <>
              <Divider type="vertical" />
              <Tooltip placement="top" title="メンバ設定" mouseLeaveDelay={0}>
                <UserSwitchOutlined
                  onClick={() => {
                    showModal(OPERATION.MEMBER, record);
                  }}
                />
              </Tooltip>
            </>
          ) : (
            <></>
          )}
        </>
      );
    },
    fixed: 'right',
  },
];
