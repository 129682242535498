/* eslint-disable default-case */
import {
  useContext, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import {
  InputRef, Input, Space, Button, App
} from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { AppContext } from '../contexts/AppContext';
import useRoleAuth from './useRoleAuth';
import { useNavigate } from 'react-router-dom';

type TableProp = {
  options: (any) => any;
  showModal?: (activeKey: string, index?: number) => void;
  showDeleteModal?: (activeKey: string, index?: number) => void;
  showInputTable?: (activeKey: string, index?: number) => void;
  tableTop: number;
  tableData?: any[];
  setTableData?: (state) => void;
  valueType?: boolean;
  onChange?: (...any) => void;
  kpiName?: string;
  setTableDataHandler?: (data) => void;
  category?: number;
  dxMappings?: any[];
  awpDxChecked?: any;
  deleteDataHandler?: (data) => void;
  customProperty?: any;
  listen?: any[];
};

export const useTable = ({
  options,
  showModal,
  showDeleteModal,
  showInputTable,
  tableTop,
  valueType,
  onChange,
  setTableDataHandler,
  category,
  dxMappings,
  awpDxChecked,
  deleteDataHandler,
  customProperty,
  listen = []
}: TableProp) => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();
  const [loading, setTableLoading] = useState(true);
  const [highlights, setHighlights] = useState<any>();
  const { t } = useTranslation();
  const { locale } = useContext(AppContext);
  const [searchText, setSearchText] = useState('');
  const searchInput = useRef<InputRef>(null);
  const { authentication } = useContext(AppContext);
  const { checkRoleFunctionAuth } = useRoleAuth({ authentication });
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: (param?: FilterConfirmProps) => void
  ) => {
    clearFilters();
    confirm();
    setSearchText('');
  };

  const getColumnSearchProps = (title: string, dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`${t('common.search')} ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('common.search')}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            {t('common.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => record[dataIndex]
      .toString()
      .toLowerCase()
      .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const returnParams = {
    t,
    showModal,
    showDeleteModal,
    showInputTable,
    valueType,
    tableTop,
    tableData,
    loading,
    setTableData: setTableDataHandler || setTableData,
    setTableLoading,
    setSelectedRowKeys,
    selectedRowKeys,
    highlights,
    setHighlights,
    onChange,
    locale: locale.locale,
    getColumnSearchProps,
    searchText,
    category,
    dxMappings,
    awpDxChecked,
    deleteDataHandler,
    checkRoleFunctionAuth,
    customProperty,
    navigate,
    message
  };

  const computeTable = () => [
    options(returnParams),
    setTableData,
    setSelectedRowKeys,
    setTableLoading,
    setHighlights,
  ];

  // return returnData;
  return useMemo(
    () => computeTable(),
    [tableData, selectedRowKeys, loading, highlights, valueType, t, searchText, ...listen]
  );
};
