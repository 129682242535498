import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import '@bryntum/gantt/gantt.stockholm.css';
import { BryntumGantt, BryntumGanttProps } from '@bryntum/gantt-react';
import { DateHelper, PresetManager } from '@bryntum/gantt';
import { uniq } from 'lodash';
import AlertTaskContext from '../contexts/AlertTaskContext';
import { getRandomString } from '../utils/commonUtil';

// const packageGrouper = (task) => `${task.package}`;
// const areaGrouper = (task) => `${task.area}`;
// const disciplineGrouper = (task) => `${task.discipline}`;
// const parentGrouper = (task) => `${task.parentTaskName}`;

// const defaultGrouper = [
//   packageGrouper,
//   areaGrouper,
//   disciplineGrouper,
//   parentGrouper,
// ];

// const splitterConfig = { appendTo: 'main' };

PresetManager.registerPreset('monthAndYear', {
  base: 'monthAndYear',
  tickWidth: 70, // 设置宽度
  headers: [
    {
      unit: 'YEAR',
      align: 'center',
      dateFormat: 'Y',
    },
    {
      unit: 'MONTH',
      align: 'center',
      dateFormat: 'MMM',
    },
  ],
});

const ganttConfig: BryntumGanttProps = {
  // appendTo: 'main',
  // flex: '1 1 auto',
  scrollTaskIntoViewOnCellClick: true,
  subGridConfigs: {
    fixed: {
      // No resizing of the fixed column region
      resizable: false,
      // Set a lower weight than the built-in "locked" section to have it appear to the left
      weight: 1,
    },
    locked: {
      // A scrollable section with the main columns
      width: 320,
      weight: 2,
    },
  },
  columns: [
    { type: 'name', field: 'name', width: 320 },
    {
      text: 'Activity ID',
      field: 'taskId',
      width: 160,
      renderer: (task) => `${task.record.taskId || '-'}`,
    },
    {
      text: 'wbs',
      field: 'wbs',
      renderer: (task) => `${task.record.wbs || '-'}`,
    },
    {
      text: 'discipline',
      field: 'discipline',
      renderer: (task) => `${task.record.discipline || '-'}`,
      sortable: false,
      filterable: {
        filterField: {
          type: 'combo',
          multiSelect: false,
          editable: false,
          items: [],
        },
      },
    },
    {
      text: 'CWA',
      field: 'area',
      renderer: (task) => `${task.record.area || '-'}`,
      sortable: false,
      filterable: {
        filterField: {
          type: 'combo',
          multiSelect: false,
          editable: false,
          items: [],
        },
      },
    },
    {
      text: 'package',
      field: 'package',
      renderer: (task) => `${task.record.package || '-'}`,
      sortable: false,
      filterable: {
        filterField: {
          type: 'combo',
          multiSelect: false,
          editable: false,
          items: [],
        },
      },
    },
    { type: 'startdate' },
    { type: 'enddate' },
    { type: 'duration' },
    { type: 'predecessor' },
  ],
  barMargin: 5,
  rowHeight: 20,
  cls: 'gantt-bar',
  project: {
    // startDate: '2023-03-01',
    // endDate: '2024-12-09',
    taskStore: {
      fields: ['name', 'taskId', 'wbs', 'discipline', 'area', 'package', 'startdate', 'enddate', 'duration', 'predecessor'],
    },
    // transport: {
    //   load: {
    //     // url: '/api/schedule-tasks',
    //     url: '../data/gantt-data.json',
    //   },
    // },
    // autoLoad: false,
    // The State TrackingManager which the UndoRedo widget in the toolbar uses
    stm: {
      autoRecord: true,
    },
    // Reset Undo / Redo after each load
    resetUndoRedoQueuesAfterLoad: true,
    calendarsData: [
      {
        id: 'general',
        name: 'General',
        intervals: [
          {
            recurrentStartDate: 'on Sat at 0:00',
            recurrentEndDate: 'on Mon at 0:00',
            isWorking: false,
          },
        ],
      },
      // {
      //   id: 'mon-thu',
      //   name: 'Mon-Thu',
      //   intervals: [
      //     {
      //       recurrentStartDate: 'on Fri at 0:00',
      //       recurrentEndDate: 'on Mon at 0:00',
      //       isWorking: false,
      //     },
      //   ],
      // },
      // {
      //   id: 'break',
      //   name: 'Breaks',
      //   intervals: [
      //     {
      //       startDate: '2022-08-07',
      //       endDate: '2022-08-11',
      //       isWorking: false,
      //     },
      //     {
      //       startDate: '2022-08-18',
      //       endDate: '2022-08-20',
      //       isWorking: false,
      //     },
      //   ],
      // },
    ],
  },
  viewPreset: 'monthAndYear',
  onBeforePresetChange: (event) => {
    console.log(event.preset);
    console.log(event.preset.id);
  },
  features: {
    // parentArea: true,
    // Disable data editing features
    dependencies: {
      allowCreate: false,
    },
    percentBar: {
      allowResize: false,
    },
    cellEdit: false,
    taskEdit: false,
    taskMenu: false,
    taskDrag: false,
    taskResize: false,
    taskDragCreate: false,
    timeSpanHighlight: true,
    criticalPaths: {
      disabled: true,
    },

    filter: true,

    progressLine: {
      disabled: true,
    },

    // treeGroup: {
    //   levels: defaultGrouper,
    // },
    // sort: 'startdate',
    taskNonWorkingTime: true,
    baselines: {
      disabled: false,
      template: (item): string => {
        const { baseline } = item;
        const { task } = baseline;

        const displayDuration = baseline.duration;

        return `<div class="b-gantt-task-title">
                ${task.name} (${baseline.name})</div>
                <table>
                  <tr>
                    <td>Start:</td>
                    <td>${item.startClockHtml}</td>
                  </tr>
                  <tr>
                    <td>End:</td>
                    <td>${item.endClockHtml}</td>
                  </tr>
                  <tr>
                    <td>Duration:</td>
                    <td class="b-right">${`${displayDuration} ${DateHelper.getLocalizedNameOfUnit(
                      baseline.durationUnit,
                      baseline.duration !== 1
                    )}`}</td>
                  </tr>
                </table>
                `;
      },
    },
    timeRanges: true,
  },

  tbar: {
    type: 'gantttoolbar',
  },
  // pdfExportFeature: {
  //   exportServer: 'http://localhost:8080',
  //   translateURLsToAbsolute: 'http://localhost:3000',
  //   clientURL: 'http://localhost:3000',
  //   keepPathName: false,
  // },
};

const GanttChart: React.FC<any> = (props) => {
  const { height, data } = props;
  const gantt = useRef<any>(null);
  const [ganttKey, setGanttKey] = useState(getRandomString());
  const { alertView } = useContext(AlertTaskContext);

  const [packagePressed, setPackagePressed] = useState<boolean>(true);
  const [areaPressed, setAreaPressed] = useState<boolean>(true);
  const [disciplinePressed, setDisciplinePressed] = useState<boolean>(true);
  const [parentPressed, setParentPressed] = useState<boolean>(true);
  const [criticalPathsPressed, setCriticalPathsPressed] = useState<boolean>(false);

  const onFeaturesClick = ({ source: item }) => {
    if (item.feature) {
      const feature = gantt.current.instance.features[item.feature];
      feature.disabled = !feature.disabled;
    } else if (item.subGrid) {
      const subGrid = gantt.current.instance.subGrids[item.subGrid];
      subGrid.collapsed = !subGrid.collapsed;
    } else if (item.toggleConfig) {
      gantt.current.instance[item.toggleConfig] = item.checked;
    }
  };

  const onFeaturesShow = ({ source: menu }) => {
    menu.items.map((item) => {
      const { feature } = item;

      if (feature) {
        // a feature might be not presented in the gantt
        // (the code is shared between "advanced" and "php" demos which use a bit different set of features)
        if (gantt.current.instance.features[feature]) {
          item.checked = !gantt.current.instance.features[feature].disabled;
        } else {
          // hide not existing features
          item.hide();
        }
      } else if (item.subGrid) {
        item.checked = gantt.current.instance.subGrids[item.subGrid].collapsed;
      }
    });
  };

  const onSettingsShow = ({ source: menu }) => {
    const { rowHeight, barMargin } = menu.widgetMap;

    rowHeight.value = gantt.current.instance.rowHeight;
    barMargin.value = gantt.current.instance.barMargin;
    barMargin.max = gantt.current.instance.rowHeight / 2 - 5;
  };

  const onRowHeightChange = ({ value, source }) => {
    gantt.current.instance.rowHeight = value;
    source.owner.widgetMap.barMargin.max = value / 2 - 5;
  };

  const onBarMarginChange = ({ value }) => {
    gantt.current.instance.barMargin = value;
  };

  const scrollToTask = (record) => {
    gantt.current.instance.expandTo(record).then(() => {
      gantt.current.instance
        .scrollTaskIntoView(record, {
          animate: true,
          block: 'center',
          highlight: true,
          focus: true,
        })
        .then(() => {
          gantt.current.instance.selectRow(record);
        });
    });
  };

  const ganttCardConfig: BryntumGanttProps = {
    ...ganttConfig,
    tbar: {
      items: [
        // {
        //   type: 'textfield',
        //   ref: 'filterByName',
        //   cls: 'filter-by-name',
        //   flex: '0 0 13.5em',
        //   // Label used for material, hidden in other themes
        //   label: 'Find tasks by Activity ID',
        //   // Placeholder for others
        //   placeholder: 'Find tasks by Activity ID',
        //   clearable: true,
        //   keyStrokeChangeDelay: 100,
        //   triggers: {
        //     filter: {
        //       align: 'end',
        //       cls: 'b-fa b-fa-filter',
        //     },
        //   },
        //   onChange: ({ value }) => {
        //     if (value === '') {
        //       gantt.current.instance.taskStore.clearFilters();
        //     } else {
        //       const val = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        //       gantt.current.instance.taskStore.filter({
        //         filters: (task) =>
        //           task.id && task.id.match(new RegExp(val, 'i')),
        //         replace: true,
        //       });
        //     }
        //   },
        // },
        // {
        //   ref: 'undoRedo',
        //   type: 'undoredo',
        //   items: {
        //     transactionsCombo: null,
        //   },
        // },
        {
          type: 'buttonGroup',
          items: [
            {
              ref: 'expandAllButton',
              icon: 'b-fa b-fa-angle-double-down',
              tooltip: 'Expand all',
              onAction: () => {
                gantt.current.instance.expandAll();
              },
            },
            {
              ref: 'collapseAllButton',
              icon: 'b-fa b-fa-angle-double-up',
              tooltip: 'Collapse all',
              onAction: () => {
                gantt.current.instance.collapseAll();
              },
            },
          ],
        },
        {
          type: 'buttonGroup',
          items: [
            {
              ref: 'zoomInButton',
              icon: 'b-fa b-fa-search-plus',
              tooltip: 'Zoom in',
              onAction: () => {
                gantt.current.instance.zoomIn();
              },
            },
            {
              ref: 'zoomOutButton',
              icon: 'b-fa b-fa-search-minus',
              tooltip: 'Zoom out',
              onAction: () => {
                gantt.current.instance.zoomOut();
              },
            },
            {
              ref: 'zoomToFitButton',
              icon: 'b-fa b-fa-compress-arrows-alt',
              tooltip: 'Zoom to fit',
              onAction: () => {
                // gantt.current.instance.zoomToFit({
                //   leftMargin: 50,
                //   rightMargin: 50,
                // });
                gantt.current.instance.zoomTo({
                  preset: 'monthAndYear',
                });
              },
            },
            {
              ref: 'previousButton',
              icon: 'b-fa b-fa-angle-left',
              tooltip: 'Previous time span',
              onAction: () => {
                gantt.current.instance.shiftPrevious();
              },
            },
            {
              ref: 'nextButton',
              icon: 'b-fa b-fa-angle-right',
              tooltip: 'Next time span',
              onAction: () => {
                gantt.current.instance.shiftNext();
              },
            },
          ],
        },

        // {
        //   type: 'label',
        //   text: 'Group by',
        // },
        // {
        //   type: 'button',
        //   text: 'package',
        //   toggleable: true,
        //   icon: 'b-fa-square',
        //   pressedIcon: 'b-fa-check-square',
        //   pressed: packagePressed,
        //   async onToggle({ pressed }) {
        //     if (pressed) {
        //       defaultGrouper.splice(0, 0, packageGrouper);
        //     } else {
        //       defaultGrouper.splice(0, 1);
        //     }
        //     if (defaultGrouper.length > 0) {
        //       await gantt.current.instance.group(defaultGrouper);
        //     } else {
        //       await gantt.current.instance.clearGroups();
        //     }
        //     setPackagePressed(pressed);
        //   },
        // },
        // {
        //   type: 'button',
        //   text: 'area',
        //   icon: 'b-fa-square',
        //   pressedIcon: 'b-fa-check-square',
        //   toggleable: true,
        //   pressed: areaPressed,
        //   async onToggle({ pressed }) {
        //     const packageIndex = defaultGrouper.indexOf(packageGrouper);
        //     const index = packageIndex === -1 ? 0 : 1;
        //     if (pressed) {
        //       defaultGrouper.splice(index, 0, areaGrouper);
        //     } else {
        //       defaultGrouper.splice(index, 1);
        //     }
        //     if (defaultGrouper.length > 0) {
        //       await gantt.current.instance.group(defaultGrouper);
        //     } else {
        //       await gantt.current.instance.clearGroups();
        //     }
        //     setAreaPressed(pressed);
        //   },
        // },
        // {
        //   type: 'button',
        //   text: 'discipline',
        //   icon: 'b-fa-square',
        //   pressedIcon: 'b-fa-check-square',
        //   toggleable: true,
        //   pressed: disciplinePressed,
        //   async onToggle({ pressed }) {
        //     const packageIndex = defaultGrouper.indexOf(packageGrouper);
        //     const areaIndex = defaultGrouper.indexOf(areaGrouper);
        //     const index =
        //       packageIndex === -1
        //         ? areaIndex === -1
        //           ? 0
        //           : 1
        //         : areaIndex === -1
        //         ? 1
        //         : 2;
        //     if (pressed) {
        //       defaultGrouper.splice(index, 0, disciplineGrouper);
        //     } else {
        //       defaultGrouper.splice(index, 1);
        //     }
        //     if (defaultGrouper.length > 0) {
        //       await gantt.current.instance.group(defaultGrouper);
        //     } else {
        //       await gantt.current.instance.clearGroups();
        //     }
        //     setDisciplinePressed(pressed);
        //   },
        // },
        // {
        //   type: 'button',
        //   text: 'Parent Task',
        //   icon: 'b-fa-square',
        //   pressedIcon: 'b-fa-check-square',
        //   toggleable: true,
        //   pressed: parentPressed,
        //   async onToggle({ pressed }) {
        //     const packageIndex = defaultGrouper.indexOf(packageGrouper);
        //     const areaIndex = defaultGrouper.indexOf(areaGrouper);
        //     const disciplineIndex = defaultGrouper.indexOf(disciplineGrouper);
        //     const index =
        //       packageIndex === -1
        //         ? areaIndex === -1
        //           ? disciplineIndex === -1
        //             ? 0
        //             : 1
        //           : disciplineIndex === -1
        //           ? 1
        //           : 2
        //         : areaIndex === -1
        //         ? disciplineIndex === -1
        //           ? 1
        //           : 2
        //         : disciplineIndex === -1
        //         ? 2
        //         : 3;

        //     if (pressed) {
        //       defaultGrouper.splice(index, 0, parentGrouper);
        //     } else {
        //       defaultGrouper.splice(index, 1);
        //     }
        //     if (defaultGrouper.length > 0) {
        //       await gantt.current.instance.group(defaultGrouper);
        //     } else {
        //       await gantt.current.instance.clearGroups();
        //     }
        //     setParentPressed(pressed);
        //   },
        // },

        // '->',
        // {
        //   type: 'button',
        //   color: 'b-red',
        //   // icon: 'b-fa-square',
        //   // pressedIcon: 'b-fa-check-square',
        //   ref: 'criticalPathsButton',
        //   text: 'Toggle Alert Tasks',
        //   toggleable: true,
        //   pressed: false,
        //   onToggle({ pressed }) {
        //     if (pressed) {
        //       alertGrid.current.instance.flex = '0 0 350px';
        //     } else {
        //       alertGrid.current.instance.flex = '0px';
        //     }
        //   },
        // },
        // {
        //   type: 'button',
        //   color: 'b-red',
        //   icon: 'b-fa-square',
        //   pressedIcon: 'b-fa-check-square',
        //   ref: 'criticalPathsButton',
        //   text: 'Highlight Critical Paths',
        //   toggleable: true,
        //   pressed: criticalPathsPressed,
        //   onToggle({ pressed }) {
        //     // toggle critical paths feature disabled/enabled state
        //     gantt.current.instance.features.criticalPaths.disabled =
        //       criticalPathsPressed;
        //     setCriticalPathsPressed(!criticalPathsPressed);
        //   },
        // },
        {
          type: 'button',
          ref: 'featuresButton',
          icon: 'b-fa b-fa-tasks',
          text: 'Settings',
          tooltip: 'Toggle features',
          menu: {
            onItem: onFeaturesClick,
            onBeforeShow: onFeaturesShow,
            // "checked" is set to a boolean value to display a checkbox for menu items. No matter if it is true or false.
            // The real value is set dynamically depending on the "disabled" config of the feature it is bound to.
            items: [
              {
                text: 'UI settings',
                icon: 'b-fa-sliders-h',
                menu: {
                  cls: 'settings-menu',
                  layoutStyle: {
                    flexDirection: 'column',
                  },
                  onBeforeShow: onSettingsShow,
                  defaults: {
                    type: 'slider',
                    showValue: true,
                  },
                  items: [
                    {
                      ref: 'rowHeight',
                      text: 'Row height',
                      min: 20,
                      max: 70,
                      onInput: onRowHeightChange,
                    },
                    {
                      ref: 'barMargin',
                      text: 'Bar margin',
                      min: 0,
                      max: 10,
                      onInput: onBarMarginChange,
                    },
                  ],
                },
              },
              {
                text: 'Draw relations',
                feature: 'dependencies',
                checked: false,
              },
              // {
              //   text: 'Task labels',
              //   feature: 'labels',
              //   checked: false,
              // },
              {
                text: 'Project lines',
                feature: 'projectLines',
                checked: false,
              },
              // {
              //   text: 'Highlight non-working time',
              //   feature: 'nonWorkingTime',
              //   checked: false,
              // },
              {
                text: 'Show column lines',
                feature: 'columnLines',
                checked: true,
              },
              // {
              //   text: 'Show progress line',
              //   feature: 'progressLine',
              //   checked: false,
              // },
              // {
              //   text: 'Show parent area',
              //   feature: 'parentArea',
              //   checked: false,
              // },
              {
                text: 'Hide schedule',
                cls: 'b-separator',
                subGrid: 'normal',
                checked: false,
              },
            ],
          },
        },
      ],
    },
    height,
  };

  useEffect(() => {
    if (!alertView || alertView.tab !== 'schedule' || !alertView.key) {
      return;
    }
    if (alertView.zoom) {
      gantt.current.instance.zoomTo({
        preset: 'monthAndYear',
        centerDate: new Date(alertView.startDate),
      });
      gantt.current.instance.highlightTimeSpans([
        {
          startDate: new Date(alertView.startDate),
          endDate: new Date(alertView.endDate),
        },
      ]);
    } else {
      const recordIndex = gantt.current.instance.store.allRecords.findIndex((item: any) => item.taskId === alertView.key);
      if (recordIndex > -1) {
        scrollToTask(gantt.current.instance.store.allRecords[recordIndex]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertView]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setGanttKey(getRandomString());
  }, [data]);

  useEffect(() => {
    if (!gantt.current) {
      return;
    }
    // gantt.current.instance.suspendRefresh();
    gantt.current.instance.project.loadInlineData(data).then(() => {
      // gantt.current.instance.resumeRefresh();
      setTimeout(() => {
        if (gantt && gantt.current && gantt.current.instance) {
          // gantt.current.instance.zoomToFit({
          //   leftMargin: 50,
          //   rightMargin: 50,
          // });
          const finalData = [];
          getDataFromTree(finalData, data.tasksData);
          const disciplineColumn: any = gantt.current.instance.columns.get('discipline');
          disciplineColumn.filterable.filterField.items = uniq(finalData.filter((item) => item.discipline).map((item) => item.discipline));
          const areaColumn = gantt.current.instance.columns.get('area');
          areaColumn.filterable.filterField.items = uniq(finalData.filter((item) => item.area).map((item) => item.area));
          const packageColumn = gantt.current.instance.columns.get('package');
          packageColumn.filterable.filterField.items = uniq(finalData.filter((item) => item.package).map((item) => item.package));
          gantt.current.instance.refresh();
        }
      }, 1000);
    });
  }, [gantt.current, ganttKey]);

  const getDataFromTree = (finalData: any[], treeData: any[]) => {
    treeData.forEach((treeNode) => {
      if (treeNode.children && treeNode.children.length > 0) {
        getDataFromTree(finalData, treeNode.children);
      } else {
        finalData.push(treeNode);
      }
    });
  };

  return (
    <>
      <BryntumGantt key={ganttKey} ref={gantt} {...ganttCardConfig} />
    </>
  );
};
export default memo(GanttChart, (prevProps, nextProps) => prevProps.data === nextProps.data && prevProps.height === nextProps.height);
