/* eslint-disable react/require-default-props */
import { Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import AlertTaskContext from "../contexts/AlertTaskContext";

type Pagination = {
  pageSize: number;
};

type TableCardData = {
  columns: any;
  data: any;
  height: number;
  width?: number;
  scrollHeight?: number;
  showTab: boolean;
  pagination?: Pagination;
  size?: SizeType;
  loading?: boolean;
  expandedRowKeys?: string[];
  rowSelection?: any;
  onExpand?: (expanded, record) => void;
  childrenColumnName?: string;
};

const TableCard: React.FC<TableCardData> = (props) => {
  const {
    columns,
    data,
    height,
    width,
    scrollHeight,
    showTab,
    pagination,
    size = "middle",
    loading = false,
    expandedRowKeys,
    rowSelection,
    onExpand,
    childrenColumnName = "children",
  } = props;
  useContext(AlertTaskContext);
  const [position, setPosition] = useState<any>("bottom");
  const instance = useRef(null);

  return (
    <>
      <div className="table-container">
        <div
          style={{
            height: height + (showTab ? 118 : 40),
            width: width || "auto",
            position: "relative",
            transition: "all 0.2s",
          }}
        >
          <div style={{ paddingTop: showTab ? 38 : 0 }}>
            <Table
              key={data}
              ref={instance}
              style={{ position: "relative", width: "100%" }}
              scroll={{
                x: width ? width - 32 : 1500,
                y: scrollHeight || height,
              }}
              pagination={
                pagination
                  ? { position: [position], pageSize: pagination.pageSize }
                  : false
              }
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              expandable={{
                childrenColumnName,
                defaultExpandAllRows: true,
                expandedRowKeys: expandedRowKeys,
                onExpand: onExpand,
              }}
              bordered
              size={size}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TableCard;
