export const messageMapping: any = {
  最適化結果: `
  あなたはプラント建設業界の優秀なエンジニアです。
  以下の入力文と制約条件に沿って、専門性の高い回答を出力してください。

  #入力文
  最適化結果3案を比較してください。
  #,最適化結果案,面積原価,面積原価過去案件対比,リスク合計,リスク合計過去案件対比
  1,ピーク抑制案,42.44億USD・Day,92%,10507,90%
  2,納期短縮案,34.46億USD・Day,75%,10570,91%
  3,中間案,41.32億USD・Day,90%,10535,90%

  #制約条件
  ・文字数が300文字以内とする
  ・入力文にない内容を回答しない
  ・データがCSV書式となる
  ・会話調で返答する
  ・検討すべき点は箇条書きで表現する
  ・3案を面積原価とリスク合計の２軸でそれぞれ定量的に比較する
  ・過去案件と比べ、原価とリスクが減少しているか比較・評価する
  ・リスクの少ないピーク抑制案を推奨する
`,

  改善ポイント: `
  あなたはプラント建設業界の優秀なエンジニアです。
  以下の入力文と制約条件に沿って、専門性の高い回答を出力してください。

  #入力文
  リスクとアラートの関連データを踏まえて、ピーク抑制案の改善ポイントとその対策を教えてください。

  # リスク対象対象WPのID, 対象WPの名称
  [EWP-3C13-B2_02],60% Modeling(PIP)
  [EWP-3C17-A_01],UG Design Completion(PIP)
  [EWP-3C13-A2_02],60% Modeling(PIP)

  # リスク対策
  ・これらのWPの分割や工事シーケンスを見直すことでリスクの低減を試みてください。
  ・過去類似プロジェクトの対応実績は@Lessons Learnt@を参照してください。

  # アラート名称:MP最大ピーク
  # アラート期間:2026/01/25週 – 2026/02/01週
  # アラート対象:
  対象WPのID, 対象WPの名称
  [EWP-3CA3-ALL],Special Piping Final MTO
  [EWP-3C33-ALL],Valve Final MTO
  [EWP-3C83-ALL],Specialities Final MTO
  [EWP-3C15-A1_01_1],Design Completion

  # アラート対策
  ・ リレーションシップをSSに修正し、先行アクティビティを早期開始する事でピークを均す
  ・ WPを分割し、特に重要なアクティビティの周辺アクティビティは分離する

  #制約条件
  ・文字数が1000文字以内とする
  ・入力文にない内容を回答しない
  ・インプットデータがCSV書式となる
  ・会話調で返答する
  ・リスクは対象(書式: <ID> - <名称>)を箇条書きで表示してから、対策を表示する
  ・アラートは名称・期間・対象・対策の順でに表示する
  ・対策は提供された内容のままで提示する
  ・対象は箇条書きで全データをリストアップする
`,

  納期への影響: `
  あなたはプラント建設業界の優秀なエンジニアです。
  以下の入力文と制約条件に沿って、専門性の高い回答を出力してください。

  #入力文
  60% Modelingが遅れた場合、納期にどのような影響があり、推奨アクションを合わせてお教えください。

  以下はシミュレーションの結果です。

  # シミュレーション結果パターン#, パターン名, 納期, 面積原価
  パターン1, 1週間遅れ, 2027/2/9(13日遅れ), 4,382,537,547 [USD・Day]
  パターン2, 2週間遅れ, 2027/3/2(34日遅れ), 4,508,463,913 [USD・Day]
  パターン3, 1か月遅れ, 2027/4/13(76日遅れ), 4,747,190,008 [USD・Day]

  # 推奨アクション
  Productivity低下に関する過去類似プロジェクトを2つ検知し、@Lessons Learnt@の参照をお勧めします。

  #制約条件
  ・文字数が1000文字以内とする
  ・入力文にない内容を回答しない
  ・インプットデータがCSV書式となる
  ・会話調で返答する
  ・シミュレーション結果は、パターン(<パターン#> - <パターン名>)を第1階層、納期と面積原価を第２階層で、箇条書きでリストアップする
  ・推奨アクションは提供された内容のままで提示する
`,
};

export const chatMessageConfig: any = {
  welcome: "skdChat.welcome",
  template: "skdChat.template",
  quickBtnTpl: {
    最適化結果比較: "最適化結果3案を比較してください。",
    改善ポイント: "ピーク抑制案の改善ポイントを教えてください。",
    納期への影響: "60% Modelingが遅れた場合の納期への影響をお教えください。",
  },
  defaultQuickReplies: ["最適化結果比較", "改善ポイント", "納期への影響"],
  shortCuts: {
    最適化結果: "最適化結果",
    最適化結果比較: "最適化結果",

    改善ポイント: "改善ポイント",
    ピーク抑制案の改善ポイント: "改善ポイント",

    納期への影響: "納期への影響",
    納期影響: "納期への影響",
    納期にどのような影響: "納期への影響",
  },
};
