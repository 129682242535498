import { AreaChartOutlined, FundOutlined, LineChartOutlined, UnorderedListOutlined } from '@ant-design/icons';

import React from 'react';

const router = {
  key: 'aipskd',
  logoUrl: process.env.REACT_APP_DOMAIN + '/assets/EffiMate.png',
  title: 'EffiMate',
  label: 'EffiMate',
  path: '/aipskd',
  icon: <span>EM</span>,
  description: 'Recommend optimal schedules based on multiple specified strategies to support fast and accurate decision-making.',
  descI18n: 'aipskd.menu.description',
  routes: [
    {
      key: 'project',
      label: 'Project',
      i18n: 'aipskd.project.project',
      path: '/aipskd/project',
      icon: <UnorderedListOutlined />,
      description: 'Schedule Optimization',
      chat: 'true',
    },
    {
      key: 'plan',
      label: 'Project Plan',
      i18n: 'aipskd.plan.plan',
      path: '/aipskd/plan',
      icon: <FundOutlined />,
      description: 'Schedule Optimization',
      chat: 'true',
    },
    {
      key: 'planOption',
      label: 'Plan Option',
      i18n: 'aipskd.planOption.planOption',
      path: '/aipskd/plan-option',
      icon: <LineChartOutlined />,
      description: 'Schedule Optimization',
      chat: 'true',
    },
    {
      key: 'planOptionDetail',
      label: 'Plan Option Detail',
      i18n: 'aipskd.optionDetail.optionDetail',
      path: '/aipskd/plan-option-detail',
      icon: <AreaChartOutlined />,
      description: 'Schedule Optimization',
      chat: 'true',
    },
  ],
};
export default router;
