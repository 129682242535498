import { Tag } from 'antd';
import { useEffect, useState } from 'react';
import { ACTION_EVENT } from '../consts/action-event';
import APIList from '../http/ApiList';
import useRoleAuth, { AUTH_OPEN_STATE_TYPE } from './useRoleAuth';

const useBusinessProp = ({ color, globalEvent, authInfo }) => {
  const [authentication, setAuthentication] = useState<any>();
  const { AUTH_OPEN_STATE } = useRoleAuth({ authentication });

  // data switcher
  const [projectId, setProjectId] = useState<string>();
  const [planId, setPlanId] = useState<string>();
  const [optimizeId, setOptimizeId] = useState<string[]>([]);
  const [optimizationId, setOptimizationId] = useState<string[]>([]);
  const [optimizationIds, setOptimizationIds] = useState<string[]>([]);
  const [disciplineIds, setDisciplineIds] = useState<string[]>([]);
  const [cwaIds, setCwaIds] = useState<string[]>([]);
  const [projectList, setProjectList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [optimizeList, setOptimizeList] = useState([]);
  const [optimizationList, setOptimizationList] = useState([]);
  const [disciplineList, setDisciplineList] = useState([]);
  const [cwaList, setCwaList] = useState([]);
  const [dataSection, setDataSection] = useState<{
    projectId: string;
    planId: string;
    optimizeId: string;
    optimizationId: string;
    optimizeIds: string[];
    optimizationIds: string[];
  }>();
  const [selectOptions, setSelectOptions] = useState<{
    projects: any[];
    plans: any[];
    optimizes: any[];
    optimizations: any[];
  }>();

  // alert
  const importanceCategoryMap = {
    H: (
      <Tag
        style={{
          width: 24,
          textAlign: 'center',
          height: 16,
          lineHeight: '14px',
          padding: 0,
        }}
        color={color?.errorColor}
      >
        H
      </Tag>
    ),
    M: (
      <Tag
        style={{
          width: 24,
          textAlign: 'center',
          height: 16,
          lineHeight: '14px',
          padding: 0,
        }}
        color={color?.warningColor}
      >
        M
      </Tag>
    ),
    L: (
      <Tag
        style={{
          width: 24,
          textAlign: 'center',
          height: 16,
          lineHeight: '14px',
          padding: 0,
        }}
        color={color?.successColor}
      >
        L
      </Tag>
    ),
  };

  // memo
  const [memo, setMemo] = useState();
  const [memoSwitch, setMemoSwitch] = useState(false);
  const [memoSearchValue, setMemoSearchValue] = useState();
  const [memoLoading, setMemoLoading] = useState(false);

  useEffect(() => {
    getAuthentication();
  }, [authInfo.user]);

  useEffect(() => {
    if (globalEvent && globalEvent.eventType === ACTION_EVENT.AUTH_UPDATE) {
      getAuthentication();
      globalEvent.eventType = null;
    }
  }, [globalEvent]);

  const getAuthentication = () => {
    if (!authInfo.user) {
      return;
    }
    if (AUTH_OPEN_STATE === AUTH_OPEN_STATE_TYPE.CLOSE) {
      APIList.getAuthentication().get();
      return;
    }
    APIList.getAuthentication()
      .get()
      .then((res: any) => {
        setAuthentication(res);
      })
      .catch(() => {
        setAuthentication({ errorCode: 409 });
      });
  };

  /**
   * プロジェクトを作成ボタンの制御
   * @returns
   */
  const projectCreateAuth = () => {
    if (AUTH_OPEN_STATE === AUTH_OPEN_STATE_TYPE.CLOSE) {
      return true;
    }

    if (!authentication) {
      return false;
    }
    const { systemRole } = authentication;
    switch (systemRole) {
      // システムユーザで、あらゆる権限を持つ
      case 'ROOT':
        return true;
      // システムユーザで、全てのプロジェクトに対して`VIEWER` のプロジェクトロールを持つ
      case 'GUEST':
        return false;
      // サービス利用者で、プロジェクトを作成でき、全てのプロジェクトに対してデフォルトで`MANAGER` のプロジェクトロールを持つ
      case 'ADMIN':
        return true;
      // サービス利用者で、プロジェクトロールが付与されるまでログインが可能だが、その以外の権限を持っていない
      case 'USER':
        return false;
      default:
        return false;
    }
  };

  /**
   * プロジェクト改修と削除の制御
   * @param projectId
   * @returns
   */
  const projectEditDeleteAuth = (projectId: string) => {
    if (AUTH_OPEN_STATE === AUTH_OPEN_STATE_TYPE.CLOSE) {
      return true;
    }

    if (!authentication) {
      return false;
    }
    const { systemRole, userProjectRole } = authentication;
    switch (systemRole) {
      // システムユーザで、あらゆる権限を持つ
      case 'ROOT':
        return true;
      // システムユーザで、全てのプロジェクトに対して`VIEWER` のプロジェクトロールを持つ
      case 'GUEST':
        return false;
      // サービス利用者で、プロジェクトを作成でき、全てのプロジェクトに対してデフォルトで`MANAGER` のプロジェクトロールを持つ
      case 'ADMIN':
        return true;
      // サービス利用者で、プロジェクトロールが付与されるまでログインが可能だが、その以外の権限を持っていない
      case 'USER':
        const projectRoleInfo = userProjectRole.find((role: any) => role.projectId === projectId);
        if (projectRoleInfo) {
          const { projectRole } = projectRoleInfo;
          switch (projectRole) {
            case 'MANAGER':
              return true;
            case 'EDITOR':
              return false;
            case 'VIEWER':
              return false;
            default:
              return false;
          }
        }
        return false;
      default:
        return false;
    }
  };

  /**
   * プランの制御
   * @param projectId
   * @returns
   */
  const planAuth = (projectId) => {
    if (AUTH_OPEN_STATE === AUTH_OPEN_STATE_TYPE.CLOSE) {
      return true;
    }

    if (!authentication) {
      return false;
    }
    const { systemRole, userProjectRole } = authentication;
    switch (systemRole) {
      // システムユーザで、あらゆる権限を持つ
      case 'ROOT':
        return true;
      // システムユーザで、全てのプロジェクトに対して`VIEWER` のプロジェクトロールを持つ
      case 'GUEST':
        return false;
      // サービス利用者で、プロジェクトを作成でき、全てのプロジェクトに対してデフォルトで`MANAGER` のプロジェクトロールを持つ
      case 'ADMIN':
        return true;
      // サービス利用者で、プロジェクトロールが付与されるまでログインが可能だが、その以外の権限を持っていない
      case 'USER':
        const projectRoleInfo = userProjectRole.find((role: any) => role.projectId === projectId);
        if (projectRoleInfo) {
          const { projectRole } = projectRoleInfo;
          switch (projectRole) {
            case 'MANAGER':
              return true;
            case 'EDITOR':
              return true;
            case 'VIEWER':
              return false;
            default:
              return false;
          }
        }
        return false;
      default:
        return false;
    }
  };

  return {
    authentication,
    projectCreateAuth,
    projectEditDeleteAuth,
    planAuth,
    getAuthentication,
    projectId,
    setProjectId,
    planId,
    setPlanId,
    optimizeId,
    setOptimizeId,
    optimizationId,
    setOptimizationId,
    optimizationIds,
    setOptimizationIds,
    disciplineIds,
    setDisciplineIds,
    cwaIds,
    setCwaIds,
    projectList,
    setProjectList,
    planList,
    setPlanList,
    optimizeList,
    setOptimizeList,
    optimizationList,
    setOptimizationList,
    disciplineList,
    setDisciplineList,
    cwaList,
    setCwaList,
    dataSection,
    setDataSection,
    memo,
    setMemo,
    memoSwitch,
    setMemoSwitch,
    memoSearchValue,
    setMemoSearchValue,
    memoLoading,
    setMemoLoading,
    selectOptions,
    setSelectOptions,
    importanceCategoryMap,
  };
};

export default useBusinessProp;
