import { groupBy, sumBy } from 'lodash';
import moment from 'moment';

// @flow
/**
 * @public
 */
export type FilterType = 'Year' | 'Month' | 'Week' | 'Day';

export type SourceDataType = {
  day: string;
  seriesName: string;
  [key: string]: any;
};

export type SeriesData = {
  name: string;
  values: number[];
};

export type FilterData = {
  timeline: string[];
  series: SeriesData[];
};

// export const getDateRangeByType = (timeType: FilterType, startDate: Date, endDate: Date)=> {
//   const
//   moment(startDate)
//   moment(endDate)

// }

const customWeek = (date) => {
  const currentMoment = moment(date);
  const yearStart = moment(currentMoment).startOf('year');
  const yearEnd = moment(currentMoment).endOf('year');
  const weekOffset = yearStart.week() === 1 ? 0 : 1;

  // Check if the given date is close to the year's boundary
  if (currentMoment.week() === 1 && currentMoment.month() === 11) {
    // If the date belongs to the last week of the previous year
    if (currentMoment.week() !== yearEnd.week()) {
      return currentMoment.week();
    }
    return currentMoment.subtract(1, 'week').week() + 1;
  }
  if (currentMoment.week() === 1 && currentMoment.month() === 0) {
    // If the date belongs to the first week of the current year
    return 1;
  }
  // If the date is not close to the year's boundary
  return currentMoment.week() - weekOffset;
};

export const filterDataByDate = (timeType: FilterType, sourceData: SourceDataType[]): FilterData => {
  const fullSourceData = sourceData.map((item) => ({
    ...item,
    day: moment(item.day).format('YYYY-MM-DD'),
    week: customWeek(moment(item.day)),
    // week: moment(item.day).isoWeek(),
    month: new Date(item.day).getMonth() + 1,
    year: new Date(item.day).getFullYear(),
  }));

  const getMapping = (list) => {
    const map = {};
    list.forEach((element) => {
      let key = element[timeType.toLowerCase()];
      if (timeType === 'Week' || timeType === 'Month') {
        key = `${element.year}-${timeType}${element[timeType.toLowerCase()]}`;
      }
      map[key] = map[key] || [];
      map[key].push(element);
    });
    return map;
  };

  const getValues = (timeline, object) => {
    const arr: any[] = [];
    timeline.forEach((key) => {
      let count = 0;
      const values = object[key];
      if (values && values.length > 0) {
        if (timeType !== 'Day') {
          const group = groupBy(values, 'day');
          const maxElement = Object.values(group).reduce((pre, cur) => {
            const preSum = sumBy(pre, 'value');
            const curSum = sumBy(cur, 'value');
            return preSum > curSum ? pre : cur;
          });
          count += sumBy(maxElement, 'value');
        } else {
          values.forEach((element) => {
            if (element.value) {
              count += element.value;
            }
          });
        }
      }
      arr.push(count);
    });
    return arr;
  };

  const timeline = Array.from(
    new Set(
      fullSourceData.map((element) => {
        let value = element.day;
        if (timeType === 'Week' || timeType === 'Month') {
          value = `${element.year}-${timeType}${element[timeType.toLowerCase()]}`;
        } else if (timeType === 'Year') {
          value = element.year.toString();
        }
        return value;
      })
    )
  );

  const series = Array.from(new Set(fullSourceData.map((element) => element.seriesName)));

  const result: any[] = [];
  series.forEach((element) => {
    const find = getMapping(fullSourceData.filter((item) => item.seriesName === element));
    result.push({
      name: element,
      values: getValues(timeline, find),
    });
  });

  return {
    timeline,
    series: result,
  };
};
